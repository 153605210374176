import gameTwoData from '@/data/gameTwoData.json';
import styles from '@/features/components/gameTwo/gameTwo.module.scss';
import useTruckBarycenter from '@/hooks/useTruckBarycenter';
import { truckPositions } from '@/services/games/game2/gameTwoUtils';
import { useGame2Store } from '@/store/games/game2Store';

export default function GameTwoTruck() {
    const selectedItems = useGame2Store.use.selectedTruckObjects();

    const { barycenterX, barycenterY } = useTruckBarycenter(selectedItems);

    return (
        <div className={styles.truckContainer}>
            <div>
                <h1>{gameTwoData.texts.loadingInProcessTitle}</h1>
                <h1>{gameTwoData.texts.loadingInProcess}</h1>
            </div>
            <div className={styles.truck}>
                <div className={styles.truckLoad}>
                    {Object.entries(selectedItems).map(([color, object], index) => (
                        <div
                            key={`${color + index}`}
                            className={styles[truckPositions[index]]}
                        >
                            {object?.image ? (
                                <img
                                    src={object.image}
                                    alt='vehicule'
                                    width={150}
                                    height={150}
                                />
                            ) : null}
                        </div>
                    ))}
                    <div
                        className={styles.scalePointer}
                        style={{
                            top: `${barycenterY}%`,
                            left: `${barycenterX}%`,
                            transition: 'top 1s ease, left 1s ease',
                        }}
                    />
                </div>
            </div>
            <div></div>
        </div>
    );
}
