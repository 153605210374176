import { Pin } from '@/assets/images/icons';
import styles from '@/features/components/gameThree/map/components/DestinationProposal/destinationProposal.module.scss';

export function DestinationProposal({
    address,
    city,
    region,
}: {
    address: string;
    city: string;
    region: string;
}) {
    return (
        <div className={styles.destinationProposalContainer}>
            <img src={Pin} alt='localisation' width={48} />
            <div className={styles.text}>
                <h2>{address}</h2>
                <h5>
                    {city}, {region}
                </h5>
            </div>
            <div></div>
        </div>
    );
}
