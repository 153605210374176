import gameOneData from '@/data/gameOneData.json';
import { useGame1Store } from '@/store/games/game1Store';
import { type Tire, type TirePressureStatus } from '@/types/games/gameOne/types';

export function getNextCalledId() {
    const current = useGame1Store.getState().callerId;
    const poolSize = gameOneData.callerPool.length;
    return current === poolSize - 1 ? 0 : current + 1;
}

export function getPressureStatus(tires: Record<Tire, number>, control: number) {
    let status: TirePressureStatus = 'good';

    for (const current in tires) {
        const currentPressure = parseFloat(tires[current as Tire].toFixed(2));

        if (currentPressure === control) continue;

        status = currentPressure > control ? 'over' : 'under';
    }
    return status;
}
