import ErrorAndSuccessScreen from '@/features/components/modal/errorAndSuccess/ErrorAndSuccess';
import InactiveDevice from '@/features/devices/tablets/components/InactiveDevice';
import tabletStyle from '@/features/devices/tablets/components/screens/tabletScreen.module.scss';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { type DeviceStatus } from '@/types/games/types';
import type { PropsWithChildren } from 'react';

interface TabletLayoutProps extends PropsWithChildren {
    device: DeviceStatus;
}

export default function TabletLayout({ device, children }: TabletLayoutProps) {
    const isTabletActive = device.isActivated;
    const { currentText, currentTextAudio, currentTitle, overlayType } =
        useCurrentOverlay();

    return (
        <div
            className={
                isTabletActive
                    ? tabletStyle.redTabletContainer
                    : tabletStyle.neutralTabletContainer
            }
        >
            {overlayType !== 'neutral' && (
                <ErrorAndSuccessScreen
                    success={overlayType === 'success'}
                    text={currentText}
                    title={currentTitle}
                    audio={currentTextAudio}
                    isTablet
                />
            )}
            {isTabletActive ? children : <InactiveDevice />}
        </div>
    );
}
