import overlayData, {
    VEHICLE_SELECTION as data,
} from '@/data/overlayDeviceContent.json';
import TextAnimation from '@/features/components/text/TextAnimation';
import { useGameStore } from '@/store/gameStore';
import styles from './vehicleSelection.module.scss';
import AudioComponent from '@/features/components/AudioComponent';

export default function VehicleSelection() {
    const vehicleSelected = useGameStore.use.vehicle();
    const content = overlayData['VEHICLE_SELECTION'].tv;

    return (
        <div className={styles.container}>
            <AudioComponent src={data.header[0].audio} />
            <div className={styles.textContainer}>
                <h2>{data.header[0].title}</h2>
                <TextAnimation text={data.header[0].text} />
            </div>
            {content.map((vehicle) => (
                <div
                    key={vehicle.id}
                    className={`${styles.vehicle_card} ${vehicleSelected === vehicle.category ? styles.selected : ''}`}
                    id={vehicle.category}
                >
                    <div className={styles.vehicle_image}>
                        <img
                            src={vehicle.image}
                            alt={vehicle.category}
                            width={512}
                        />
                    </div>
                    <div className={styles.text_content}>
                        <h1 className={styles.vehicle_title}>{vehicle.text}</h1>
                    </div>
                </div>
            ))}
        </div>
    );
}
