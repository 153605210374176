import { INITIAL_DEVICE_STORE } from '@/sessionInfo';
import createSelectors from '@/store/selectors';
import { type DeviceStore } from '@/types/games/types';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';
import { create } from 'zustand';

export const useDeviceStoreBase = create<DeviceStore>(() =>
    structuredClone(INITIAL_DEVICE_STORE)
);

export const useDeviceStore = createSelectors(useDeviceStoreBase);

export function setDeviceConnectedStatus(
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
) {
    useDeviceStoreBase.setState((state) => ({
        deviceStatus: {
            ...state.deviceStatus,
            [deviceColor]: {
                ...state.deviceStatus[deviceColor],
                isConnected: status,
            },
        },
    }));
}

export function setDeviceActivatedStatus(
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
) {
    useDeviceStoreBase.setState((state) => ({
        deviceStatus: {
            ...state.deviceStatus,
            [deviceColor]: {
                ...state.deviceStatus[deviceColor],
                isActivated: status,
            },
        },
    }));
}

export function resetDevice() {
    useDeviceStoreBase.setState(() => ({
        deviceStatus: structuredClone({
            ...INITIAL_DEVICE_STORE.deviceStatus,
            blue: { ...INITIAL_DEVICE_STORE.deviceStatus.blue, isConnected: true },
            red: { ...INITIAL_DEVICE_STORE.deviceStatus.red, isConnected: true },
            orange: {
                ...INITIAL_DEVICE_STORE.deviceStatus.orange,
                isConnected: true,
            },
            green: {
                ...INITIAL_DEVICE_STORE.deviceStatus.green,
                isConnected: true,
            },
            tv: { ...INITIAL_DEVICE_STORE.deviceStatus.tv, isConnected: true },
            mediator: {
                ...INITIAL_DEVICE_STORE.deviceStatus.mediator,
                isConnected: true,
            },
        }),
    }));
}
