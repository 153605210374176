import dataGameTwo from '@/data/gameTwoData.json';
import globalData from '@/data/global.json';
import AstraLoading from '@/features/components/astraLoading/AstraLoading';
import DeviceBreathingScreen from '@/features/components/breathingExercice/deviceBreathingScreen/DeviceBreathingScreen';
import TirePressure from '@/features/components/gameOne/tirePressure/TirePressure';
import { NextSceneButton } from '@/features/components/gameThree/scenes/NextSceneButton';
import SelectVehicle from '@/features/components/vehicleSelection/SelectVehicle';
import ActionButton from '@/features/devices/tablets/components/buttons/ActionButton';
import EngineStartButton from '@/features/devices/tablets/components/buttons/EngineStartButton';
import SelectCarObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectCarObjectButton';
import SelectTruckObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectTruckObjectButton';
import FullScreenButton from '@/features/devices/tablets/components/gameFive/FullScreenButton';
import SelectSubstance from '@/features/devices/tablets/components/gameFour/SelectSubstance';
import SelectEmojiContainer from '@/features/devices/tablets/components/gameSix/SelectEmojiContainer';
import TabletLayout from '@/features/devices/tablets/components/screens/TabletLayout';
import Phone from '@/features/devices/tablets/orange/components/phone';
import CallEntry from '@/features/devices/tablets/orange/components/phone/callEntry/CallEntry';
import { useDynamicTabletName } from '@/hooks/useDynamicTabletName';
import { useReceiveCall } from '@/hooks/useReceiveCall';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { handleGoToGameStatus } from '@/services/global/globalHandleFunctions';
import { isCarSelected } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGame1Store } from '@/store/games/game1Store';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import { GAMESTATUS } from '@/types/global/types';

export default function OrangeActionSwitch() {
    const { gameStatus, cockpitStatus } = useGameStore();
    const { orange } = useDeviceStore.use.deviceStatus();
    const overlayIdx = useOverlayStore.use.currentOverlayIndex();
    const isBeingCalled = useGame1Store.use.isBeingCalled();
    const canGoToVehicleSelection =
        overlayIdx === 0 && gameStatus === 'GAME1INTRODUCTION';

    useDynamicTabletName('orange', orange.label);
    useReSynchronizeDevice('orange');

    useReceiveCall();

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.WELCOME_SCREEN:
                return (
                    <EngineStartButton
                        onClick={() => {
                            handleGoToGameStatus(GAMESTATUS.VEHICLE_SELECTION);
                        }}
                    />
                );
            case GAMESTATUS.VEHICLE_SELECTION:
                return <SelectVehicle type='car' />;
            case GAMESTATUS.GAME1INTRODUCTION_LOADING:
            case GAMESTATUS.GAME2INTRODUCTION_LOADING:
            case GAMESTATUS.GAME3INTRODUCTION_LOADING:
            case GAMESTATUS.GAME4INTRODUCTION_LOADING:
            case GAMESTATUS.GAME5INTRODUCTION_LOADING:
            case GAMESTATUS.GAME6INTRODUCTION_LOADING:
                return <AstraLoading device='orange' />;
            case GAMESTATUS.GAME1INTRODUCTION:
                return (
                    <div>
                        <ActionButton
                            action='goToNextOverlay'
                            title={globalData.global.next}
                            color='black'
                        />
                        {canGoToVehicleSelection && (
                            <ActionButton
                                action='goToVehicleSelectionStatus'
                                title={globalData.global.previous}
                                color='white'
                            />
                        )}
                    </div>
                );
            case GAMESTATUS.GAME1ACTION:
                if (isBeingCalled) return <CallEntry fromTv={false} />;
                if (cockpitStatus === 'TIRE')
                    return <TirePressure tire='RightFront' />;
                return <Phone />;
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.GAME2ACTION:
                return isCarSelected() ? (
                    <SelectCarObjectButton
                        color='orange'
                        title={dataGameTwo.texts.frontLeftArea}
                    />
                ) : (
                    <SelectTruckObjectButton color='orange' />
                );

            case GAMESTATUS.GAME3ACTION:
                return <NextSceneButton choice='left' color='orange' />;

            case GAMESTATUS.BREATHING_INTRO:
            case GAMESTATUS.BREATHING_END:
                return (
                    <ActionButton
                        action='goToNextGameStatus'
                        title={globalData.global.next}
                        color='black'
                        style={{ width: '90%' }}
                    />
                );
            case GAMESTATUS.BREATHING_ACTION:
                return <DeviceBreathingScreen device='orange' />;
            case GAMESTATUS.GAME4ACTION:
                return <SelectSubstance color='orange' />;
            case GAMESTATUS.GAME5ACTION:
                return <FullScreenButton color='orange' text='Reglementation' />;
            case GAMESTATUS.GAME6ACTION:
                return <SelectEmojiContainer color='orange' />;
            case GAMESTATUS.FINISHED:
                return null;
        }
    };

    return <TabletLayout device={orange}>{handleRender()}</TabletLayout>;
}
