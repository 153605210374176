import styles from '@/features/devices/tablets/mediator/mediatorAction.module.scss';
import { motion } from 'framer-motion';

export type MediatorActionProps = {
    handleFunction: () => void;
    title: string;
    description?: string;
    displayingCondition?: boolean;
    type?: 'secondary' | 'tertiary';
};

export default function MediatorAction({
    handleFunction,
    title,
    description,
    displayingCondition,
    type,
}: MediatorActionProps) {
    if (!displayingCondition) return;

    return (
        <div className={styles.mediatorAction}>
            <div>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
            <motion.button
                whileTap={{ scale: 0.85 }}
                onClick={handleFunction}
                className={type && styles[type]}
            >
                <p> Exécuter </p>
            </motion.button>
        </div>
    );
}
