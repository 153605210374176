import { ClickSound } from '@/assets/sounds/soundsEffects';
import Button from '@/features/devices/tablets/components/buttons/Button';
import styles from '@/features/devices/tablets/components/buttons/gameTwo/selectCarObjectButton.module.scss';
import SelectObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectObjectButton';
import { handleValidateGameTwoCar } from '@/services/games/game2/gameTwoHandleFunctions';
import {
    mapColorWithCarPosition,
    mapLocationNameEnglishToFrench,
    showValidateButton,
} from '@/services/games/game2/gameTwoUtils';
import { playSound } from '@/services/global/globalUtils';
import { useGame2Store } from '@/store/games/game2Store';
import type { DeviceColor } from '@/types/global/types';

export default function SelectCarObjectButton({
    color,
    title,
}: {
    color: DeviceColor;
    title: string;
}) {
    const carLocationByColor = mapColorWithCarPosition(color);
    const selectedCarObjects = useGame2Store.use.selectedCarObjects();

    const showButton = showValidateButton(selectedCarObjects);

    return (
        <div className={styles[`container_${color}`]}>
            <div className={styles.header}>
                <p>{title}</p>
            </div>
            <div className={styles.content}>
                {carLocationByColor.map((location) => {
                    const locationName = mapLocationNameEnglishToFrench[location];
                    const locationNameIndex = Object.keys(
                        mapLocationNameEnglishToFrench
                    ).indexOf(location);

                    return (
                        <SelectObjectButton
                            key={location}
                            type='car'
                            color={color}
                            carLocation={location}
                            locationName={locationName}
                            index={locationNameIndex}
                        />
                    );
                })}
            </div>
            {showButton && (
                <Button
                    color='black'
                    onClick={() => {
                        // TODO: update sound
                        playSound(ClickSound);
                        handleValidateGameTwoCar();
                    }}
                    text='Valider'
                    additionalStyle={{
                        width: '90%',
                    }}
                />
            )}
        </div>
    );
}
