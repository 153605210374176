import { PapangImage, SalaziaImage } from '@/assets/images/global';
import { EndSound } from '@/assets/sounds';
import data from '@/data/global.json';
import { MemoizedConfettis } from '@/features/components/confettis/Confettis';
import TextAnimation from '@/features/components/text/TextAnimation';
import styles from '@/features/devices/tv/components/endScreen/endScreen.module.scss';
import { playSound } from '@/services/global/globalUtils';
import { useGameStore } from '@/store/gameStore';
import { useEffect } from 'react';

export default function EndScreen() {
    const isTruck = useGameStore.use.vehicle() === 'truck';

    useEffect(() => {
        playSound(EndSound);
    }, []);

    return (
        <div className={styles.container}>
            <MemoizedConfettis top={0} right={0} />
            <MemoizedConfettis bottom={0} left={0} />
            <div className={styles.header}>
                <div>
                    <h1>{data.global.endTitle}</h1>
                    <TextAnimation text={data.global.endSubtitle} />
                </div>
            </div>
            <img
                src={isTruck ? PapangImage : SalaziaImage}
                alt='voiture ou camion'
                className={styles.image}
            />
        </div>
    );
}
