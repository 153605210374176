import SocketService from '@/services/SocketServices';
import { useDeviceStore } from '@/store/deviceStore';
import { useGame1Store } from '@/store/games/game1Store';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import { useRoomStore } from '@/store/roomStore';

import { useGame2Store } from '@/store/games/game2Store';
import { useGame3Store } from '@/store/games/game3Store';
import { useGame4Store } from '@/store/games/game4Store';
import { useGame5Store } from '@/store/games/game5Store';
import { useGame6Store } from '@/store/games/game6Store';
import { useGameBreathingExerciceStore } from '@/store/games/gameBreathingExerciceStore';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';
import { useEffect } from 'react';

export default function useReSynchronizeDevice(
    color: DeviceColorWithTvAndMediator
): void {
    const socket = SocketService.getInstance().getSocket();

    useEffect(() => {
        socket.on('receive_synchronize_device', (data) => {
            if (data.deviceColor !== color || !data.storeInfo) return;
            // Avoid erasing local store with server initial data
            if (data.storeInfo.gameStore.gameStatus === 'WELCOME_SCREEN') return;

            const {
                deviceStore,
                overlayStore,
                gameStore,
                game1Store,
                game2Store,
                game3Store,
                gameBreathingExercice,
                game4Store,
                game5Store,
                game6Store,
            } = data.storeInfo;

            useRoomStore.setState({ roomName: data.roomName });
            useDeviceStore.setState(deviceStore);
            useOverlayStore.setState(overlayStore);
            useGameStore.setState(gameStore);
            useGame1Store.setState(game1Store);
            useGame2Store.setState(game2Store);
            useGame3Store.setState(game3Store);
            useGameBreathingExerciceStore.setState(gameBreathingExercice);
            useGame4Store.setState(game4Store);
            useGame5Store.setState(game5Store);
            useGame6Store.setState(game6Store);
        });

        return () => {
            socket.off('receive_synchronize_device');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
