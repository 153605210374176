import { ClickSound } from '@/assets/sounds/soundsEffects';
import styles from '@/features/devices/tablets/components/buttons/gameTwo/selectObjectButton.module.scss';
import useVehicleObject from '@/hooks/useVehicleObject';
import {
    handleCarObjectSelectedGameTwo,
    handleTruckObjectSelectedGametwo,
} from '@/services/games/game2/gameTwoHandleFunctions';
import {
    assertIsCarObject,
    assertIsTruckObject,
} from '@/services/games/game2/gameTwoUtils';
import { playSound } from '@/services/global/globalUtils';
import { useGame2Store } from '@/store/games/game2Store';
import type {
    CarLocation,
    CarObject,
    TruckObject,
} from '@/types/games/gameTwo/types';
import { type DeviceColor, type Maybe, type Nullable } from '@/types/global/types';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';

type CarProps = {
    type: 'car';
    color: DeviceColor;
    carLocation: CarLocation;
    disabled?: false;
};

type TruckProps = {
    type: 'truck';
    disabled: boolean;
    color: DeviceColor;
};

type SelectObjectButtonProps = {
    locationName?: string;
    index: number;
} & (CarProps | TruckProps);

export default function SelectObjectButton(props: SelectObjectButtonProps) {
    const { selectedCarObjects, selectedTruckObjects } = useGame2Store();

    const currentObj = useMemo(() => {
        return props.type === 'car'
            ? selectedCarObjects[props.color][props.carLocation]
            : selectedTruckObjects[props.color];
    }, [props, selectedCarObjects, selectedTruckObjects]);

    const { type, color, index, locationName } = props;

    const [currentVehicleObject, setCurrentVehicleObject] =
        useState<Maybe<Nullable<CarObject | TruckObject>>>(currentObj);

    useEffect(() => {
        setCurrentVehicleObject(currentObj);
    }, [currentObj]);

    const nextVehicleObject = useVehicleObject(currentVehicleObject?.id);

    const handleCarButtonClick = (carLocation: CarLocation) => {
        if (type === 'truck') return;
        if (!nextVehicleObject) return;

        assertIsCarObject(nextVehicleObject);

        handleCarObjectSelectedGameTwo(carLocation, nextVehicleObject, color);
        setCurrentVehicleObject(nextVehicleObject);
    };

    const handleTruckButtonClick = () => {
        if (type === 'car') return;
        if (!nextVehicleObject) return;

        assertIsTruckObject(nextVehicleObject);

        handleTruckObjectSelectedGametwo(nextVehicleObject, color);
        setCurrentVehicleObject(nextVehicleObject);
    };

    const onButtonClick = () => {
        if (type === 'car') {
            handleCarButtonClick(props.carLocation);
        } else {
            handleTruckButtonClick();
        }
    };

    return (
        <div className={styles.container}>
            <p className={styles.location}>{locationName}</p>
            <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                    // TODO: update sound
                    playSound(ClickSound);
                    onButtonClick();
                }}
                disabled={props.disabled}
                className={
                    type === 'truck' || !currentVehicleObject
                        ? undefined
                        : styles.truck
                }
            >
                {(currentVehicleObject as TruckObject)?.isStrapped && (
                    <div className={styles.strapped}></div>
                )}
                {currentVehicleObject?.image ? (
                    <img
                        src={currentVehicleObject.image}
                        alt='object'
                        width={200}
                        height={200}
                    />
                ) : (
                    <p>{index + 1}</p>
                )}
            </motion.button>
        </div>
    );
}
