import { getSearch } from '@/api/search';
import type { SearchResponse } from '@/api/search/search.types';
import useDebounce from '@/hooks/useDebounce';
import { useEffect, useState } from 'react';

export function useDebouncedSearch(search: string | undefined, delay: number) {
    const debouncedSearch = useDebounce(search, delay);
    const [apiResponse, setApiResponse] = useState<SearchResponse>();

    useEffect(() => {
        const fetchData = async () => {
            if (!debouncedSearch || debouncedSearch.length < 3) {
                setApiResponse(undefined);
                return;
            }
            try {
                const result = await getSearch({ search: debouncedSearch });
                setApiResponse(result);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error while fetching', error);
            }
        };

        fetchData();
    }, [debouncedSearch]);

    return apiResponse;
}
