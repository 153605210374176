import { carObjects, truckObjects } from '@/data/gameTwoData.json';
import { isCarSelected } from '@/services/global/globalUtils';
import type { CarObject, TruckObject } from '@/types/games/gameTwo/types';

export default function useVehicleObject<TVehicle extends TruckObject | CarObject>(
    currentVehicleObjectId?: number
): TVehicle {
    const isCar = isCarSelected();

    const vehicleObjects = isCar
        ? (carObjects as CarObject[])
        : (truckObjects as TruckObject[]);

    if (
        !currentVehicleObjectId ||
        currentVehicleObjectId === vehicleObjects?.at(-1)!.id
    )
        return vehicleObjects[0] as TVehicle;

    const currentItemIndex = vehicleObjects.findIndex(
        (vehicleObject) => vehicleObject.id > currentVehicleObjectId
    );

    return vehicleObjects[currentItemIndex] as TVehicle;
}
