import styles from '@/features/components/gameTwo/gameTwo.module.scss';
import gameTwoData from '@/data/gameTwoData.json';
import { useGame2Store } from '@/store/games/game2Store';
import { useMemo } from 'react';
import AudioComponent from '@/features/components/AudioComponent';

export default function GameTwoCar() {
    const selectedItems = useGame2Store.use.selectedCarObjects();
    const carObjectLocations = Object.values(selectedItems);

    const locations = useMemo(() => {
        return carObjectLocations
            .map((position) => Object.entries(position))
            .flat();
    }, [carObjectLocations]);

    return (
        <div className={styles.carContainer}>
            <div>
                <h1>{gameTwoData.texts.loadingInProcessTitle}</h1>
                <h1>{gameTwoData.texts.loadingInProcess}</h1>
            </div>
            <AudioComponent src={gameTwoData.texts.loadingInProcessAudio} />
            <div className={styles.car}>
                {locations.map((location, index) => {
                    const selectedObject = location[1];

                    return (
                        <div
                            key={location[0] + index + selectedObject?.id}
                            className={
                                styles[
                                    `circle_${index + 1}_${selectedObject ? 'selected' : 'empty'}`
                                ]
                            }
                        >
                            {selectedObject?.image ? (
                                <img
                                    src={selectedObject.image}
                                    alt='Objet'
                                    width={110}
                                    height={110}
                                />
                            ) : (
                                <p>{index + 1}</p>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
