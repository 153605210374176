import game3Data from '@/data/gameThreeData.json';
import FullScreenButton from '@/features/devices/tablets/components/gameFive/FullScreenButton';
import { handleSwipeAction } from '@/services/games/game3/gameThreeHandleFunctions';
import { useGame3Store } from '@/store/games/game3Store';
import type { Game5Tablets } from '@/types/games/gameFive/types';
import type { SwipeAction } from '@/types/games/gameThree/types';
import type { DeviceColor } from '@/types/global/types';

const CHOICE_MAP = {
    left: 0,
    right: 1,
} as const;

export function NextSceneButton({
    choice,
    color,
}: {
    choice: 'left' | 'right';
    color: DeviceColor;
}) {
    const currentSceneIndex = useGame3Store.use.currentSceneIndex();
    const currentScene = game3Data.scenes[currentSceneIndex];

    const currentGroupIndex = useGame3Store.use.currentGroupIndex();
    const currentGroup = currentScene.groups[currentGroupIndex];
    const currentChoice = currentGroup.actions[CHOICE_MAP[choice]] as SwipeAction;

    return (
        <FullScreenButton
            color={color as Game5Tablets}
            text={game3Data.sceneActionsMap[currentChoice]}
            onClick={() => handleSwipeAction(currentChoice)}
        />
    );
}
