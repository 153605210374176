import AudioComponent from '@/features/components/AudioComponent';
import styles from '@/features/components/gameFive/gameFive.module.scss';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { userCurrentSituationGame5 } from '@/hooks/useCurrentSituationGame5';
import { useGame5Store } from '@/store/games/game5Store';

export default function GameFive() {
    const { overlayType } = useCurrentOverlay();
    const playersReaction = useGame5Store.use.playersAnswer();
    const currentSituation = userCurrentSituationGame5();

    return (
        <div className={styles.container}>
            {overlayType === 'neutral' && (
                <AudioComponent src={currentSituation.audio} />
            )}
            <p>Situation {currentSituation.id}</p>

            <div className={styles.content}>
                <img
                    src={currentSituation.image}
                    alt='car placeholder for the tv'
                />

                <div>
                    <div className={styles.descriptionContainer}>
                        <p>Description</p>
                        <p>{currentSituation.description}</p>
                    </div>

                    <div className={styles.playerAnswer}>
                        <p>Action sélectionnée</p>
                        <div>
                            <span className={styles[`${playersReaction?.name}`]} />
                            <p>{playersReaction?.name.toUpperCase()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
