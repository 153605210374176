import dataGameFive from '@/data/gameFiveData.json';
import dataGameSix from '@/data/gameSixData.json';
import mediatorDatas from '@/data/mediator/actionsGames.json';
import { handleTruckObjectStrappedGametwo } from '@/services/games/game2/gameTwoHandleFunctions';
import { handleGoToGameStatus } from '@/services/global/globalHandleFunctions';
import { getNextGameStatus } from '@/services/global/globalUtils';

import { handlePassResponseOverlay } from '@/services/games/game3/gameThreeHandleFunctions';
import {
    handleSetCurrentSituationIndex,
    handleTimerStatus,
} from '@/services/games/game6/game6HandleFunctions';
import { isSelectionCorrect } from '@/services/games/game6/game6Utils';
import { type ActionsGamesMapping } from '@/services/mediator/types.mediator';
import { useGame2Store } from '@/store/games/game2Store';
import { useGame5Store } from '@/store/games/game5Store';
import { useGame6Store } from '@/store/games/game6Store';
import type { OverlayType } from '@/types/games/types';
import {
    GAMESTATUS,
    type DeviceColor,
    type GameStatus,
} from '@/types/global/types';

export const actionsGames: ActionsGamesMapping = mediatorDatas;

export function handleOverlayCallbackMediator(
    gameStatus: GameStatus,
    overlayType: OverlayType
) {
    const isCorrect = isSelectionCorrect();
    const selectedTruckObjects = useGame2Store.getState().selectedTruckObjects;
    const currentIndex = useGame6Store.getState().currentSituationIndex;
    const totalIndex = dataGameSix.situations.length - 1;
    const isTimerRunning = useGame6Store.getState().startTimer;

    const currentSituationIndex = useGame5Store.getState().currentSituationIndex;
    const isLastGameFiveIndex =
        currentSituationIndex === dataGameFive.situations.length - 1;

    const isLastIndex = currentIndex === totalIndex;

    const handleSuccess = () => handleGoToGameStatus(getNextGameStatus());

    switch (gameStatus) {
        case GAMESTATUS.GAME2ACTION:
            if (overlayType === 'success') handleSuccess();
            else
                Object.keys(selectedTruckObjects).forEach((color) => {
                    handleTruckObjectStrappedGametwo(color as DeviceColor, false);
                });
            break;

        case GAMESTATUS.GAME3ACTION:
            handlePassResponseOverlay();
            break;

        case GAMESTATUS.GAME4ACTION:
            if (overlayType === 'success') handleSuccess();
            break;

        case GAMESTATUS.GAME5ACTION:
            if (overlayType === 'success' && isLastGameFiveIndex) handleSuccess();
            break;

        case GAMESTATUS.GAME6ACTION:
            if (isCorrect) {
                isLastIndex
                    ? handleSuccess()
                    : handleSetCurrentSituationIndex(currentIndex + 1);
            } else {
                if (isTimerRunning) {
                    handleTimerStatus(false);
                    isLastIndex
                        ? handleSuccess()
                        : handleSetCurrentSituationIndex(currentIndex + 1);
                } else {
                    isLastIndex ? handleSuccess() : handleTimerStatus(true);
                }
            }
            break;
    }
}
