import { Corentin, Elian, Grim, Max } from '@/assets/images/characters';
import globalData from '@/data/global.json';
import gameOneData from '@/data/gameOneData.json';
import styles from '@/features/devices/tablets/orange/components/phone/phone.module.scss';
import { useGame1Store } from '@/store/games/game1Store';
import { handleUpdateOverlayDescriptions } from '@/services/global/globalHandleFunctions';

const ICONS_MAP = {
    avatarMax: Max,
    avatarGrim: Grim,
    avatarElian: Elian,
    avatarCorentin: Corentin,
};

type IconsMapKey = keyof typeof ICONS_MAP;

export default function Messages() {
    const messages = gameOneData.messages;
    const shouldDisplayWarning = useGame1Store.use.isDoNotDisturbOn();

    const handleOpenMessage = () => {
        handleUpdateOverlayDescriptions(
            [
                {
                    id: 1,
                    title: gameOneData.phone.error.title,
                    text: gameOneData.phone.error.text,
                    audio: gameOneData.phone.error.audio,
                },
            ],
            'error'
        );
    };

    return (
        <div className={styles.list}>
            <h3>Messages</h3>
            {!shouldDisplayWarning && (
                <div className={styles.prevention}>
                    ⚠️
                    <p>&nbsp;{gameOneData.dontUsePhoneWhileDriving}</p>
                </div>
            )}
            {messages.map((msg) => (
                <button key={msg.id} onClick={handleOpenMessage}>
                    <div className={styles.row}>
                        <div className={styles.avatar}>
                            <img
                                src={ICONS_MAP[msg.icon as IconsMapKey]}
                                alt='avatar'
                                width={96}
                            />
                            <div>
                                <h3>{msg.from}</h3>
                                <p>{msg.preview}</p>
                            </div>
                        </div>
                        {msg.id < 2 && (
                            <div className={styles.badge}>
                                👋 {globalData.global.salute}
                            </div>
                        )}
                    </div>
                </button>
            ))}
        </div>
    );
}
