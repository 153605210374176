import useTimer from '@/features/components/timer/hooks/useTimer';
import styles from '@/features/components/timer/timer.module.scss';
import type { TimerTypes } from '@/features/components/timer/types';
import { useEffect, useState } from 'react';

type TimerProps = {
    mode: TimerTypes;
    threshold: number;
    isStarted: boolean;
    onAlert?: () => void;
    additionalStyles?: React.CSSProperties;
    size?: number;
};

const DEFAULT_SIZE = 300;
const DEFAULT_STROKE_WIDTH = 15;

export default function Timer({
    mode,
    threshold,
    isStarted,
    onAlert,
    additionalStyles,
    size = DEFAULT_SIZE,
}: TimerProps) {
    const [currentTime, setCurrentTime] = useState(
        mode === 'timer' ? 0 : threshold
    );

    const { time } = useTimer(currentTime);

    useEffect(() => {
        if (!isStarted) {
            setCurrentTime(mode === 'timer' ? 0 : threshold);
        }
    }, [isStarted, threshold, mode]);

    useEffect(() => {
        if (!onAlert || !isStarted) return;

        if (
            (mode === 'timer' && currentTime === threshold + 1) ||
            (mode === 'countdown' && currentTime === -1)
        ) {
            onAlert();
        }
    }, [isStarted, onAlert, currentTime, threshold, mode]);

    useEffect(() => {
        let intervalID: NodeJS.Timeout | null = null;
        if (isStarted) {
            intervalID = setInterval(() => {
                setCurrentTime((state) =>
                    mode === 'timer' ? state + 1 : state - 1
                );
            }, 1000);
        }

        return () => {
            if (intervalID) clearInterval(intervalID);
        };
    }, [isStarted, mode, currentTime]);

    const percentage =
        mode === 'timer'
            ? Math.min((currentTime / threshold) * 100, 100)
            : Math.min(((threshold - currentTime) / threshold) * 100, 100);

    const strokeWidth = (size / DEFAULT_SIZE) * DEFAULT_STROKE_WIDTH;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;
    const viewBoxSize = size;

    return (
        <div
            className={styles.timerContainer}
            style={{
                ...additionalStyles,
                width: `${size}px`,
                height: `${size}px`,
            }}
        >
            <svg viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
                <circle
                    cx={viewBoxSize / 2}
                    cy={viewBoxSize / 2}
                    r={radius}
                    fill='none'
                    stroke='rgb(217, 217, 217)'
                    strokeWidth={strokeWidth}
                />
                <circle
                    cx={viewBoxSize / 2}
                    cy={viewBoxSize / 2}
                    r={radius}
                    fill='none'
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    strokeWidth={strokeWidth}
                    stroke='rgb(198, 203, 255)'
                    style={{
                        transition: 'all 0.5s ease',
                        transform: 'rotate(0.75turn)',
                        transformOrigin: 'center',
                    }}
                />
            </svg>
            <p
                style={{
                    // Automatically adjust font size based on the size of the timer
                    fontSize: `${size / 5}px`,
                }}
            >
                {time}
            </p>
        </div>
    );
}
