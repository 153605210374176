import { destination } from '@/data/gameThreeData.json';
import type { SwipeAction } from '@/types/games/gameThree/types';
import type { Nullable } from '@/types/global/types';

const MIN_SEARCH_LENGTH = 8;

export function shouldShowDestination(search: Nullable<string>) {
    if (!search) return false;

    return (
        search.length > MIN_SEARCH_LENGTH &&
        destination.toLocaleLowerCase().search(search.toLowerCase()) !== -1
    );
}

export const MAP_COLOR_TO_ACTION: Record<SwipeAction, string> = {
    dangerous: '#EFE4D0',
    safe: '#CFE9ED',
    illegal: '#EFE4D0',
    legal: '#D3EFD1',
    useful: '#D1EECF',
    useless: '#EFE4D0',
};

export const MAP_SWIPE_ACTION_TO_DIRECTION: Record<SwipeAction, 'left' | 'right'> =
    {
        dangerous: 'left',
        safe: 'right',
        illegal: 'left',
        legal: 'right',
        useful: 'right',
        useless: 'left',
    };

export const MAP_SWIPE_ACTION_ENGLISH_TO_FRENCH: Record<SwipeAction, string> = {
    dangerous: 'dangereux',
    safe: 'sans danger',
    illegal: 'illégal',
    legal: 'légal',
    useful: 'utile',
    useless: 'inutile',
};
