import { BreathingVideo, CloudsSun } from '@/assets/images/global';
import { breathingSounds } from '@/assets/sounds/voices/gameThree/breathing';
import data from '@/data/gameBreathingExercise.json';
import AudioComponent from '@/features/components/AudioComponent';
import styles from '@/features/components/breathingExercice/breathingInfoScreen/breathing.module.scss';
import { useCycles } from '@/features/components/breathingExercice/hooks/useCycles';
import TextAnimation from '@/features/components/text/TextAnimation';
import { handleGoToGameStatus } from '@/services/global/globalHandleFunctions';
import { getNextGameStatus } from '@/services/global/globalUtils';
import { useGameBreathingExerciceStore } from '@/store/games/gameBreathingExerciceStore';
import { useGameStore } from '@/store/gameStore';
import { GAMESTATUS } from '@/types/global/types';
import { useEffect, useState } from 'react';

const INTRO_STEPS = ['third', 'second', 'first', 'zero'] as const;

export default function Breathing({
    isIntro = false,
}: {
    isIntro?: boolean;
    isPlaying?: boolean;
}) {
    const { playersStatus } = useGameBreathingExerciceStore();
    const currentGameStatus = useGameStore.use.gameStatus();
    const isEnded = currentGameStatus === GAMESTATUS.BREATHING_END;
    const { areCyclesCompleted } = useCycles(2, data.cycles);
    const [currentIntroStep, setCurrentIntroStep] = useState(0);

    const allPlaying = Object.values(playersStatus).every(
        (player) => player.isPlaying === true
    );

    useEffect(() => {
        if (
            areCyclesCompleted ||
            Object.values(playersStatus).every((device) => device.hasAskedToPass)
        ) {
            handleGoToGameStatus(getNextGameStatus());
        }
    }, [areCyclesCompleted, playersStatus]);

    const handleSubtitle = () => {
        if (isIntro) return data.intro[INTRO_STEPS[currentIntroStep]];

        return data.outro;
    };

    const handleAudioEnd = () => {
        setCurrentIntroStep((prev) => {
            if (prev < INTRO_STEPS.length - 1) return prev + 1;
            handleGoToGameStatus(GAMESTATUS.BREATHING_ACTION);
            return prev;
        });
    };

    useEffect(() => {
        const video = document.getElementById('breathVideo');

        const handleVideoEnd = () => {
            handleGoToGameStatus(GAMESTATUS.BREATHING_END);
        };

        video && video.addEventListener('ended', handleVideoEnd);

        return () => {
            video && video.removeEventListener('ended', handleVideoEnd);
        };
    }, []);

    return (
        <div className={styles.container}>
            {isIntro ? (
                <AudioComponent
                    onEndedCallback={handleAudioEnd}
                    src={breathingSounds[INTRO_STEPS[currentIntroStep]]}
                />
            ) : null}
            {isEnded ? (
                <div>
                    <AudioComponent src={data['audio-outro']} />
                    <div>
                        <h1>{data.title}</h1>
                        <TextAnimation text={handleSubtitle()} />
                    </div>
                    <img
                        src={CloudsSun}
                        className={styles.cloudsSuccess}
                        alt='Nuages de reussite'
                    />
                </div>
            ) : (
                <div>
                    <div>
                        <h1>{data.title}</h1>
                        <TextAnimation text={handleSubtitle()} />
                    </div>
                    {allPlaying && !isEnded ? (
                        <video
                            autoPlay
                            muted
                            id='breathVideo'
                            style={{
                                position: 'fixed',
                                right: 0,
                                bottom: 0,
                                minWidth: '100%',
                                minHeight: '100%',
                            }}
                            controls={false}
                            // should cover the full screen
                            playsInline
                        >
                            <source src={BreathingVideo} type='video/mp4' />
                        </video>
                    ) : null}
                </div>
            )}
        </div>
    );
}
