import type { DeviceColor } from '@/types/global/types';

export type PlayerStatus = {
    isReady: boolean;
    isPlaying: boolean;
    hasAskedToPass: boolean;
};

export type BreathingExerciceStore = {
    isStarted: boolean;
    playersStatus: Record<DeviceColor, PlayerStatus>;
    action: BreathingActions;
};

export type BreathingActions = 'inspire' | 'expire';

export const INITIAL_BREATHINGEXERCICE_STORE: BreathingExerciceStore = {
    isStarted: false,
    playersStatus: {
        blue: { isReady: false, isPlaying: false, hasAskedToPass: false },
        orange: { isReady: false, isPlaying: false, hasAskedToPass: false },
        green: { isReady: false, isPlaying: false, hasAskedToPass: false },
        red: { isReady: false, isPlaying: false, hasAskedToPass: false },
    },
    action: 'inspire',
};
