import gameThreeData from '@/data/gameThreeData.json';
import {
    handleCockpitAction,
    handleGoToGameStatus,
    handleTabletStatus,
    handleUpdateOverlayDescriptions,
} from '@/services/global/globalHandleFunctions';
import { DEVICES_COLOR, emitSocketEvent } from '@/services/global/globalUtils';
import { useGame3Store } from '@/store/games/game3Store';
import {
    type BreathingActions,
    type PlayerStatus,
} from '@/types/games/gameBreathingExercice/types';
import { type Itinerary, type SwipeAction } from '@/types/games/gameThree/types';
import { type DeviceColor, type Nullable } from '@/types/global/types';

// ----------------- Game 3 -----------------

export function handleContinueToScenes() {
    handleTabletStatus({
        blue: false,
        orange: true,
        green: true,
        red: false,
    });
    handleCockpitAction('GAME');
}

export function handleAudioPlayed(audioPlayed: boolean) {
    emitSocketEvent('send_update_game_three_audio_played', { audioPlayed });
}

export function handleSearch(text: Nullable<string>) {
    emitSocketEvent('send_update_search', { text });
}

export function handleShowDirections(status: boolean) {
    emitSocketEvent('send_update_show_directions', { status });
}

export function handleSelectedItinerary(itinerary: Itinerary) {
    emitSocketEvent('send_update_selected_itinerary', { itinerary });
}

export function handleSwipeAction(direction: Nullable<SwipeAction>) {
    const currentGroupIndex = useGame3Store.getState().currentGroupIndex;
    const currentSceneIndex = useGame3Store.getState().currentSceneIndex;
    const currentScene = gameThreeData.scenes[currentSceneIndex];
    const currentGroup = currentScene.groups[currentGroupIndex];

    const isCorrect = currentGroup.goodAnswer === direction;

    handleUpdateOverlayDescriptions(
        [
            {
                id: 1,
                audio: isCorrect ? currentGroup.goodAudio : currentGroup.badAudio,
                title: isCorrect ? 'Bravo !' : 'Mauvaise réponse',
                text: isCorrect
                    ? currentGroup.goodAnswerText
                    : currentGroup.badAnswerText,
            },
        ],
        isCorrect ? 'success' : 'error'
    );

    emitSocketEvent('send_propagate_swipe_action', { action: direction });
    handleTabletStatus({
        blue: true,
        orange: false,
        green: false,
        red: false,
    });
}

export function handlePassResponseOverlay() {
    const currentGroupIndex = useGame3Store.getState().currentGroupIndex;
    const currentSceneIndex = useGame3Store.getState().currentSceneIndex;
    const currentScene = gameThreeData.scenes[currentSceneIndex];

    // count the number of groups in the current scene
    const totalGroups = currentScene.groups.length;

    handleUpdateOverlayDescriptions([], 'neutral');
    handleTabletStatus({
        blue: false,
        orange: true,
        green: true,
        red: false,
    });

    // if we are at the last group, we go to the next scene
    if (currentGroupIndex === totalGroups - 1) {
        handleAudioPlayed(false);
        handleNextOrPreviousSceneIndex('next');
        return;
    }

    // if not, we update the group index to the next one
    handleUpdateCurrentGroupIndex(currentGroupIndex + 1);
}

export function handleNextOrPreviousSceneIndex(action: 'next' | 'previous') {
    const currentSceneIndex = useGame3Store.getState().currentSceneIndex;
    const totalIndex = gameThreeData.scenes.length - 1;

    // If this is the first scene and the action is previous, we do nothing
    if (currentSceneIndex === 0 && action === 'previous') return;

    // If this is the last scene and the action is next, we go to the breathing game exercise
    if (currentSceneIndex === totalIndex && action === 'next') {
        handleGoToGameStatus('BREATHING_INTRO');
        return;
    }
    handleAudioPlayed(false);
    // in both cases, we reset the group index to 0
    handleUpdateCurrentGroupIndex(0);

    emitSocketEvent('send_update_current_scene_index', {
        currentSceneIndex:
            action === 'next' ? currentSceneIndex + 1 : currentSceneIndex - 1,
    });
}

export function handleUpdateCurrentGroupIndex(index: number) {
    emitSocketEvent('send_update_current_group_index', {
        currentGroupIndex: index,
    });
}

// ----------------- Breathing exercice -----------------

export function handleStartBreathingGame() {
    emitSocketEvent('send_set_update_breathing_exercice_is_started', {
        isStarted: true,
    });

    handleTabletStatus({
        blue: true,
        orange: true,
        green: true,
        red: true,
    });
}

export function handleUpdateAllPlayersStatus(
    status: keyof PlayerStatus,
    value: boolean
) {
    emitSocketEvent('send_update_breathing_exercice_player_status', {
        device: DEVICES_COLOR,
        status,
        value,
    });
}

export function handleUpdatePlayerStatus(
    device: DeviceColor,
    status: keyof PlayerStatus,
    value: boolean
) {
    emitSocketEvent('send_update_breathing_exercice_player_status', {
        device,
        status,
        value,
    });
}

export function handleSwitchAction(currentAction: BreathingActions) {
    const actionCycleMap = {
        inspire: 'expire',
        expire: 'inspire',
    };
    const action = actionCycleMap[currentAction] as BreathingActions;
    emitSocketEvent('send_update_breathing_exercice_action', {
        action,
    });
}
