import BreathingZero from '@/assets/sounds/voices/gameThree/breathing/zero.mp3';
import BreathingFirst from '@/assets/sounds/voices/gameThree/breathing/first.mp3';
import BreathingSecond from '@/assets/sounds/voices/gameThree/breathing/second.mp3';
import BreathingThird from '@/assets/sounds/voices/gameThree/breathing/third.mp3';

export const breathingSounds = {
    zero: BreathingZero,
    first: BreathingFirst,
    second: BreathingSecond,
    third: BreathingThird,
};
