import { PapangImage, SalaziaImage } from '@/assets/images/global';
import data from '@/data/global.json';
import AudioComponent from '@/features/components/AudioComponent';
import styles from '@/features/components/modal/carouselConversation/carouselConversation.module.scss';
import TextAnimation from '@/features/components/text/TextAnimation';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { useGameStore } from '@/store/gameStore';

export default function CarouselConversation() {
    const { currentTitle, currentText, currentContent, currentTextAudio } =
        useCurrentOverlay();
    const vehicle = useGameStore.use.vehicle();

    if (!vehicle) return null;

    const vehicleToDisplay = vehicle === 'car' ? SalaziaImage : PapangImage;
    const vehicleContent = currentContent?.find(
        (content) => content.id === vehicle
    );

    let enrichedText = '';

    if (currentText) {
        enrichedText = currentText
            .replace(/\[VEHICLE_NAME\]/g, data.global[vehicle])
            .replace(/([.!?])/g, '$1\n\n');
    }

    return (
        <div className={styles.carouselConversationContainer}>
            <AudioComponent src={currentTextAudio} />
            <div className={styles.textContainer}>
                <h2>{currentTitle}</h2>
                <TextAnimation text={enrichedText ?? vehicleContent?.text} />
            </div>
            <div className={styles.vehicleContainer}>
                <img
                    src={vehicleToDisplay}
                    alt='voiture ou camion'
                    className={styles[vehicle]}
                />
            </div>
        </div>
    );
}
