import { DashLeft, DashRight } from '@/assets/images/global';
import TireImg from '@/assets/images/global/tire.svg';

interface TvTireProps {
    pressure: number;
    side: 'left' | 'right';
}

export default function TvTire({ pressure, side }: TvTireProps) {
    return (
        <>
            {side === 'right' && (
                <>
                    <img src={TireImg} alt='pneu' width={100} />
                    <img src={DashRight} alt='trait' height={5} />
                </>
            )}

            <div>
                <p
                    style={{
                        color: 'white',
                        fontSize: '2rem',
                    }}
                >
                    {pressure.toFixed(1)}
                </p>
                <p
                    style={{
                        color: 'white',
                        fontSize: '2rem',
                    }}
                >
                    BAR
                </p>
            </div>

            {side === 'left' && (
                <>
                    <img src={DashLeft} alt='trait' height={5} />
                    <img src={TireImg} alt='pneu' width={100} />
                </>
            )}
        </>
    );
}
