/* eslint-disable jsx-a11y/label-has-associated-control */
import { ClickSound } from '@/assets/sounds/soundsEffects';
import styles from '@/features/devices/tablets/components/buttons/switch.module.scss';
import { playSound } from '@/services/global/globalUtils';

interface SwitchProps {
    initialStatus?: boolean;
    clickHandler: () => void;
}
export default function Switch({
    initialStatus = false,
    clickHandler,
}: SwitchProps) {
    return (
        <>
            <label className={styles.switch}>
                <input
                    type='checkbox'
                    onChange={() => {
                        clickHandler();
                        playSound(ClickSound);
                    }}
                    checked={initialStatus}
                />
                <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
        </>
    );
}
