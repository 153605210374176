import data from '@/data/global.json';
import AudioComponent from '@/features/components/AudioComponent';
import styles from '@/features/components/badge/claimBadgeScreen.module.scss';
import {
    extractNumberFromGameStatus,
    NUMBER_OF_GAMES,
} from '@/services/global/globalUtils';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import { useEffect, useState } from 'react';

export default function ClaimBadgeScreen() {
    const overlayDescription = useOverlayStore.use.overlayDescriptions();
    const overlayIndex = useOverlayStore.use.currentOverlayIndex();
    const mode = useGameStore.use.mode();
    const currentVehicle = useGameStore.use.vehicle();
    const currentGameStatus = useGameStore.use.gameStatus();
    const gameNumber = extractNumberFromGameStatus(currentGameStatus);
    const currentCompletionPercentage = Math.floor(
        ((gameNumber - 1) / NUMBER_OF_GAMES) * 100
    );
    const [completionPercentage, setCompletionPercentage] = useState(
        currentCompletionPercentage
    );

    const currentBadge = data.badges.find((badge) => badge.id === gameNumber);

    useEffect(() => {
        const targetPercentage = Math.floor((gameNumber / NUMBER_OF_GAMES) * 100);
        const animationDuration = 2000;
        const startTime = performance.now();

        const animate = (currentTime: number) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / animationDuration, 1);
            const newPercentage = Math.floor(
                currentCompletionPercentage +
                    (targetPercentage - currentCompletionPercentage) * progress
            );
            setCompletionPercentage(newPercentage);

            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    }, [gameNumber, currentCompletionPercentage]);

    const currentTitle = overlayDescription[overlayIndex].title;
    const currentText = overlayDescription[overlayIndex][
        `text${mode!.charAt(0).toUpperCase()}${mode!.slice(1)}` as keyof (typeof overlayDescription)[0]
    ] as string;

    const currentTextAudio = overlayDescription[overlayIndex][
        `audio${mode!.charAt(0).toUpperCase()}${mode!.slice(1)}` as keyof (typeof overlayDescription)[0]
    ] as string;

    let enrichedText = '';

    if (currentText) {
        enrichedText = currentText.replace(/([.!?])/g, '$1\n\n');
    }

    return (
        <div className={styles.container}>
            <AudioComponent src={currentTextAudio!} />
            <div className={styles.header}>
                <p>{currentTitle}</p>
                <p>{enrichedText}</p>
            </div>
            <div className={styles.loaderContainer}>
                <div>
                    <p>
                        {data.global.complianceRate}: {completionPercentage}%
                    </p>
                    <p>
                        {data.global.unlockedBadge} {gameNumber}/{NUMBER_OF_GAMES}
                    </p>
                </div>
                <div className={styles.loader}>
                    <div
                        className={styles.progress}
                        style={{
                            width: `${completionPercentage}%`,
                            transition: 'width',
                        }}
                    />
                </div>
                <div>
                    <div>
                        <img
                            src={
                                currentBadge?.[
                                    currentVehicle === 'car'
                                        ? 'iconCar'
                                        : 'iconTruck'
                                ]
                            }
                            alt='badge'
                        />
                    </div>
                    <p>{currentBadge?.name}</p>
                </div>
            </div>
            <div></div>
        </div>
    );
}
