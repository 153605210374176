import dataGameTwo from '@/data/gameTwoData.json';
import globalData from '@/data/global.json';
import AstraLoading from '@/features/components/astraLoading/AstraLoading';
import DeviceBreathingScreen from '@/features/components/breathingExercice/deviceBreathingScreen/DeviceBreathingScreen';
import TirePressure from '@/features/components/gameOne/tirePressure/TirePressure';
import SelectVehicle from '@/features/components/vehicleSelection/SelectVehicle';
import ActionButton from '@/features/devices/tablets/components/buttons/ActionButton';
import Button from '@/features/devices/tablets/components/buttons/Button';
import EngineStartButton from '@/features/devices/tablets/components/buttons/EngineStartButton';
import SelectCarObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectCarObjectButton';
import SelectTruckObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectTruckObjectButton';
import FullScreenButton from '@/features/devices/tablets/components/gameFive/FullScreenButton';
import SelectSubstance from '@/features/devices/tablets/components/gameFour/SelectSubstance';
import SelectEmojiContainer from '@/features/devices/tablets/components/gameSix/SelectEmojiContainer';
import TabletLayout from '@/features/devices/tablets/components/screens/TabletLayout';
import { useDynamicTabletName } from '@/hooks/useDynamicTabletName';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { handleStartEngine } from '@/services/games/game1/gameOneHandleFunctions';
import { handlePassResponseOverlay } from '@/services/games/game3/gameThreeHandleFunctions';
import { isCarSelected } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { GAMESTATUS } from '@/types/global/types';

export default function BlueActionSwitch() {
    const { gameStatus, cockpitStatus } = useGameStore();
    const { blue } = useDeviceStore.use.deviceStatus();

    useDynamicTabletName('blue', blue.label);
    useReSynchronizeDevice('blue');

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.WELCOME_SCREEN:
                return (
                    <>
                        <ActionButton
                            title={globalData.global.start}
                            action='startGame'
                            color='black'
                        />
                    </>
                );
            case GAMESTATUS.VEHICLE_SELECTION:
                return <SelectVehicle type='truck' />;

            case GAMESTATUS.GAME1INTRODUCTION_LOADING:
            case GAMESTATUS.GAME2INTRODUCTION_LOADING:
            case GAMESTATUS.GAME3INTRODUCTION_LOADING:
            case GAMESTATUS.GAME4INTRODUCTION_LOADING:
            case GAMESTATUS.GAME5INTRODUCTION_LOADING:
            case GAMESTATUS.GAME6INTRODUCTION_LOADING:
                return <AstraLoading device='blue' />;

            case GAMESTATUS.BREATHING_ACTION:
                return <DeviceBreathingScreen device='blue' />;
            case GAMESTATUS.GAME2INTRODUCTION:
            case GAMESTATUS.GAME3INTRODUCTION:
            case GAMESTATUS.GAME4INTRODUCTION:
            case GAMESTATUS.GAME5INTRODUCTION:
            case GAMESTATUS.GAME6INTRODUCTION:
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.GAME2PREVENTION:
            case GAMESTATUS.GAME3PREVENTION:
            case GAMESTATUS.GAME4PREVENTION:
            case GAMESTATUS.GAME5PREVENTION:
            case GAMESTATUS.GAME6PREVENTION:
            case GAMESTATUS.GAME1UNLOCK:
            case GAMESTATUS.GAME2UNLOCK:
            case GAMESTATUS.GAME3UNLOCK:
            case GAMESTATUS.GAME4UNLOCK:
            case GAMESTATUS.GAME5UNLOCK:
            case GAMESTATUS.GAME6UNLOCK:
                return (
                    <ActionButton
                        action='goToNextOverlay'
                        title={globalData.global.next}
                        color='black'
                        style={{
                            width: '80%',
                        }}
                    />
                );
            case GAMESTATUS.GAME1ACTION:
                return cockpitStatus === 'TIRE' ? (
                    <TirePressure tire='RightRear' />
                ) : (
                    <EngineStartButton onClick={handleStartEngine} />
                );
            case GAMESTATUS.GAME2ACTION:
                return isCarSelected() ? (
                    <SelectCarObjectButton
                        color='blue'
                        title={dataGameTwo.texts.frontRightArea}
                    />
                ) : (
                    <SelectTruckObjectButton color='blue' />
                );

            case GAMESTATUS.GAME3ACTION:
                return (
                    <Button
                        color='black'
                        onClick={handlePassResponseOverlay}
                        text='Continuer'
                        additionalStyle={{
                            width: '80%',
                        }}
                    />
                );
            case GAMESTATUS.GAME4ACTION:
                return <SelectSubstance color='blue' />;

            case GAMESTATUS.GAME5ACTION:
                return <FullScreenButton color='blue' text='Courtoisie' />;

            case GAMESTATUS.GAME6ACTION:
                return <SelectEmojiContainer color='blue' />;

            case GAMESTATUS.FINISHED:
                break;
            default:
                break;
        }
    };

    return <TabletLayout device={blue}>{handleRender()}</TabletLayout>;
}
