import data from '@/data/gameFiveData.json';
import {
    handleGoToGameStatus,
    handleTabletStatus,
    handleUpdateOverlayDescriptions,
} from '@/services/global/globalHandleFunctions';
import { emitSocketEvent, getNextGameStatus } from '@/services/global/globalUtils';
import { useGame5Store } from '@/store/games/game5Store';
import { setOverlayCallback } from '@/store/overlayStore';
import type {
    Game5Situation,
    Game5Tablets,
    Reaction,
} from '@/types/games/gameFive/types';

export function handleSelectReactionByDeviceColor(deviceColor: Game5Tablets) {
    const selectedReaction = data.reactions[deviceColor] as Reaction;

    emitSocketEvent('send_players_answer_game_five', {
        selectedReaction,
    });
    handleTabletStatus({
        blue: true,
        orange: true,
        green: true,
        red: true,
    });
}

export function handleSelectReaction(situation: Game5Situation) {
    const goodReactionId = situation.goodReactionId;
    const reaction = Object.values(data.reactions).find(
        (reaction) => reaction.id === goodReactionId
    ) as Reaction;

    emitSocketEvent('send_players_answer_game_five', {
        selectedReaction: reaction,
    });
    handleTabletStatus({
        blue: true,
        orange: true,
        green: true,
        red: true,
    });
}

export function handleUpdateCurrentSituationIndex(index: number) {
    emitSocketEvent('send_update_situation_game_five', {
        index,
    });
}

export function handleValidateGame5() {
    const currentSituationIndex = useGame5Store.getState().currentSituationIndex;
    const currentReaction = useGame5Store.getState().playersAnswer;
    const errorDescription = currentReaction?.errorMessage;

    const { goodReactionId, successMessage } = data.situations[
        currentSituationIndex
    ] as Game5Situation;

    const isLastIndex = currentSituationIndex === data.situations.length - 1;

    const isGoodAnswer = currentReaction?.id === goodReactionId;

    if (isGoodAnswer && !isLastIndex) {
        handleUpdateCurrentSituationIndex(currentSituationIndex + 1);
    }

    const overlayDescription = isGoodAnswer ? successMessage : errorDescription;

    handleUpdateOverlayDescriptions(
        [
            {
                id: currentSituationIndex,
                title: overlayDescription?.title ?? '',
                text: overlayDescription?.text ?? '',
                audio: overlayDescription?.audio ?? '',
            },
        ],
        isGoodAnswer ? 'success' : 'error'
    );

    if (isGoodAnswer && isLastIndex) {
        setOverlayCallback(() => {
            handleGoToGameStatus(getNextGameStatus());
        });
    }

    emitSocketEvent('send_validate_game_five', {
        isGoodAnswer,
    });
}
