import { INITIAL_GAME4_STORE } from '@/sessionInfo/sessionInfo';
import createSelectors from '@/store/selectors';
import type {
    Game4Store,
    Game4Tablets,
    ItemStatus,
    Substance,
} from '@/types/games/gameFour/types';
import { create } from 'zustand';

const useGame4StoreBase = create<Game4Store>(() =>
    structuredClone(INITIAL_GAME4_STORE)
);

export const useGame4Store = createSelectors(useGame4StoreBase);

export function setSelectedSubstance(
    color: Game4Tablets,
    substance: Substance
): void {
    useGame4StoreBase.setState((state) => ({
        itemsSelected: {
            ...state.itemsSelected,
            [color]: substance,
        },
    }));
}

export function setItemStatus(color: Game4Tablets, status: ItemStatus) {
    useGame4StoreBase.setState((state) => ({
        itemStatuses: {
            ...state.itemStatuses,
            [color]: status,
        },
    }));
}

export function resetGame4Store() {
    useGame4StoreBase.setState(structuredClone(INITIAL_GAME4_STORE));
}
