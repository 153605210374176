import mediatorData from '@/features/devices/tablets/mediator/gamesComponents/gameComponentsTexts.json';
import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import {
    handleGameFourValidation,
    handleSelectCorrectAnswers,
} from '@/services/games/game4/gameFourHandleFunctions';
import {
    handleGoToGameStatus,
    handleUpdateOverlayType,
} from '@/services/global/globalHandleFunctions';
import { getNextGameStatus } from '@/services/global/globalUtils';
import { useGame4Store } from '@/store/games/game4Store';

export default function GameFourMediator() {
    const itemsSelected = useGame4Store.use.itemsSelected();
    const { overlayType } = useCurrentOverlay();

    return (
        <div>
            {/* Select the correct answers */}
            <MediatorAction
                title={mediatorData.global.selectGoodAnswers}
                displayingCondition={overlayType === 'neutral'}
                handleFunction={handleSelectCorrectAnswers}
                type='secondary'
            />

            {/* Validate the current answer */}
            <MediatorAction
                title={mediatorData.global.validateCurrentAnswes}
                displayingCondition={
                    // display only if the items are selected
                    Object.values(itemsSelected).every((item) => item) &&
                    overlayType === 'neutral'
                }
                handleFunction={handleGameFourValidation}
                type='secondary'
            />

            {/* Skip the overlay */}
            <MediatorAction
                title='Passer le message'
                handleFunction={() => {
                    handleUpdateOverlayType('neutral');
                    if (overlayType === 'success') {
                        handleGoToGameStatus(getNextGameStatus());
                    }
                }}
                displayingCondition={overlayType !== 'neutral'}
                type='tertiary'
                description='Passer le message'
            />
        </div>
    );
}
