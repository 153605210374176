import { ErrorGameThree, SuccessGameThree } from '@/assets/images/global';
import { ErrorGif, Success } from '@/assets/images/icons';
import {
    ErrorSound,
    PoliceSound,
    SuccessSound,
} from '@/assets/sounds/soundsEffects';
import data from '@/data/global.json';
import AudioComponent from '@/features/components/AudioComponent';
import Button from '@/features/devices/tablets/components/buttons/Button';
import { handleUpdateOverlayDescriptions } from '@/services/global/globalHandleFunctions';
import { playSound } from '@/services/global/globalUtils';
import { useGame3Store } from '@/store/games/game3Store';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import styles from './errorAndSuccess.module.scss';

interface ErrorAndSuccessScreenProps {
    title: string;
    text: string;
    success: boolean;
    audio?: string;
    isTablet?: boolean;
}

const springTransition = {
    type: 'spring',
    stiffness: 260,
    damping: 20,
    duration: 0.3,
};

const slideVariants = {
    entering: { y: 50, opacity: 0, scale: 0.3 },
    entered: { y: 0, opacity: 1, scale: 1 },
    exiting: { y: 50, opacity: 0, scale: 0.3 },
};

const fadeScaleVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
};

function GameThreeMessage({
    success,
    title,
    text,
}: Pick<ErrorAndSuccessScreenProps, 'success' | 'title' | 'text'>) {
    const enrichedText = text.replace(/([.!?])/g, '$1\n\n');

    return (
        <motion.div
            className={styles.gameThreeContainer}
            initial='initial'
            animate='animate'
            exit='exit'
            variants={fadeScaleVariants}
            transition={springTransition}
        >
            <img
                src={success ? SuccessGameThree : ErrorGameThree}
                alt={success ? 'Success indicator' : 'Error indicator'}
            />
            <h1>{title}</h1>
            <p>{enrichedText}</p>
        </motion.div>
    );
}

function StandardMessage({
    success,
    title,
    text,
}: Pick<ErrorAndSuccessScreenProps, 'success' | 'title' | 'text'>) {
    const overlayCallback = useOverlayStore.use.overlayCallback();
    const isAudioPlaying = useGameStore.use.isAudioPlaying();

    return (
        <motion.div
            initial='entering'
            animate='entered'
            exit='exiting'
            variants={slideVariants}
            transition={{
                type: 'spring',
                duration: 0.5,
                bounce: 0.3,
            }}
            className={styles.container}
        >
            <img
                src={success ? Success : ErrorGif}
                alt={success ? 'Success checkmark' : 'Error cross'}
            />
            <h1>{title}</h1>
            <h3>{text}</h3>
            <div className={styles.buttonContainer}>
                <Button
                    color='white'
                    text={data.global.next}
                    onClick={() => {
                        handleUpdateOverlayDescriptions([], 'neutral');
                        overlayCallback?.();
                    }}
                    disabled={isAudioPlaying}
                />
            </div>
        </motion.div>
    );
}

export default function ErrorAndSuccessScreen({
    title,
    text,
    success = false,
    isTablet,
    audio,
}: ErrorAndSuccessScreenProps) {
    const isGameThree = useGameStore.use.gameStatus() === 'GAME3ACTION';
    const isItinerarySelected = useGame3Store.use.selectedItinerary();

    useEffect(() => {
        if (isTablet) return;

        let sound;
        if (success) sound = SuccessSound;
        else if (isItinerarySelected) sound = PoliceSound;
        else if (isGameThree) sound = ErrorSound;
        else sound = ErrorSound;

        playSound(sound);
    }, [success, isTablet, isGameThree, isItinerarySelected]);

    if (isGameThree && isTablet && isItinerarySelected) return null;

    return (
        <div className={styles.wrapper}>
            {!isTablet && <AudioComponent src={audio!} />}
            <AnimatePresence mode='wait'>
                {isGameThree && isItinerarySelected ? (
                    <GameThreeMessage success={success} title={title} text={text} />
                ) : (
                    <StandardMessage success={success} title={title} text={text} />
                )}
            </AnimatePresence>
        </div>
    );
}
