import styles from '@/features/devices/tablets/orange/components/phone/phone.module.scss';
import { handleUpdateOverlayDescriptions } from '@/services/global/globalHandleFunctions';

import {
    ClockWhite,
    MessageTinyWhite,
    PhoneHorizontalWhite,
    PhoneWhite,
} from '@/assets/images/icons';
import { PhoneDring } from '@/assets/sounds/soundsEffects';
import gameOneData from '@/data/gameOneData.json';
import data from '@/data/global.json';
import AudioComponent from '@/features/components/AudioComponent';
import { handleReceiveCall } from '@/services/games/game1/gameOneHandleFunctions';
import { useGame1Store } from '@/store/games/game1Store';

export default function CallEntry({ fromTv }: { fromTv: boolean }) {
    const callerId = useGame1Store.use.callerId();
    const caller = gameOneData.callerPool[callerId];

    const handleManageCall = (action: 'pickup' | 'hangup'): void => {
        if (action === 'pickup') {
            handleUpdateOverlayDescriptions(
                [
                    {
                        id: gameOneData.phone.error.id,
                        title: gameOneData.phone.error.title,
                        text: gameOneData.phone.error.text,
                        audio: gameOneData.phone.error.audio,
                    },
                ],
                'error'
            );
        }
        handleReceiveCall(false);
    };
    return (
        <div className={styles.call_entry}>
            {!fromTv && <AudioComponent src={PhoneDring} />}
            <h1>{caller.caller}</h1>
            <h3>{data.global.incomingCall}</h3>
            <div className={styles.shortcuts}>
                <div>
                    <img src={ClockWhite} alt='' width={48} />
                    <p>{data.global.reminder}</p>
                </div>
                <div>
                    <img src={MessageTinyWhite} alt='' width={48} />
                    <p>{data.global.message}</p>
                </div>
            </div>
            <div className={styles.actions}>
                <button
                    type='button'
                    className={`${styles.hangup}`}
                    onClick={() => handleManageCall('hangup')}
                >
                    <img src={PhoneHorizontalWhite} alt='telephone' width={64} />
                </button>
                <button
                    type='button'
                    className={`${styles.pickup}`}
                    onClick={() => handleManageCall('pickup')}
                >
                    <img src={PhoneWhite} alt='telephone' width={64} />
                </button>
            </div>
        </div>
    );
}
