import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';

export default function useCurrentOverlay() {
    const { overlayDescriptions, currentOverlayIndex, overlayType } =
        useOverlayStore();

    const vehicle = useGameStore.use.vehicle();

    const currentOverlayDescriptions = overlayDescriptions.filter(
        (desc) => !desc.category || desc.category === vehicle
    );

    const currentTextAudio = currentOverlayDescriptions[currentOverlayIndex]?.audio;
    const currentTitle = currentOverlayDescriptions[currentOverlayIndex]?.title;
    const currentText = currentOverlayDescriptions[currentOverlayIndex]?.text;
    const currentContent = currentOverlayDescriptions[currentOverlayIndex]?.content;
    const currentFooter = currentOverlayDescriptions[currentOverlayIndex]?.footer;
    const currentAudioFooter =
        currentOverlayDescriptions[currentOverlayIndex]?.footerAudio;

    return {
        currentTextAudio,
        currentTitle,
        currentText,
        overlayType,
        currentContent,
        currentFooter,
        currentAudioFooter,
    };
}
