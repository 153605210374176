import { GameFourSituation } from '@/assets/images/global';
import data from '@/data/gameFourData.json';
import styles from '@/features/components/gameFour/gameFour.module.scss';
import { useGame4Store } from '@/store/games/game4Store';
import type { Game4Tablets } from '@/types/games/gameFour/types';

export default function GameFour() {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div>
                    <h1>{data.texts.crash}</h1>
                    <p>{data.texts.descriptionFirst}</p>
                    <p>{data.texts.description}</p>
                </div>
            </div>
            <div className={styles.content}>
                <img src={GameFourSituation} alt='car placeholder for the tv' />
                <div>
                    <ItemSelection color='blue' />
                    <div className={styles.separator} />
                    <ItemSelection color='orange' />
                </div>
            </div>
        </div>
    );
}

function ItemSelection({ color }: { color: Game4Tablets }) {
    const itemsSelected = useGame4Store.use.itemsSelected();
    const itemStatus = useGame4Store.use.itemStatuses()[color];
    const substance = itemsSelected[color];

    const handleStyle = () => {
        if (itemStatus === 'success') return styles.success;
        if (itemStatus === 'error') return styles.error;
        return undefined;
    };

    return (
        <div className={styles.itemSelectionContainer}>
            <p>
                {data.texts.vehicle} {color === 'blue' ? 'A' : 'B'}
            </p>
            <div className={handleStyle()}>
                <div className={styles.substanceIconContainer}>
                    {substance?.image && (
                        <img src={substance.image} alt='icone de la substance' />
                    )}
                </div>
                <p>{substance?.name ?? data.texts.waitingSelection}</p>
            </div>
        </div>
    );
}
