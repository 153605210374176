import { ClickSound } from '@/assets/sounds/soundsEffects';
import game3Data from '@/data/gameThreeData.json';
import globalData from '@/data/global.json';
import Direction from '@/features/components/gameThree/map/components/Itineraries/Direction';
import styles from '@/features/components/gameThree/map/components/Itineraries/itineraries.module.scss';
import Button from '@/features/devices/tablets/components/buttons/Button';
import { handleSelectedItinerary } from '@/services/games/game3/gameThreeHandleFunctions';
import { handleCockpitAction } from '@/services/global/globalHandleFunctions';
import { playSound } from '@/services/global/globalUtils';
import { useGame3Store } from '@/store/games/game3Store';
import type { Itinerary } from '@/types/games/gameThree/types';
import { type DeviceColorWithTV } from '@/types/global/types';
import { motion } from 'framer-motion';

interface ItinerariesProps {
    device: DeviceColorWithTV;
}

export default function Itineraries({ device }: ItinerariesProps) {
    const selectedItinerary = useGame3Store.use.selectedItinerary();
    const itineraries = game3Data.itineraries as Itinerary[];

    return (
        <ul
            className={
                styles[
                    device === 'tv'
                        ? 'itinerariesContainerTv'
                        : 'itinerariesContainerTablet'
                ]
            }
        >
            {itineraries.map((itinerary) => (
                <li
                    key={itinerary.id}
                    className={
                        itinerary.id === selectedItinerary?.id
                            ? styles.selected
                            : ''
                    }
                >
                    {device === 'tv' ? (
                        <Direction itinerary={itinerary} />
                    ) : (
                        <motion.button
                            type='button'
                            onClick={() => {
                                // TODO: update sound
                                playSound(ClickSound);
                                handleSelectedItinerary(itinerary);
                            }}
                            className={`${styles.clickableDirectionButton} ${itinerary.id === selectedItinerary?.id ? styles.selected : ''}`}
                            whileTap={{
                                scale: 0.8,
                            }}
                        >
                            <img src={itinerary.tabletImage} alt='itineraire' />
                        </motion.button>
                    )}
                </li>
            ))}
            {device !== 'tv' && (
                <Button
                    text={globalData.global.validate}
                    color='black'
                    onClick={() => {
                        handleCockpitAction('INFO');
                    }}
                    additionalStyle={{
                        width: '60%',
                        placeSelf: 'center',
                        margin: 0,
                    }}
                />
            )}
        </ul>
    );
}
