import { Corentin, Elian, Grim, Max } from '@/assets/images/characters';

import gameOneData from '@/data/gameOneData.json';
import globalData from '@/data/global.json';
import styles from '@/features/devices/tablets/orange/components/phone/phone.module.scss';
import { handleUpdateOverlayDescriptions } from '@/services/global/globalHandleFunctions';
import { useGame1Store } from '@/store/games/game1Store';

const ICONS_MAP = {
    avatarMax: Max,
    avatarGrim: Grim,
    avatarElian: Elian,
    avatarCorentin: Corentin,
};

type IconsMapKey = keyof typeof ICONS_MAP;

export default function CallHistory() {
    const calls = gameOneData.callerPool;
    const shouldDisplayWarning = useGame1Store.use.isDoNotDisturbOn();

    const handleCallback = () => {
        handleUpdateOverlayDescriptions(
            [
                {
                    id: 1,
                    title: gameOneData.phone.error.title,
                    text: gameOneData.phone.error.text,
                    audio: gameOneData.phone.error.audio,
                },
            ],
            'error'
        );
    };

    return (
        <div className={styles.list}>
            <h3>Appels</h3>
            {!shouldDisplayWarning && (
                <div className={styles.prevention}>
                    ⚠️
                    <p>&nbsp;{gameOneData.dontUsePhoneWhileDriving}</p>
                </div>
            )}
            {calls.map((call) => (
                <div className={styles.row} key={call.id}>
                    <div className={styles.avatar}>
                        <img
                            src={ICONS_MAP[call.icon as IconsMapKey]}
                            width={96}
                            alt='avatar'
                        />
                        <div>
                            <h3>{call.caller}</h3>
                            <p>
                                {call.device} | {call.time}
                            </p>
                        </div>
                    </div>

                    <div className={`${styles.badge} ${styles.callback}`}>
                        <button type='button' onClick={handleCallback}>
                            {globalData.global.callback}
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
}
