import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import { handleGoToGameStatus } from '@/services/global/globalHandleFunctions';
import { useGameStore } from '@/store/gameStore';
import { GAMESTATUS } from '@/types/global/types';

export default function BreathingExerciceMediator() {
    const gameStatus = useGameStore.use.gameStatus();

    return (
        <>
            <MediatorAction
                title='Passer'
                description='Passer le jeu de respiration'
                handleFunction={() =>
                    handleGoToGameStatus(GAMESTATUS.GAME3PREVENTION)
                }
                displayingCondition
                type='secondary'
            />
            <MediatorAction
                title='Continuer'
                handleFunction={() => handleGoToGameStatus('BREATHING_ACTION')}
                type='secondary'
                displayingCondition={gameStatus === GAMESTATUS.BREATHING_INTRO}
            />
        </>
    );
}
