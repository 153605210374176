import type { Nullable } from '@/types/global/types';
import { useCallback, useEffect, useState } from 'react';

const TOTAL_SEC_IN_MIN = 60;
const TOTAL_SEC_IN_HOUR = 3600;

interface Timer {
    seconds: number;
    minutes: number;
    hours: Nullable<number>;
    time: string;
}

export default function useTimer(origin: number, separator = ':'): Timer {
    const [seconds, setSeconds] = useState(origin);
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState<Nullable<number>>(null);
    const [time, setTime] = useState('00:00');

    const formatTime = useCallback(
        (sec: number, min: number, h: Nullable<number>): string => {
            const displayedHours = h
                ? h > 10
                    ? h + separator
                    : `0${h}${separator}`
                : '';

            return `${displayedHours} ${min > 10 ? min : '0' + min} ${separator} ${sec > 10 ? sec : '0' + sec}`;
        },
        [separator]
    );

    useEffect(() => {
        if (origin < 0) return;

        const h = Math.floor(origin / TOTAL_SEC_IN_HOUR);
        const min = Math.floor((origin % TOTAL_SEC_IN_HOUR) / TOTAL_SEC_IN_MIN);
        const sec = Math.floor((origin % TOTAL_SEC_IN_HOUR) % TOTAL_SEC_IN_MIN);

        setSeconds(sec);
        setMinutes(min);

        if (h) {
            setHours(h);
        }
    }, [origin, hours]);

    useEffect(() => {
        setTime(formatTime(seconds, minutes, hours));
    }, [seconds, minutes, hours, formatTime]);

    return { seconds, minutes, hours, time };
}
