import game3Data from '@/data/gameThreeData.json';
import { INITIAL_GAME3_STORE } from '@/sessionInfo';
import createSelectors from '@/store/selectors';
import type {
    Game3Store,
    Itinerary,
    Scene,
    SwipeAction,
} from '@/types/games/gameThree/types';
import type { Nullable } from '@/types/global/types';
import { create } from 'zustand';

export const firtScene = game3Data.scenes[0] as Scene;

const useGame3StoreBase = create<Game3Store>(() =>
    structuredClone(INITIAL_GAME3_STORE)
);

export const useGame3Store = createSelectors(useGame3StoreBase);

export function setSearch(search: Nullable<string>) {
    useGame3StoreBase.setState({ search });
}

export function setShowDirections(status: boolean) {
    useGame3StoreBase.setState({
        showDirections: status,
    });
}

export function setAudioPlayed(status: boolean) {
    useGame3StoreBase.setState({
        audioPlayed: status,
    });
}

export function setSelectedItinerary(itinerary: Itinerary) {
    useGame3StoreBase.setState({ selectedItinerary: itinerary });
}

export function setCurrentSceneIndex(currentSceneIndex: number) {
    useGame3StoreBase.setState({ currentSceneIndex });
}

export function setCurrentGroupIndex(currentGroupIndex: number) {
    useGame3StoreBase.setState({ currentGroupIndex });
}

export function setSwipeAction(swipeAction: Nullable<SwipeAction>) {
    useGame3StoreBase.setState({ swipeAction });
}

export function resetGame3Store() {
    useGame3StoreBase.setState(structuredClone(INITIAL_GAME3_STORE));
}
