import {
    Acceleration,
    Calling,
    ChangePath,
    ClipPathGroup,
    Cross,
    Eating,
    Exit,
    GasStation,
    Glue,
    PhoneScene,
    StopBand,
} from '@/assets/images/scenes';
import styles from '@/features/components/gameThree/scenes/scenes.module.scss';
import type { Scene } from '@/types/games/gameThree/types';

const SCENE_IMAGES = {
    acceleration: Acceleration,
    calling: Calling,
    changePath: ChangePath,
    clipPathGroup: ClipPathGroup,
    cross: Cross,
    eating: Eating,
    exit: Exit,
    gasStation: GasStation,
    glue: Glue,
    phone: PhoneScene,
    stopBand: StopBand,
} as const;

type IMAGE_KEY = keyof typeof SCENE_IMAGES;

export default function SwipableScene({ scene }: { scene: Scene }) {
    const propositionText = scene.proposition.replace(/([.!?])/g, '$1\n\n');

    return (
        <div className={styles.card}>
            <img src={SCENE_IMAGES[scene.image as IMAGE_KEY]} alt='scenes' />
            <div>
                <h2>Proposition {scene.id}</h2>
                <p>{propositionText}</p>
            </div>
        </div>
    );
}
