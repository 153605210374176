import data from '@/data/gameSixData.json';
import { isSelectionCorrect } from '@/services/games/game6/game6Utils';
import {
    handleGoToGameStatus,
    handleUpdateOverlayDescriptions,
} from '@/services/global/globalHandleFunctions';
import { emitSocketEvent, getNextGameStatus } from '@/services/global/globalUtils';
import { useGame6Store } from '@/store/games/game6Store';
import { setOverlayCallback } from '@/store/overlayStore';
import type { DeviceColor } from '@/types/global/types';

export function handleSetResponse(color: DeviceColor, responseId: number) {
    emitSocketEvent('send_response_game_six', { color, responseId });
}

export function handleResetGameSixReponses() {
    emitSocketEvent('send_reset_responses_game_six', {});
}

export function handleSetCurrentSituationIndex(currentSituationIndex: number) {
    emitSocketEvent('send_current_situation_index_game_six', {
        currentSituationIndex,
    });
}

export function handleTimerStatus(status: boolean) {
    emitSocketEvent('send_timer_status_game_six', { status });
}

export function handleGameSixValidation(fromTimer = false) {
    const isCorrect = isSelectionCorrect();
    const currentIndex = useGame6Store.getState().currentSituationIndex;
    const totalIndex = data.situations.length - 1;
    const currentSituation = data.situations[currentIndex];

    const isLastIndex = currentIndex === totalIndex;

    const status = isCorrect && fromTimer === false ? 'success' : 'error';

    handleUpdateOverlayDescriptions(
        [
            {
                id: currentSituation[status].id,
                title: currentSituation[status].title,
                text: currentSituation[status].text,
                audio: currentSituation[status].audio,
            },
        ],
        status
    );

    if (fromTimer) {
        if (isLastIndex) {
            setOverlayCallback(() => {
                handleGoToGameStatus(getNextGameStatus());
            });
            return;
        }
        setOverlayCallback(() => {
            handleTimerStatus(false);
            handleSetCurrentSituationIndex(currentIndex + 1);
        });
        return;
    }

    if (isCorrect) {
        setOverlayCallback(() => {
            isLastIndex
                ? handleGoToGameStatus(getNextGameStatus())
                : handleSetCurrentSituationIndex(currentIndex + 1);
        });
    } else {
        setOverlayCallback(() => {
            handleTimerStatus(true);
        });
    }
}
