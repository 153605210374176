import commonData from '@/data/global.json';
import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import {
    handleGoToGameStatus,
    handleVehicleSelection,
} from '@/services/global/globalHandleFunctions';
import { getNextGameStatus } from '@/services/global/globalUtils';
import { useGameStore } from '@/store/gameStore';

export default function SelectVehicleMediator() {
    const vehicle = useGameStore.use.vehicle();

    return (
        <>
            <MediatorAction
                title={`Valider ${commonData.global[vehicle!]}`}
                handleFunction={() => handleGoToGameStatus(getNextGameStatus())}
                displayingCondition={!!vehicle}
                type='secondary'
            />

            <MediatorAction
                title='Choisir Salazia'
                description='Sélectionner le véhicule léger pour le reste de la partie.'
                handleFunction={() => handleVehicleSelection('car')}
                displayingCondition={vehicle !== 'car'}
            />

            <MediatorAction
                title='Choisir Papang'
                description='Sélectionner le véhicule lourd pour le reste de la partie.'
                handleFunction={() => handleVehicleSelection('truck')}
                displayingCondition={vehicle !== 'truck'}
            />
        </>
    );
}
