import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';

import { useGameStore } from '@/store/gameStore';

import dataGameOne from '@/data/gameOneData.json';
import {
    handleResetSeatBelt,
    handleResetTirePressure,
    handleSeatBeltFastening,
    handleSetDoNotDisturb,
    handleStartEngine,
} from '@/services/games/game1/gameOneHandleFunctions';
import { handleMediatorSetTiresPressure } from '@/services/mediator/game1/mediatorUtilsGameOne';
import { useGame1Store } from '@/store/games/game1Store';

export default function GameOneMediator() {
    const vehicle = useGameStore.use.vehicle();
    const tirePressures = useGame1Store.use.tiresPressure();
    const isBeltClipped = useGame1Store.use.isBeltClipped();
    const isDoNotDisturbOn = useGame1Store.use.isDoNotDisturbOn();

    const isTirePressureValid = () => {
        const tirePressure = Object.values(tirePressures);
        return tirePressure.every(
            (pressure) => pressure === dataGameOne[`${vehicle!}TirePressure`]
        );
    };

    return (
        <>
            <MediatorAction
                title='Attacher la ceinture'
                handleFunction={() => handleSeatBeltFastening(true)}
                displayingCondition={!isBeltClipped}
                type='secondary'
            />

            <MediatorAction
                title='Détacher la ceinture'
                handleFunction={handleResetSeatBelt}
                displayingCondition={isBeltClipped}
                type='secondary'
            />

            <MediatorAction
                title='Régler la pression des pneus'
                handleFunction={() => {
                    handleMediatorSetTiresPressure(
                        dataGameOne[`${vehicle!}TirePressure`]
                    );
                }}
                displayingCondition={!isTirePressureValid()}
                type='secondary'
            />

            <MediatorAction
                title='Réinitialiser la pression des pneus'
                handleFunction={handleResetTirePressure}
                displayingCondition={isTirePressureValid()}
                type='secondary'
            />

            <MediatorAction
                title='Activer le mode Ne pas déranger'
                handleFunction={() => handleSetDoNotDisturb(true)}
                displayingCondition={!isDoNotDisturbOn}
                type='secondary'
            />

            <MediatorAction
                title='Désactiver le mode Ne pas déranger'
                handleFunction={() => handleSetDoNotDisturb(false)}
                displayingCondition={isDoNotDisturbOn}
                type='secondary'
            />

            <MediatorAction
                title='Démarrer le véhicule'
                handleFunction={handleStartEngine}
                displayingCondition={
                    isBeltClipped && isTirePressureValid() && isDoNotDisturbOn
                }
                type='tertiary'
            />
        </>
    );
}
