import createSelectors from '@/store/selectors';
import {
    INITIAL_BREATHINGEXERCICE_STORE,
    type BreathingActions,
    type BreathingExerciceStore,
    type PlayerStatus,
} from '@/types/games/gameBreathingExercice/types';
import type { DeviceColor } from '@/types/global/types';
import { create } from 'zustand';

const useGameBreathingExerciceStoreBase = create<BreathingExerciceStore>(() =>
    structuredClone(INITIAL_BREATHINGEXERCICE_STORE)
);

export const useGameBreathingExerciceStore = createSelectors(
    useGameBreathingExerciceStoreBase
);

export function toggleIsStarted() {
    useGameBreathingExerciceStoreBase.setState((state) => ({
        ...state,
        isStarted: !state.isStarted,
    }));
}

export function setPlayerStatus(
    device: DeviceColor | Array<DeviceColor>,
    status: keyof PlayerStatus,
    value: boolean
) {
    if (typeof device === 'object') {
        const newStatus = device.reduce(
            (output, device) => {
                output[device] ??= { [status]: value } as PlayerStatus;
                return output;
            },
            {} as Record<DeviceColor, PlayerStatus>
        );

        useGameBreathingExerciceStoreBase.setState((state) => ({
            ...state,
            playersStatus: {
                ...state.playersStatus,
                ...newStatus,
            },
        }));
    } else {
        useGameBreathingExerciceStoreBase.setState((state) => ({
            ...state,
            playersStatus: {
                ...state.playersStatus,
                [device]: { ...state.playersStatus[device], [status]: value },
            },
        }));
    }
}

export function setAction(action: BreathingActions) {
    useGameBreathingExerciceStoreBase.setState({ action });
}

export function resetGameBreathingExerciceStore() {
    useGameBreathingExerciceStoreBase.setState(
        structuredClone(INITIAL_BREATHINGEXERCICE_STORE)
    );
}
