import gameOneData, { default as game1Data } from '@/data/gameOneData.json';
import { getPressureStatus } from '@/services/games/game1/gameOneUtils';
import {
    handleGoToGameStatus,
    handleUpdateOverlayDescriptions,
} from '@/services/global/globalHandleFunctions';
import { emitSocketEvent, getNextGameStatus } from '@/services/global/globalUtils';
import { useGame1Store } from '@/store/games/game1Store';
import { useGameStore } from '@/store/gameStore';
import { type Tire } from '@/types/games/gameOne/types';

export function handleTirePressureChecked(value: boolean) {
    emitSocketEvent('send_update_tire_pressure_checked', { value });
}

export function handleTirePressureUpdate(tire: Tire, pressure: number) {
    emitSocketEvent('send_update_tire_pressure', { tire, pressure });
}

export function handleTirePressureValidation() {
    const vehicle = useGameStore.getState().vehicle;
    const currentTiresPressure = useGame1Store.getState().tiresPressure;

    if (!vehicle) return;

    const expectedValue = gameOneData[`${vehicle}TirePressure`];
    const pressureStatus = getPressureStatus(currentTiresPressure, expectedValue);

    handleUpdateOverlayDescriptions(
        [
            {
                id: 1,
                title: gameOneData.tirePressureInfoStatus[pressureStatus].title,
                text: gameOneData.tirePressureInfoStatus[pressureStatus].text,
                audio: gameOneData.tirePressureInfoStatus[pressureStatus].audio,
            },
        ],
        pressureStatus === 'good' ? 'success' : 'error'
    );

    emitSocketEvent('send_validate_tire_pressure', {
        status: pressureStatus,
    });
}

export function handleResetTirePressure() {
    emitSocketEvent('send_reset_tire_pressure', {});
}

export function handleSeatBeltFastening(status: boolean) {
    handleUpdateOverlayDescriptions(
        [
            {
                id: 1,
                title: status ? 'Bravo' : 'Erreur',
                text: game1Data.seatBeltFasten[status ? 'success' : 'error'],
                audio: game1Data.seatBeltFasten[
                    `audio${status ? 'Success' : 'Error'}`
                ],
            },
        ],
        status ? 'success' : 'error'
    );
    emitSocketEvent('send_update_seat_belt_status', {
        status,
    });
}

export function handleResetSeatBelt() {
    emitSocketEvent('send_reset_seat_belt_status', {});
}

export function handleSetDoNotDisturb(status: boolean) {
    emitSocketEvent('send_update_do_not_disturb', {
        status,
    });
}

export function handleReceiveCall(status: boolean, callerId?: number) {
    const currentId = callerId ?? useGame1Store.getState().callerId;
    emitSocketEvent('send_update_receive_call', {
        status,
        callerId: currentId,
    });
}

export function handleStartEngine() {
    const { isBeltClipped, isDoNotDisturbOn, tiresPressure } =
        useGame1Store.getState();

    const vehicle = useGameStore.getState().vehicle;

    const pressureStatus = getPressureStatus(
        tiresPressure,
        gameOneData[`${vehicle!}TirePressure`]
    );

    const isStartable =
        isBeltClipped && isDoNotDisturbOn && pressureStatus === 'good';
    const status = isStartable ? 'success' : 'error';

    handleUpdateOverlayDescriptions(
        [
            {
                id: 1,
                title: game1Data.engineStart[status].title,
                text: game1Data.engineStart[status].text,
                audio: game1Data.engineStart[status].audio,
            },
        ],
        status
    );

    if (isStartable) handleGoToGameStatus(getNextGameStatus());
}
