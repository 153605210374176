import { objectKeys } from '@/services/global/globalUtils';
import type { Game2Store } from '@/types/games/gameTwo/types';
import { useEffect, useState } from 'react';

const positions = {
    topLeft: [0, 0],
    topRight: [1, 0],
    bottomLeft: [0, 1],
    bottomRight: [1, 1],
} as const;

const DEFAULT_BARYCENTER_VALUE = 50;
const UP_THRESHOLD = DEFAULT_BARYCENTER_VALUE + 5;
const DOWN_THRESHOLD = DEFAULT_BARYCENTER_VALUE - 5;

export default function useTruckBarycenter(
    selectedItems: Game2Store['selectedTruckObjects']
) {
    const [barycenterX, setBarycenterX] = useState(DEFAULT_BARYCENTER_VALUE);
    const [barycenterY, setBarycenterY] = useState(DEFAULT_BARYCENTER_VALUE);

    useEffect(() => {
        let totalWeight = 0;
        let weightedX = 0;
        let weightedY = 0;

        Object.values(selectedItems).forEach((object, index) => {
            const weight = object?.weight || 0;
            const positionKeys = objectKeys(positions);
            const [x, y] = positions[positionKeys[index]];

            totalWeight += weight;
            weightedX += x * weight;
            weightedY += y * weight;
        });

        let newBarycenterX = totalWeight
            ? (weightedX / totalWeight) * 100
            : DEFAULT_BARYCENTER_VALUE;
        let newBarycenterY = totalWeight
            ? (weightedY / totalWeight) * 100
            : DEFAULT_BARYCENTER_VALUE;

        if (newBarycenterX >= DOWN_THRESHOLD && newBarycenterX <= UP_THRESHOLD) {
            newBarycenterX = DEFAULT_BARYCENTER_VALUE;
        }
        if (newBarycenterY >= DOWN_THRESHOLD && newBarycenterY <= UP_THRESHOLD) {
            newBarycenterY = DEFAULT_BARYCENTER_VALUE;
        }

        setBarycenterX(newBarycenterX);
        setBarycenterY(newBarycenterY);
    }, [selectedItems]);

    return { barycenterX, barycenterY };
}
