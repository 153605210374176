import { type DeviceColor, type Nullable } from '@/types/global/types';

export interface VehicleObject {
    id: number;
    name: string;
    image: string;
}

export interface TruckObject extends VehicleObject {
    weight: number;
    isStrapped?: boolean;
    deliveryOrderIndex: number;
}

export interface CarObject extends VehicleObject {
    carLocation?: CarLocation;
}

export const CarLocation = {
    GloveBox: 'gloveBox',
    FrontPassenger: 'frontPassenger',
    FrontMiddleStorage: 'frontMiddleStorage',
    FrontDriverDoorStorage: 'frontDriverDoorStorage',
    BackSeatLeft: 'backSeatLeft',
    BackSeatRight: 'backSeatRight',
    Trunk: 'trunk',
} as const;

export type CarLocation = (typeof CarLocation)[keyof typeof CarLocation];

export type CarLocationMap = {
    [key in CarLocation]?: Nullable<CarObject>;
};

export interface Game2Store {
    selectedTruckObjects: Record<DeviceColor, Nullable<TruckObject>>;
    selectedCarObjects: Record<DeviceColor, CarLocationMap>;
}

export const INITIAL_GAME2_STATE: Game2Store = {
    selectedTruckObjects: {
        red: null,
        green: null,
        blue: null,
        orange: null,
    },
    selectedCarObjects: {
        blue: {
            gloveBox: null,
            frontPassenger: null,
        },
        orange: {
            frontMiddleStorage: null,
            frontDriverDoorStorage: null,
        },
        green: {
            backSeatRight: null,
            backSeatLeft: null,
        },
        red: {
            trunk: null,
        },
    },
};
