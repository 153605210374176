import createSelectors from '@/store/selectors';
import {
    INITIAL_GAME2_STATE,
    type CarLocation,
    type CarObject,
    type Game2Store,
    type TruckObject,
} from '@/types/games/gameTwo/types';
import type { DeviceColor } from '@/types/global/types';
import { create } from 'zustand';

function initializeStore() {
    return structuredClone(INITIAL_GAME2_STATE);
}

const useGame2StoreBase = create<Game2Store>(initializeStore);

export const useGame2Store = createSelectors(useGame2StoreBase);

export function setSelectedTruckObject(
    color: DeviceColor,
    truckObject: TruckObject
) {
    useGame2StoreBase.setState((state) => ({
        selectedTruckObjects: {
            ...state.selectedTruckObjects,
            [color]: {
                ...truckObject,
            },
        },
    }));
}

export function setStrapStatus(color: DeviceColor, isStrapped: boolean) {
    useGame2StoreBase.setState((state) => ({
        selectedTruckObjects: {
            ...state.selectedTruckObjects,
            [color]: {
                ...state.selectedTruckObjects[color],
                isStrapped,
            },
        },
    }));
}

export function setSelectedCarObject(
    color: DeviceColor,
    location: CarLocation,
    carObject: CarObject
) {
    useGame2StoreBase.setState((state) => {
        return {
            selectedCarObjects: {
                ...state.selectedCarObjects,
                [color]: {
                    ...state.selectedCarObjects[color],
                    [location]: carObject,
                },
            },
        };
    });
}

export function resetGame2Store() {
    useGame2StoreBase.setState(structuredClone(INITIAL_GAME2_STATE));
}
