import styles from '@/features/devices/tablets/mediator/preConnectionMediatorScreen.module.scss';
import TabletStatusList, {
    type TabletStatus,
} from '@/features/devices/tablets/mediator/TabletStatusList';

interface PreConnectionMediatorScreenProps {
    deviceStatuses: TabletStatus[];
    handleConnect: () => void;
}

type TabletsState = {
    online: TabletStatus[];
    offline: TabletStatus[];
};

export default function PreConnectionMediatorScreen({
    deviceStatuses,
    handleConnect,
}: PreConnectionMediatorScreenProps) {
    const devices = deviceStatuses.reduce(
        (output, deviceStatus: TabletStatus) => {
            deviceStatus.status
                ? output.online.push(deviceStatus)
                : output.offline.push(deviceStatus);
            return output;
        },
        { online: [], offline: [] } as TabletsState
    );

    const areAllDevicesOnline = deviceStatuses.every((device) => device.status);

    return (
        <div className={styles.container}>
            <div className={styles.devicesList}>
                <h2>Tablettes en ligne</h2>
                <div>
                    <TabletStatusList list={devices.online} />
                </div>

                <h2>Tablettes hors ligne</h2>
                <div>
                    <TabletStatusList
                        list={devices.offline}
                        displayOffline={true}
                    />
                </div>
            </div>
            {areAllDevicesOnline && (
                <div className={styles.buttonContainer}>
                    <button onClick={handleConnect}>LANCER LA SESSION</button>
                </div>
            )}
        </div>
    );
}
