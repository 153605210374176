import { ClickSound } from '@/assets/sounds/soundsEffects';
import { default as data, default as dateGameFour } from '@/data/gameFourData.json';
import Button from '@/features/devices/tablets/components/buttons/Button';
import styles from '@/features/devices/tablets/components/gameFour/selectSubstance.module.scss';
import {
    handleGameFourValidation,
    handleItemsSelectedGameFour,
} from '@/services/games/game4/gameFourHandleFunctions';
import { playSound } from '@/services/global/globalUtils';
import { useGame4Store } from '@/store/games/game4Store';
import type { Game4Tablets, Substance } from '@/types/games/gameFour/types';
import { motion } from 'framer-motion';

export default function SelectSubstance({ color }: { color: Game4Tablets }) {
    const substances = dateGameFour.substances;
    const storeSubstances = useGame4Store.use.itemsSelected();
    const colorToDisplay = color === 'blue' ? 'A' : 'B';
    const selectedSubstance = storeSubstances[color];

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <p>
                    {data.texts.vehicle} {colorToDisplay}
                </p>
                <p>{data.texts.description}</p>
            </div>

            <div>
                {substances.map((substance) => {
                    const isSelected = selectedSubstance?.id === substance.id;

                    return (
                        <SubstanceCard
                            key={substance.name}
                            substance={substance}
                            isSelected={isSelected}
                            color={color}
                        />
                    );
                })}
            </div>
            <Button
                color='black'
                text='Valider ma selection'
                onClick={handleGameFourValidation}
                additionalStyle={{
                    width: '90%',
                }}
                disabled={Object.values(storeSubstances).some(
                    (substance) => substance === null
                )}
            />
        </div>
    );
}

function SubstanceCard({
    substance,
    isSelected,
    color,
}: {
    substance: Substance;
    isSelected: boolean;
    color: Game4Tablets;
}) {
    return (
        <motion.button
            className={
                styles[`${'substanceContainer'}${isSelected ? 'Selected' : ''}`]
            }
            whileTap={{
                scale: 0.9,
            }}
            onClick={() => {
                // TODO: update sound
                playSound(ClickSound);
                handleItemsSelectedGameFour(color, substance);
            }}
        >
            <div
                className={
                    styles[
                        `${'substanceIconContainer'}${substance.slug.charAt(0).toUpperCase() + substance.slug.slice(1)}`
                    ]
                }
            >
                <img src={substance.image} alt='substance icon' />
            </div>
            <p>{substance.name}</p>
        </motion.button>
    );
}
