import { handleSendIsAudioPlaying } from '@/services/global/globalHandleFunctions';
import { useRoomStore } from '@/store/roomStore';
import { useEffect } from 'react';

interface AudioProps {
    src: string;
    trackSource?: string;
    onEndedCallback?: () => void;
    isLooping?: boolean;
}

// eslint-disable-next-line react/function-component-definition
const AudioComponent: React.FC<AudioProps> = ({
    src,
    trackSource,
    isLooping = false,
    onEndedCallback,
}) => {
    const roomName = useRoomStore.use.roomName();

    const onPlay = () => {
        handleSendIsAudioPlaying(true, roomName);
    };

    const onEnd = () => {
        handleSendIsAudioPlaying(false, roomName);
        if (onEndedCallback) {
            onEndedCallback();
        }
    };

    useEffect(() => {
        return () => {
            handleSendIsAudioPlaying(false, roomName);
        };
    }, [roomName]);

    return (
        <audio
            loop={isLooping}
            autoPlay
            src={src}
            onEnded={onEnd}
            onPlaying={onPlay}
        >
            <track kind='captions' srcLang='fr' src={trackSource || ''} />
        </audio>
    );
};

export default AudioComponent;
