import {
    Home,
    PinBlue,
    SeatBeltIcon,
    TirePressureIcon,
    TirePressureIconBlue,
} from '@/assets/images/icons';
import dataGameOne from '@/data/gameOneData.json';
import data from '@/data/global.json';
import { DeactivatedTab } from '@/features/components/cockpit/components/DeactivatedTab';
import TvTiresPressure from '@/features/components/gameOne/tirePressure/TvTiresPressure';
import Map from '@/features/components/gameThree/map/Map';
import { Toast } from '@/features/components/toast/Toast';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { getPressureStatus } from '@/services/games/game1/gameOneUtils';
import { handleCockpitAction } from '@/services/global/globalHandleFunctions';
import { useGame1Store } from '@/store/games/game1Store';
import { useGame3Store } from '@/store/games/game3Store';
import { useGameStore } from '@/store/gameStore';
import { type CockpitAction } from '@/types/games/types';
import type { DeviceColorWithTV } from '@/types/global/types';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import styles from './cockpit.module.scss';

interface CockpitProps {
    device: DeviceColorWithTV;
}

const STATUS_TO_ICON: Record<CockpitAction, string> = {
    GAME: '',
    SETTINGS: '',
    INFO: '',
    COCKPIT: Home,
    TIRE: TirePressureIconBlue,
    MAP: PinBlue,
};

export default function Cockpit({ device }: CockpitProps) {
    const gameStatus = useGameStore.use.gameStatus();
    const vehicle = useGameStore.use.vehicle();
    const tirePressures = useGame1Store.use.tiresPressure();
    const { overlayType } = useCurrentOverlay();
    const { cockpitStatus } = useGameStore();
    const isSeatBeltFasten = useGame1Store.use.isBeltClipped();
    const showDirections = useGame3Store.use.showDirections();
    const isGame3 = gameStatus === 'GAME3ACTION';
    const pressureStatus = getPressureStatus(
        tirePressures,
        dataGameOne[`${vehicle!}TirePressure`]
    );

    const isTirePressureValid = pressureStatus === 'good';

    useEffect(() => {
        if (isTirePressureValid && overlayType === 'success') {
            handleCockpitAction('COCKPIT');
        }
    }, [overlayType, isTirePressureValid]);

    const renderCockpitContent = () => {
        switch (cockpitStatus) {
            case 'TIRE':
                return isGame3 ? (
                    <DeactivatedTab alreadydone />
                ) : (
                    <TvTiresPressure />
                );
            case 'MAP':
                return gameStatus === 'GAME1ACTION' ? (
                    <DeactivatedTab />
                ) : (
                    <Map device={device} />
                );
            default:
                return (
                    <div className={styles.cokpitContentContainer}>
                        <Toast
                            text={
                                isGame3
                                    ? data.cockpit.welcome
                                    : data.cockpit.welcomeAndCheck
                            }
                        />
                        {isGame3 ? null : (
                            <>
                                {isSeatBeltFasten &&
                                pressureStatus === 'good' ? null : (
                                    <div className={styles.informations}>
                                        {pressureStatus !== 'good' ? (
                                            <VehicleInformation
                                                icon={TirePressureIcon}
                                                title={
                                                    data.cockpit.checkTirePressure
                                                }
                                            />
                                        ) : null}
                                        {!isSeatBeltFasten ? (
                                            <VehicleInformation
                                                icon={SeatBeltIcon}
                                                title={data.cockpit.checkSeatBelt}
                                            />
                                        ) : null}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                );
        }
    };
    return (
        <div className={styles.cokpitContainer}>
            {!showDirections && <ActionBar />}
            {renderCockpitContent()}
        </div>
    );
}

function VehicleInformation({ icon, title }: { icon: string; title: string }) {
    return (
        <div>
            <img src={icon} alt="Icon d'information du vehicule" />
            <p>{title}</p>
            <div />
        </div>
    );
}

function ActionBar() {
    return (
        <div className={styles.action_bar}>
            <ActionBarButton buttonName='COCKPIT' />
            <ActionBarButton buttonName='TIRE' />
            <ActionBarButton buttonName='MAP' />
        </div>
    );
}

function ActionBarButton({
    buttonName,
    disable,
}: {
    buttonName: CockpitAction;
    disable?: boolean;
}) {
    const { cockpitStatus } = useGameStore();

    return (
        <motion.button
            type='button'
            whileTap={{ scale: 0.9 }}
            onClick={() => handleCockpitAction(buttonName)}
            disabled={disable}
        >
            <div
                className={
                    cockpitStatus === buttonName ? styles.selected : undefined
                }
            >
                <div>
                    <img src={STATUS_TO_ICON[buttonName]} alt='maison' width={50} />
                </div>
            </div>
        </motion.button>
    );
}
