import data from '@/data/gameSixData.json';
import mediatorData from '@/features/devices/tablets/mediator/gamesComponents/gameComponentsTexts.json';
import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import {
    handleGameSixValidation,
    handleResetGameSixReponses,
    handleSetCurrentSituationIndex,
    handleTimerStatus,
} from '@/services/games/game6/game6HandleFunctions';
import {
    getCorrectAnswersIdsBySituationId,
    setResponsesForAllColors,
} from '@/services/games/game6/game6Utils';
import { useGame6Store } from '@/store/games/game6Store';
import { useOverlayStore } from '@/store/overlayStore';

export default function GameSixMediator() {
    const currentSituationIndex = useGame6Store.use.currentSituationIndex();
    const overlayType = useOverlayStore.use.overlayType();
    const isOverlayNeutral = overlayType === 'neutral';

    const correctAnswersIds =
        getCorrectAnswersIdsBySituationId(currentSituationIndex);

    return (
        <div>
            {/* Go to next situation */}
            <MediatorAction
                title={mediatorData.global.goToNextSituation}
                displayingCondition={
                    isOverlayNeutral &&
                    currentSituationIndex < data.situations.length - 1
                }
                handleFunction={() => {
                    handleTimerStatus(false);
                    handleSetCurrentSituationIndex(currentSituationIndex + 1);
                }}
                type='secondary'
            />

            {/* Go to previous situation */}
            <MediatorAction
                title={mediatorData.global.goToPreviousSituation}
                displayingCondition={isOverlayNeutral && currentSituationIndex > 0}
                handleFunction={() => {
                    handleTimerStatus(false);
                    handleSetCurrentSituationIndex(currentSituationIndex - 1);
                }}
                type='secondary'
            />

            {/* Select the correct answers */}
            <MediatorAction
                title={mediatorData.global.selectGoodAnswers}
                displayingCondition={isOverlayNeutral}
                handleFunction={() => {
                    handleResetGameSixReponses();
                    correctAnswersIds.forEach((responseId) => {
                        setResponsesForAllColors(responseId);
                    });
                }}
                type='secondary'
            />

            {/* Validate the current answer */}
            <MediatorAction
                title={mediatorData.global.validateCurrentAnswes}
                displayingCondition={isOverlayNeutral}
                handleFunction={() => handleGameSixValidation(false)}
                type='tertiary'
            />
        </div>
    );
}
