import { LogoAstraWhite } from '@/assets/images/global';
import styles from '@/features/components/toast/toast.module.scss';
import type { CSSProperties } from 'react';

export function Toast({ text, style }: { text: string; style?: CSSProperties }) {
    return (
        <div className={styles.toast} style={style}>
            <img src={LogoAstraWhite} alt='Astra logo' width={120} height={120} />
            <p>{text}</p>
        </div>
    );
}
