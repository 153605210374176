import Cockpit from '@/features/components/cockpit/Cockpit';
import styles from '@/features/components/gameOne/gameOne.module.scss';
import CallEntry from '@/features/devices/tablets/orange/components/phone/callEntry/CallEntry';
import { useGame1Store } from '@/store/games/game1Store';

export default function GameOne() {
    const isBeingCalled = useGame1Store.use.isBeingCalled();

    return (
        <div className={styles.gameOneContainer}>
            {isBeingCalled ? <CallEntry fromTv /> : <Cockpit device='tv' />}
        </div>
    );
}
