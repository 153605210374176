import AudioComponent from '@/features/components/AudioComponent';
import styles from '@/features/components/gameSix/gameSix.module.scss';
import Timer from '@/features/components/timer/Timer';
import {
    handleGameSixValidation,
    handleTimerStatus,
} from '@/services/games/game6/game6HandleFunctions';
import {
    getResponsesBySituationId,
    getSituationByIndex,
} from '@/services/games/game6/game6Utils';
import { useGame6Store } from '@/store/games/game6Store';
import { useOverlayStore } from '@/store/overlayStore';
import type { Game6Response } from '@/types/games/gameSix/types';
import type { DeviceColor } from '@/types/global/types';

const TIMER_THRESHOLD = 60;

export default function GameSix() {
    const currentSituationIndex = useGame6Store.use.currentSituationIndex();
    const currentSituation = getSituationByIndex(currentSituationIndex);
    const possibleResponses = getResponsesBySituationId(currentSituationIndex);
    const timerStatus = useGame6Store.use.startTimer();
    const overlayType = useOverlayStore.use.overlayType();

    return (
        <div className={styles.container}>
            {overlayType === 'neutral' && (
                <AudioComponent src={currentSituation.audio} />
            )}
            <div>
                <p>Situation {currentSituationIndex + 1}</p>
                <p>{currentSituation.title}</p>
            </div>
            {timerStatus && (
                <Timer
                    isStarted={timerStatus}
                    mode='countdown'
                    threshold={TIMER_THRESHOLD}
                    onAlert={() => {
                        handleGameSixValidation(true);
                        handleTimerStatus(false);
                    }}
                    size={200}
                    additionalStyles={{
                        alignSelf: 'center',
                    }}
                />
            )}
            <p>Réponses possibles</p>
            <div className={styles.responseCardContainerWrapper}>
                <div>
                    {possibleResponses.map((response) => {
                        return (
                            <ResponseCard key={response.id} response={response} />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

function ResponseCard({ response }: { response: Game6Response }) {
    const currentPlayersAnswers = useGame6Store.use.responses();

    return (
        <div className={styles.responseCard}>
            <div className={styles.circlesContainer}>
                {Object.entries(currentPlayersAnswers).map(
                    ([color, currentResponse]) => {
                        const isSelected = currentResponse.includes(response.id);
                        return (
                            <FeedbackCircle
                                color={color as DeviceColor}
                                key={color}
                                isFilled={isSelected}
                            />
                        );
                    }
                )}
            </div>
            <div className={styles.emojiContainer}>
                {response.emojis.map((emoji) => (
                    <img key={emoji} src={emoji} alt='Emoji' />
                ))}
            </div>
            <div>
                <p className={styles.responseCardTitle}>{response.title}</p>
                {response.subtitle && (
                    <p className={styles.responseCardSubtitle}>
                        {response.subtitle}
                    </p>
                )}
            </div>
        </div>
    );
}

function FeedbackCircle({
    color,
    isFilled,
}: {
    color: DeviceColor;
    isFilled?: boolean;
}) {
    return (
        <div
            className={`${styles[`feedbackCircle_${color}`]} ${isFilled && styles[`feedbackCircle_${color}_filled`]}`}
        />
    );
}
