import { useEffect, useState } from 'react';

interface Cyclehandlers {
    cyclesLeft: number;
    areCyclesCompleted: boolean;
    nextStep: () => void;
}

/**
 *
 * @param stepsByCycle : number. Number of steps to complete one cycle.
 * @param num : number. The number of cycles to complete
 * @returns CycleHandlers: {cyclesLeft: number, areCyclesCompleted: boolean, nextStep: () => void}
 */
export function useCycles(stepsByCycle: number, num: number): Cyclehandlers {
    const [cyclesLeft, setCyclesLeft] = useState(num);
    const [areCyclesCompleted, setAreCycleCompleted] = useState(false);
    const stepValue = 1 / stepsByCycle;

    useEffect(() => {
        setAreCycleCompleted(cyclesLeft === 0);
    }, [cyclesLeft]);

    const nextStep = () => {
        setCyclesLeft((state) => state - stepValue);
    };

    return {
        cyclesLeft,
        areCyclesCompleted,
        nextStep,
    };
}
