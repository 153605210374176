import data from '@/data/gameFourData.json';
import {
    handleGoToGameStatus,
    handleUpdateOverlayDescriptions,
} from '@/services/global/globalHandleFunctions';
import { emitSocketEvent, getNextGameStatus } from '@/services/global/globalUtils';
import { useGame4Store } from '@/store/games/game4Store';
import { setOverlayCallback } from '@/store/overlayStore';
import type {
    Game4Tablets,
    ItemStatus,
    Substance,
} from '@/types/games/gameFour/types';
import type { Nullable } from '@/types/global/types';

export function handleItemsSelectedGameFour(
    color: Game4Tablets,
    substance: Substance
): void {
    emitSocketEvent('send_items_selected_game_four', {
        color,
        substance,
    });
    handleItemStatusGameFour(color, null);
}

export function handleItemStatusGameFour(
    color: Game4Tablets,
    status: Nullable<ItemStatus>
) {
    emitSocketEvent('send_items_status_game_four', {
        color,
        itemStatus: status,
    });
}

export function handleGameFourValidation() {
    const itemsSelectected = useGame4Store.getState().itemsSelected;
    const correctAnswers = data.correctAnswers;

    const isBlueItemCorrect = itemsSelectected.blue?.id === correctAnswers.blue;
    const isOrangeItemCorrect =
        itemsSelectected.orange?.id === correctAnswers.orange;

    handleItemStatusGameFour('blue', isBlueItemCorrect ? 'success' : 'error');
    handleItemStatusGameFour('orange', isOrangeItemCorrect ? 'success' : 'error');

    const status = isBlueItemCorrect && isOrangeItemCorrect ? 'success' : 'error';

    handleUpdateOverlayDescriptions(
        [
            {
                id: 1,
                audio: data.resultMessages[status].audio,
                text: data.resultMessages[status].text,
                title: data.resultMessages[status].title,
            },
        ],
        status === 'error' ? 'error' : 'success'
    );

    if (status === 'success') {
        setOverlayCallback(() => {
            handleGoToGameStatus(getNextGameStatus());
        });
    }
}

export function handleSelectCorrectAnswers() {
    Object.entries(data.correctAnswers).map(([color, id]) => {
        const substance = data.substances.find(
            (substance) => substance.id === id
        ) as Substance;

        handleItemsSelectedGameFour(color as Game4Tablets, substance);
    });
}
