import { LogoAstra } from '@/assets/images/global';
import globalData from '@/data/global.json';
import ProgressBar from '@/features/devices/tablets/components/progressBar/ProgressBar';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { handleGoToGameStatus } from '@/services/global/globalHandleFunctions';
import { getNextGameStatus } from '@/services/global/globalUtils';
import { type DeviceColorWithTV } from '@/types/global/types';
import { useEffect, useState } from 'react';
import styles from './astraLoading.module.scss';

interface AstraLoadingProps {
    device: DeviceColorWithTV;
    preventDefault?: boolean;
}

export default function AstraLoading({
    device,
    preventDefault,
}: AstraLoadingProps) {
    const [progress, setProgress] = useState(0);
    const isTV = device === 'tv';

    const { currentTitle: title, currentText: text } = useCurrentOverlay();

    useEffect(() => {
        let timeoutId = null;
        if (progress < 100) {
            timeoutId = setTimeout(() => {
                setProgress((previous) => previous + 20);
            }, 500);
        }

        if (!preventDefault && progress === 100) {
            handleGoToGameStatus(getNextGameStatus());
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [progress, preventDefault]);

    return (
        <div
            className={`${styles.container} ${!isTV ? styles.justify_center : null}`}
        >
            {isTV && (
                <div className={styles.text}>
                    <h3>{title}</h3>
                    <h1>{globalData.global.pleaseWait.toUpperCase()}</h1>
                    <h5>{text}...</h5>
                </div>
            )}
            <div className={styles.progressbar}>
                <ProgressBar
                    title={`${progress} %`}
                    numberOfItemCompleted={progress}
                />
            </div>
            {isTV && <img src={LogoAstra} alt='astra' width={100} />}
        </div>
    );
}
