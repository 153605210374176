import { INITIAL_BREATHINGEXERCICE_STORE } from '@/types/games/gameBreathingExercice/types';
import { type Game5Store } from '@/types/games/gameFive/types';
import { type Game4Store } from '@/types/games/gameFour/types';
import { type Game1Store } from '@/types/games/gameOne/types';
import type { Game6Store } from '@/types/games/gameSix/types';
import type { Game3Store } from '@/types/games/gameThree/types';
import { INITIAL_GAME2_STATE } from '@/types/games/gameTwo/types';
import type { DeviceStore, GameStore, OverlayStore } from '@/types/games/types';
import {
    type DeviceColorWithTvAndMediator,
    type Nullable,
} from '@/types/global/types';
import type { RoomInfo, SessionInfo } from '@/types/sessionInfo/types';

const MAX_OF_DEVICES_IN_A_ROOM = 6;

export function storeSocketInfo(
    session: SessionInfo,
    data: { roomName: string; device: DeviceColorWithTvAndMediator },
    socketId: string
): SessionInfo {
    session[data.roomName].sockets.set(data.device, socketId);

    return session;
}

function getUnfilledRooms(
    connectedSockets: Array<{ id: string; room: string }>,
    storedRoomNames: Array<string>
) {
    const socketsIdsByRooms = connectedSockets.reduce(
        (acc, { id, room }) => {
            acc[room] = acc[room] ?? [];
            acc[room].push(id);
            return acc;
        },
        {} as Record<string, Array<string>>
    );

    //Add all the the empty room
    for (const roomName of storedRoomNames) {
        if (!socketsIdsByRooms[roomName]) {
            socketsIdsByRooms[roomName] = [];
        }
    }

    return Object.entries(socketsIdsByRooms).filter(([, ids]) => {
        return ids.length < MAX_OF_DEVICES_IN_A_ROOM;
    });
}

export function getDisconnectedSocketRoomsWithColor(
    session: SessionInfo,
    connectedSockets: Array<{ id: string; room: string }>
): Nullable<[string, DeviceColorWithTvAndMediator]> {
    const roomsWithMissingSockets = getUnfilledRooms(
        connectedSockets,
        Object.keys(session)
    );

    let missingSocketRoomsWithColor: Nullable<
        [string, DeviceColorWithTvAndMediator]
    > = null;

    for (const [room, ids] of roomsWithMissingSockets) {
        let disconnectedSocketColor = null;
        session[room].sockets.forEach((socketId, color) => {
            if (socketId != null && !ids.includes(socketId)) {
                disconnectedSocketColor = color;
            }
        });

        if (!disconnectedSocketColor) continue;

        missingSocketRoomsWithColor = [room, disconnectedSocketColor];
    }

    return missingSocketRoomsWithColor;
}

export function createRoom(roomName: string): SessionInfo {
    return {
        [roomName]: { sockets: new Map(), ...initRoom() },
    };
}

export function deleteEmptyRoom(sessionInfo: SessionInfo) {
    Object.entries(sessionInfo).forEach(([roomName, { sockets }]) => {
        const emptyRoom =
            Array.from(sockets).filter(([, status]) => status !== null).length ===
            0;

        if (emptyRoom) delete sessionInfo[roomName];
    });
}

export function initRoom(): Omit<RoomInfo, 'sockets'> {
    return structuredClone({
        deviceStore: INITIAL_DEVICE_STORE,
        overlayStore: INITAL_OVERLAY_STORE,
        gameStore: INITIAL_GAME_STORE,
        game1Store: INITIAL_GAME1_STORE,
        game2Store: INITIAL_GAME2_STATE,
        game3Store: INITIAL_GAME3_STORE,
        gameBreathingExercice: INITIAL_BREATHINGEXERCICE_STORE,
        game4Store: INITIAL_GAME4_STORE,
        game5Store: INITIAL_GAME5_STORE,
        game6Store: INITIAL_GAME6_STORE,
    });
}

export const INITIAL_DEVICE_STORE: DeviceStore = {
    deviceStatus: {
        red: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette rouge',
        },
        blue: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette bleue',
        },
        green: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette verte',
        },
        orange: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette orange',
        },
        tv: {
            isConnected: false,
            isActivated: true,
            label: 'TV',
        },
        mediator: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette Médiateur',
        },
    },
};

export const INITAL_OVERLAY_STORE: OverlayStore = {
    currentOverlayIndex: 0,
    overlayDescriptions: [],
    overlayType: 'neutral',
    overlayCallback: null,
};

export const INITIAL_GAME_STORE: GameStore = {
    roomName: '',
    gameStatus: 'WELCOME_SCREEN',
    mode: null,
    isAudioPlaying: false,
    vehicle: null,
    cockpitStatus: 'COCKPIT',
};

export const INITIAL_GAME1_STORE: Game1Store = {
    tiresPressure: { LeftFront: 1, LeftRear: 1, RightFront: 1, RightRear: 1 },
    tirePressureInfoStatus: 'under',
    isBeltClipped: false,
    isDoNotDisturbOn: false,
    isBeingCalled: false,
    callerId: 0,
    unlockState: false,
};

export const INITIAL_GAME3_STORE: Game3Store = {
    search: null,
    showDirections: false,
    selectedItinerary: null,
    swipeAction: null,
    currentSceneIndex: 0,
    currentGroupIndex: 0,
    audioPlayed: false,
};

export const INITIAL_GAME4_STORE: Game4Store = {
    itemsSelected: {
        blue: null,
        orange: null,
    },
    itemStatuses: {
        blue: null,
        orange: null,
    },
};

export const INITIAL_GAME5_STORE: Game5Store = {
    currentSituationIndex: 0,
    playersAnswer: null,
};

export const INITIAL_GAME6_STORE: Game6Store = {
    currentSituationIndex: 0,
    responses: {
        blue: [],
        orange: [],
        green: [],
        red: [],
    },
    startTimer: false,
};
