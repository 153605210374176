import game3Data from '@/data/gameThreeData.json';
import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import {
    handleContinueToScenes,
    handleNextOrPreviousSceneIndex,
    handleSearch,
    handleSelectedItinerary,
    handleShowDirections,
    handleSwipeAction,
} from '@/services/games/game3/gameThreeHandleFunctions';
import {
    MAP_SWIPE_ACTION_ENGLISH_TO_FRENCH,
    shouldShowDestination,
} from '@/services/games/game3/gameThreeUtils';
import {
    handleCockpitAction,
    handleGoToGameStatus,
} from '@/services/global/globalHandleFunctions';
import { useGame3Store } from '@/store/games/game3Store';
import { useGameStore } from '@/store/gameStore';
import type { Itinerary, SwipeAction } from '@/types/games/gameThree/types';
import { GAMESTATUS, type Nullable } from '@/types/global/types';

export default function GameThreeMediator() {
    const currentSearch = useGame3Store.use.search();
    const showDirections = useGame3Store.use.showDirections();
    const cockpitStatus = useGameStore.use.cockpitStatus();
    const currentSceneIndex = useGame3Store.use.currentSceneIndex();
    const currentGroupIndex = useGame3Store.use.currentGroupIndex();
    const currentGroup =
        game3Data.scenes[currentSceneIndex].groups[currentGroupIndex];
    const itinerary = game3Data.itineraries[0] as Itinerary;

    const { overlayType } = useCurrentOverlay();
    const showSceneActions = overlayType === 'neutral';

    return (
        <div>
            <MediatorAction
                title="Aller sur l'ecran de GPS"
                handleFunction={() => {
                    handleCockpitAction('MAP');
                }}
                displayingCondition={
                    cockpitStatus !== 'MAP' &&
                    cockpitStatus !== 'INFO' &&
                    cockpitStatus !== 'GAME'
                }
                type='secondary'
            />

            <MediatorAction
                title='Saisir la bonne adresse'
                handleFunction={() => {
                    handleSearch(game3Data.destination);
                }}
                displayingCondition={
                    !shouldShowDestination(currentSearch) && cockpitStatus === 'MAP'
                }
                type='secondary'
            />

            <MediatorAction
                title="Valider l'addresse"
                handleFunction={() => {
                    handleShowDirections(true);
                }}
                displayingCondition={
                    shouldShowDestination(currentSearch) && !showDirections
                }
                type='secondary'
            />

            <MediatorAction
                title="Choisir l'itineraire"
                handleFunction={() => {
                    handleSelectedItinerary(itinerary);
                    handleCockpitAction('INFO');
                }}
                displayingCondition={
                    showDirections &&
                    cockpitStatus !== 'INFO' &&
                    cockpitStatus !== 'GAME'
                }
                type='secondary'
            />

            <MediatorAction
                title='Passer le message'
                handleFunction={handleContinueToScenes}
                displayingCondition={showDirections && cockpitStatus === 'INFO'}
                type='tertiary'
            />

            <MediatorAction
                title={'Passer les scènes'}
                handleFunction={() =>
                    handleGoToGameStatus(GAMESTATUS.BREATHING_INTRO)
                }
                displayingCondition={cockpitStatus === 'GAME' && showSceneActions}
                type='secondary'
            />

            <MediatorAction
                title='Scene precedente'
                handleFunction={() => handleNextOrPreviousSceneIndex('previous')}
                displayingCondition={currentSceneIndex > 0 && showSceneActions}
                type='secondary'
            />

            <MediatorAction
                title={
                    currentSceneIndex < game3Data.scenes.length - 1
                        ? 'Scene suivante'
                        : 'Derniere scene'
                }
                handleFunction={() => handleNextOrPreviousSceneIndex('next')}
                displayingCondition={cockpitStatus === 'GAME' && showSceneActions}
                type='secondary'
            />

            <MediatorAction
                title={`Action: ${MAP_SWIPE_ACTION_ENGLISH_TO_FRENCH[currentGroup.actions[0] as SwipeAction]}`}
                handleFunction={() =>
                    handleSwipeAction(
                        currentGroup.actions[0] as Nullable<SwipeAction>
                    )
                }
                displayingCondition={cockpitStatus === 'GAME' && showSceneActions}
                type='tertiary'
            />

            <MediatorAction
                title={`Action: ${MAP_SWIPE_ACTION_ENGLISH_TO_FRENCH[currentGroup.actions[1] as SwipeAction]}`}
                handleFunction={() =>
                    handleSwipeAction(
                        currentGroup.actions[1] as Nullable<SwipeAction>
                    )
                }
                displayingCondition={cockpitStatus === 'GAME' && showSceneActions}
                type='tertiary'
            />
        </div>
    );
}
