import { CarTopView, TruckTopView } from '@/assets/images/global';
import gameOneData from '@/data/gameOneData.json';
import styles from '@/features/components/gameOne/tirePressure/tirePressure.module.scss';
import TvTire from '@/features/components/gameOne/tirePressure/TvTire';
import { Toast } from '@/features/components/toast/Toast';
import { INITIAL_GAME1_STORE } from '@/sessionInfo';
import { useGame1Store } from '@/store/games/game1Store';
import { useGameStore } from '@/store/gameStore';

export default function TvTiresPressure() {
    const tiresPressure = useGame1Store.use.tiresPressure();
    const vehicule = useGameStore.use.vehicle();

    const isTiresPressureUpdated = Object.values(tiresPressure).some(
        (pressure) => pressure !== INITIAL_GAME1_STORE.tiresPressure.LeftFront
    );

    return (
        <div className={styles.container}>
            {!isTiresPressureUpdated && (
                <Toast
                    text={gameOneData.tvAdjustPressure}
                    style={{
                        marginBottom: '100px',
                    }}
                />
            )}
            <div className={styles.tv_tire_pressure}>
                <div className={styles.side}>
                    <div className={`${styles.tire} ${styles.left_front}`}>
                        <TvTire pressure={tiresPressure.LeftFront} side='left' />
                    </div>
                    <div className={`${styles.tire} ${styles.left_rear}`}>
                        <TvTire pressure={tiresPressure.LeftRear} side='left' />
                    </div>
                </div>
                <div className={styles[`${vehicule}Container`]}>
                    <img
                        src={vehicule === 'car' ? CarTopView : TruckTopView}
                        alt='vehicule'
                        height={600}
                    />
                </div>
                <div className={styles.side}>
                    <div className={`${styles.tire} ${styles.right_front}`}>
                        <TvTire pressure={tiresPressure.RightFront} side='right' />
                    </div>
                    <div className={`${styles.tire} ${styles.right_rear}`}>
                        <TvTire pressure={tiresPressure.RightRear} side='right' />
                    </div>
                </div>
            </div>
        </div>
    );
}
