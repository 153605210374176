import { PapangImage, SalaziaImage } from '@/assets/images/global';
import globalData from '@/data/global.json';
import Button from '@/features/devices/tablets/components/buttons/Button';
import {
    handleGoToGameStatus,
    handleVehicleSelection,
} from '@/services/global/globalHandleFunctions';
import { useGameStore } from '@/store/gameStore';
import type { Vehicle } from '@/types/games/types';
import { GAMESTATUS } from '@/types/global/types';
import styles from './selectVehicle.module.scss';

export default function SelectVehicle({ type }: { type: Vehicle }) {
    const vehicle = useGameStore.use.vehicle();

    return (
        <div className={styles.container}>
            <img
                src={type === 'car' ? SalaziaImage : PapangImage}
                alt={type === 'car' ? 'Voiture' : 'Camion'}
                width={1024}
            />
            <div className={styles.buttonContainer}>
                {(!vehicle || vehicle !== type) && (
                    <Button
                        text={globalData.tablet.choseVehicle}
                        color='black'
                        onClick={() => handleVehicleSelection(type)}
                        additionalStyle={{
                            width: '80%',
                        }}
                    />
                )}
                {vehicle === type && (
                    <Button
                        text={globalData.global.validate}
                        color='blue'
                        onClick={() =>
                            handleGoToGameStatus(GAMESTATUS.GAME1INTRODUCTION)
                        }
                        disabled={vehicle !== type}
                        additionalStyle={{
                            width: '80%',
                        }}
                    />
                )}
            </div>
        </div>
    );
}
