import { INITIAL_GAME6_STORE } from '@/sessionInfo';
import createSelectors from '@/store/selectors';
import type { Game6Store } from '@/types/games/gameSix/types';
import type { DeviceColor } from '@/types/global/types';
import { create } from 'zustand';

const useGame6StoreBase = create<Game6Store>(() =>
    structuredClone(INITIAL_GAME6_STORE)
);

export const useGame6Store = createSelectors(useGame6StoreBase);

export function setCurrentSituationIndex(currentSituationIndex: number) {
    useGame6StoreBase.setState({
        currentSituationIndex,
    });
}

export function setResponses(color: DeviceColor, responseId: number) {
    useGame6StoreBase.setState((state) => {
        const selectedItemsId = state.responses[color];

        const itemIndex = selectedItemsId.findIndex(
            (itemId) => itemId === responseId
        );

        if (itemIndex > -1) {
            selectedItemsId.splice(itemIndex, 1);
        } else {
            selectedItemsId.push(responseId);
        }

        return {
            ...state,
            responses: {
                ...state.responses,
                [color]: selectedItemsId,
            },
        };
    });
}

export function setTimerStatus(status: boolean) {
    useGame6StoreBase.setState({
        startTimer: status,
    });
}

export function resetResponses() {
    useGame6StoreBase.setState({
        responses: structuredClone(INITIAL_GAME6_STORE.responses),
    });
}

export function resetGame6Store() {
    useGame6StoreBase.setState(structuredClone(INITIAL_GAME6_STORE));
}
