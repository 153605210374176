import { MainScreen } from '@/assets/images/global';
import { Ambiance } from '@/assets/sounds/soundsEffects';
import AstraLoading from '@/features/components/astraLoading/AstraLoading';
import ClaimBadgeScreen from '@/features/components/badge/ClaimBadgeScreen';
import Breathing from '@/features/components/breathingExercice/breathingInfoScreen/Breathing';
import GameFive from '@/features/components/gameFive/GameFive';
import GameFour from '@/features/components/gameFour/GameFour';
import GameOne from '@/features/components/gameOne/GameOne';
import GameSix from '@/features/components/gameSix/GameSix';
import GameThree from '@/features/components/gameThree/GameThree';
import GameTwoCar from '@/features/components/gameTwo/GameTwoCar';
import GameTwoTruck from '@/features/components/gameTwo/GameTwoTruck';
import CarouselConversation from '@/features/components/modal/carouselConversation/CarouselConversation';
import EndScreen from '@/features/devices/tv/components/endScreen/EndScreen';
import Introduction from '@/features/devices/tv/components/introduction/Introduction';
import Prevention from '@/features/devices/tv/components/prevention/Prevention';
import TvLayout from '@/features/devices/tv/components/TvLayout';
import VehicleSelection from '@/features/devices/tv/components/vehicleSelection/VehicleSelection';
import { useDynamicTabletName } from '@/hooks/useDynamicTabletName';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { useGameStore } from '@/store/gameStore';
import { GAMESTATUS } from '@/types/global/types';
import { useEffect, useRef } from 'react';

export default function Tv() {
    const gameStatus = useGameStore.use.gameStatus();
    const vehicle = useGameStore.use.vehicle();

    useReSynchronizeDevice('tv');

    useDynamicTabletName('tv', 'TV');

    const ambianceSoundRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        if (!ambianceSoundRef.current) {
            const ambianceSound = new Audio(Ambiance);
            ambianceSound.loop = true;
            ambianceSound.volume = 0.2;
            ambianceSound.play();
            ambianceSoundRef.current = ambianceSound;
        }

        return () => {
            if (ambianceSoundRef.current) {
                ambianceSoundRef.current.pause();
                ambianceSoundRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (gameStatus === GAMESTATUS.WELCOME_SCREEN && ambianceSoundRef.current) {
            ambianceSoundRef.current.pause();
        } else if (ambianceSoundRef.current && ambianceSoundRef.current.paused) {
            ambianceSoundRef.current.play();
        }
    }, [gameStatus]);

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.WELCOME_SCREEN:
                return (
                    <video
                        autoPlay
                        muted
                        loop
                        id='myVideo'
                        style={{
                            position: 'fixed',
                            right: 0,
                            bottom: 0,
                            minWidth: '100%',
                            minHeight: '100%',
                        }}
                        controls={false}
                        // should cover the full screen
                        playsInline
                    >
                        <source src={MainScreen} type='video/mp4' />
                    </video>
                );
            case GAMESTATUS.VEHICLE_SELECTION:
                return <VehicleSelection />;

            case GAMESTATUS.GAME1INTRODUCTION_LOADING:
            case GAMESTATUS.GAME2INTRODUCTION_LOADING:
            case GAMESTATUS.GAME3INTRODUCTION_LOADING:
            case GAMESTATUS.GAME4INTRODUCTION_LOADING:
            case GAMESTATUS.GAME5INTRODUCTION_LOADING:
            case GAMESTATUS.GAME6INTRODUCTION_LOADING:
                return <AstraLoading device='tv' />;

            case GAMESTATUS.GAME1INTRODUCTION:
                return <CarouselConversation />;
            case GAMESTATUS.GAME2INTRODUCTION:
            case GAMESTATUS.GAME3INTRODUCTION:
            case GAMESTATUS.GAME4INTRODUCTION:
            case GAMESTATUS.GAME5INTRODUCTION:
            case GAMESTATUS.GAME6INTRODUCTION:
                return <Introduction />;

            case GAMESTATUS.GAME1ACTION:
                return <GameOne />;

            case GAMESTATUS.GAME3ACTION:
                return <GameThree />;

            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.GAME2PREVENTION:
            case GAMESTATUS.GAME3PREVENTION:
            case GAMESTATUS.GAME4PREVENTION:
            case GAMESTATUS.GAME5PREVENTION:
            case GAMESTATUS.GAME6PREVENTION:
                return <Prevention />;

            case GAMESTATUS.GAME2ACTION:
                return vehicle === 'car' ? <GameTwoCar /> : <GameTwoTruck />;

            case GAMESTATUS.BREATHING_INTRO:
                return <Breathing isIntro />;

            case GAMESTATUS.BREATHING_ACTION:
                return <Breathing isPlaying />;

            case GAMESTATUS.BREATHING_END:
                return <Breathing />;

            case GAMESTATUS.GAME4ACTION:
                return <GameFour />;

            case GAMESTATUS.GAME5ACTION:
                return <GameFive />;

            case GAMESTATUS.GAME6ACTION:
                return <GameSix />;

            case GAMESTATUS.GAME1UNLOCK:
            case GAMESTATUS.GAME2UNLOCK:
            case GAMESTATUS.GAME3UNLOCK:
            case GAMESTATUS.GAME4UNLOCK:
            case GAMESTATUS.GAME5UNLOCK:
            case GAMESTATUS.GAME6UNLOCK:
                return <ClaimBadgeScreen />;

            case GAMESTATUS.FINISHED:
                return <EndScreen />;
        }
    };

    return <TvLayout>{handleRender()}</TvLayout>;
}
