import { SeatBeltClick } from '@/assets/sounds/soundsEffects';
import { handleSeatBeltFastening } from '@/services/games/game1/gameOneHandleFunctions';
import { playSound } from '@/services/global/globalUtils';
import SocketService from '@/services/SocketServices';
import { useGame1Store } from '@/store/games/game1Store';
import gsap from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { useCallback, useEffect } from 'react';

const COVERING_TRESHOLD = '50%';
const TONGUE_ORIGIN_OFFSET = 45;
const WIDTH_OFFSET = 39;
const HEIGHT_OFFSET = 43;
const RADIUS = 50;

/**
 * For this hook to work, you need to set the id property of three HTML elements: `drag_container`, `tongue` and `latche`.
 * The drag container represent the boundaries in which the tongue can be dragged. The latche also needs to be inside of it.
 * The tongue represent the draggable element and the latche represent the target element on which
 * you set your end position (e.g. seat belt fasten).
 * @returns void
 */
export const useFastenSeatBelt = (): void => {
    gsap.registerPlugin(Draggable);
    const isBeltClipped = useGame1Store.use.isBeltClipped();
    const socket = SocketService.getInstance().getSocket();
    const dragContainerElement = document.getElementById('drag_container');
    const latcheElement = document.getElementById('latche');

    const getTargetCoordinates = useCallback(() => {
        if (!dragContainerElement || !latcheElement) return { x: 0, y: 0 };

        const dragContainerRect = dragContainerElement.getBoundingClientRect();
        const latcheRect = latcheElement.getBoundingClientRect();

        /**
         * To compute the target we need to account of the offset created by the padding inside of the drag container (e.g. TONGUE_ORIGIN_OFFSET)
         * since gsap take as its origin the top left corner of the element you want to animate, here the tongue element.
         * Then we account for the container size (e.g. dragContainerRect) to set the proper base for the computation.
         * Finally we offset the target point of the latch element (e.g. latcheRect) to give the illussion of the the tongue clipping in it.
         */
        return {
            x:
                TONGUE_ORIGIN_OFFSET +
                WIDTH_OFFSET +
                latcheRect.width -
                dragContainerRect.width,
            y:
                dragContainerRect.height -
                latcheRect.height -
                HEIGHT_OFFSET -
                TONGUE_ORIGIN_OFFSET,
        };
    }, [dragContainerElement, latcheElement]);

    const goBackToOrigin = useCallback(() => {
        const point = { x: 0, y: 0 };
        gsap.to('#tongue', {
            x: point.x,
            y: point.y,
            duration: 0.5,
            animation: 'elastic',
        });
        beltFollowTongue(point, 0.5);
    }, []);

    const goToTarget = useCallback(() => {
        const target = getTargetCoordinates();
        gsap.to('#tongue', {
            ...target,
            duration: 0,
            animation: 'elastic',
        });
        beltFollowTongue(target);
    }, [getTargetCoordinates]);

    const beltFollowTongue = (
        target: gsap.Point2D = { x: 0, y: 0 },
        duration = 0
    ) => {
        gsap.to('#belt', {
            immediateRender: true,
            duration: duration,
            x: target.x,
            y: target.y,
        });
    };

    Draggable.create('#tongue', {
        bounds: dragContainerElement,
        liveSnap: {
            points: (point) => {
                if (!dragContainerElement || !latcheElement) return point;
                const target = getTargetCoordinates();
                const dx = point.x - target.x;
                const dy = point.y - target.y;

                return Math.sqrt(dx * dx + dy * dy) < RADIUS ? target : point;
            },
        },
        onDragEnd: () => {
            const isBeltBuckled = Draggable.hitTest(
                '#tongue',
                '#latche',
                COVERING_TRESHOLD
            );

            if (!isBeltBuckled) goBackToOrigin();
            playSound(SeatBeltClick);
            handleSeatBeltFastening(isBeltBuckled);
        },
        onDrag: function () {
            const { x, y } = this;
            beltFollowTongue({ x, y });
        },
    });

    useEffect(() => {
        if (isBeltClipped) {
            goToTarget();
        }
    }, [isBeltClipped, goToTarget]);

    useEffect(() => {
        socket.on('receive_reset_seat_belt_status', goBackToOrigin);
        return () => {
            socket.off('receive_reset_seat_belt_status');
        };
    }, [socket, goBackToOrigin]);

    useEffect(() => {
        socket.on('receive_update_seat_belt_status', ({ status }) => {
            if (status) goToTarget();
        });

        return () => {
            socket.off('receive_update_seat_belt_status');
        };
    }, [socket, goToTarget]);
};
