import { objectKeys } from '@/services/global/globalUtils';
import { useGame2Store } from '@/store/games/game2Store';
import { useGameStore } from '@/store/gameStore';
import type {
    CarLocation,
    CarLocationMap,
    CarObject,
    TruckObject,
} from '@/types/games/gameTwo/types';
import type { DeviceColor, Nullable } from '@/types/global/types';
import data from '@/data/gameTwoData.json';

export const truckPositions = [
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
] as const;

export function assertTruckObjectIsNotNull(
    object: Record<DeviceColor, Nullable<TruckObject>>
): asserts object is Record<DeviceColor, TruckObject> {
    if (
        object.red === null ||
        object.blue === null ||
        object.green === null ||
        object.orange === null
    ) {
        throw new Error('One of the objects is null');
    }
}

export function isWeightWellDistributed(
    selectedTruckObjects: Record<DeviceColor, TruckObject>
): boolean {
    const rightWeight =
        selectedTruckObjects.orange.weight + selectedTruckObjects.green.weight;
    const leftWeight =
        selectedTruckObjects.red.weight + selectedTruckObjects.blue.weight;

    return rightWeight === leftWeight;
}

export function isDeliveryOrderRespected(
    selectedTruckObjects: Record<DeviceColor, TruckObject>
): boolean {
    const topRightObject = selectedTruckObjects.green;
    const topLeftObject = selectedTruckObjects.red;
    const bottomRightObject = selectedTruckObjects.orange;
    const bottomLeftObject = selectedTruckObjects.blue;

    const isTopRightRespected =
        topRightObject.deliveryOrderIndex < topLeftObject.deliveryOrderIndex &&
        topRightObject.deliveryOrderIndex < bottomLeftObject.deliveryOrderIndex;

    const isBottomRightRespected =
        bottomRightObject.deliveryOrderIndex < topLeftObject.deliveryOrderIndex &&
        bottomRightObject.deliveryOrderIndex < bottomLeftObject.deliveryOrderIndex;

    return isTopRightRespected && isBottomRightRespected;
}

export function assertIsTruckObject(obj: object): asserts obj is TruckObject {
    const isTruck = useGameStore.getState().vehicle === 'truck';
    if (!isTruck) {
        throw new Error('Object is not a TruckObject');
    }
}

export function assertIsCarObject(obj: object): asserts obj is CarObject {
    const isCar = useGameStore.getState().vehicle === 'car';

    if (!isCar) {
        throw new Error('Object is not a CarObject');
    }
}

export function mapColorWithCarPosition(color: DeviceColor): CarLocation[] {
    const selectCarObjects = useGame2Store.getState().selectedCarObjects[color];

    return objectKeys(selectCarObjects);
}

export const mapLocationNameEnglishToFrench: Record<CarLocation, string> = {
    gloveBox: data.texts.gloveBox,
    frontPassenger: data.texts.frontPassengerSeat,
    frontMiddleStorage: data.texts.frontMiddleStorage,
    frontDriverDoorStorage: data.texts.frontDriverDoorStorage,
    backSeatRight: data.texts.backSeatRight,
    backSeatLeft: data.texts.backSeatLeft,
    trunk: data.texts.trunk,
};

export function areObjectsInGoodLocation() {
    const selectedCarObjects = useGame2Store.getState().selectedCarObjects;
    const carObjectLocations = Object.values(selectedCarObjects);
    const correctCarCombination = data.correctCarCombination;
    const carObjects = data.carObjects;

    const locationObjectMap = carObjectLocations
        .map((position) => Object.entries(position))
        .flat();

    const nonEmptyObjects = locationObjectMap.filter(
        ([, object]) => object?.id !== carObjects.at(-1)?.id && object
    );

    const emptyObjects = locationObjectMap.filter(
        ([, object]) => object?.id === carObjects.at(-1)?.id
    );

    if (nonEmptyObjects.length !== correctCarCombination.length) return;

    const areObjectWellPlaced = nonEmptyObjects.every(([location, object]) =>
        correctCarCombination.map(
            ({ location: correctLocation, objectIds: correctObjectIds }) => {
                return (
                    correctObjectIds.includes(object!.id) &&
                    correctLocation === location
                );
            }
        )
    );

    const enoughEmptyObjects = emptyObjects.length === 3;

    return areObjectWellPlaced && enoughEmptyObjects;
}

export function showValidateButton(
    selectedCarObjects: Record<DeviceColor, CarLocationMap>
) {
    const carObjectLocations = Object.values(selectedCarObjects);

    const objects = carObjectLocations
        .map((position) => Object.values(position))
        .flat();

    return objects.every((object) => object);
}

export function getColorByLocation(
    carLocation: CarLocation,
    selectedCarObjects: Record<DeviceColor, CarLocationMap>
) {
    const colors = objectKeys(selectedCarObjects);
    const color = colors.find((color) => {
        const locations = objectKeys(selectedCarObjects[color]);
        return locations.includes(carLocation);
    });

    return color;
}
