import createSelectors from '@/store/selectors';
import { create } from 'zustand';

export interface DeviceState {
    roomName: string;
}

const useRoomStoreBase = create(() => ({
    roomName: 'devRoom',
}));

export const useRoomStore = createSelectors(useRoomStoreBase);

export function setRoomName(roomName: string) {
    useRoomStoreBase.setState({
        roomName,
    });
}
