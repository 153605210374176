import styles from '@/assets/styles/app.module.scss';
import ErrorAndSuccessScreen from '@/features/components/modal/errorAndSuccess/ErrorAndSuccess';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { checkIsGamePrevention } from '@/services/global/globalUtils';
import { useGameStore } from '@/store/gameStore';
import type { PropsWithChildren } from 'react';

export default function TvLayout({ children }: PropsWithChildren) {
    const gameStatus = useGameStore.use.gameStatus();
    const isGamePrevention = checkIsGamePrevention(gameStatus);
    const { currentText, currentTextAudio, currentTitle, overlayType } =
        useCurrentOverlay();

    return (
        <div
            className={
                isGamePrevention ? styles.tvContainerPrevention : styles.tvContainer
            }
        >
            {overlayType !== 'neutral' && (
                <ErrorAndSuccessScreen
                    success={overlayType === 'success'}
                    text={currentText}
                    title={currentTitle}
                    audio={currentTextAudio}
                    isTablet={false}
                />
            )}
            {children}
        </div>
    );
}
