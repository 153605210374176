import { Ecology, Landslide, Trafic } from '@/assets/images/icons';
import dataGame3 from '@/data/gameThreeData.json';
import styles from '@/features/components/gameThree/map/components/Itineraries/direction.module.scss';
import type { Itinerary } from '@/types/games/gameThree/types';

export default function Direction({ itinerary }: { itinerary: Itinerary }) {
    const status = dataGame3.status;

    return (
        <div className={styles.itinerary}>
            <img src={itinerary.image} alt='itineraire' width={190} />
            <div className={styles.directionHeader}>
                <div>
                    <h3>{itinerary.duration} mins</h3>
                    <p>{itinerary.label}</p>
                </div>
                <div>{itinerary.distance} km</div>
            </div>
            <div className={styles.contentContainer}>
                <DirectionContent
                    image={Trafic}
                    title={status.trafic.label}
                    subTitle={status.trafic[itinerary.trafic]}
                />
                <DirectionContent
                    image={Landslide}
                    title={status.landslide.label}
                    subTitle={status.landslide[itinerary.landslideRisk]}
                />
                <DirectionContent
                    image={Ecology}
                    title={status.eco.label}
                    subTitle={status.eco[itinerary.eco]}
                />
            </div>
        </div>
    );
}

function DirectionContent({
    image,
    title,
    subTitle,
}: {
    image: string;
    title: string;
    subTitle: string;
}) {
    return (
        <div>
            <img src={image} alt='icon' width={60} />
            <div>
                <p>{title}</p>
                <p>{subTitle}</p>
            </div>
        </div>
    );
}
