import { INITIAL_GAME5_STORE } from '@/sessionInfo/sessionInfo';
import createSelectors from '@/store/selectors';
import type { Game5Store, Reaction } from '@/types/games/gameFive/types';
import { create } from 'zustand';

const useGame5StoreBase = create<Game5Store>(() =>
    structuredClone(INITIAL_GAME5_STORE)
);

export const useGame5Store = createSelectors(useGame5StoreBase);

export function setCurrentSituation(index: number) {
    return useGame5StoreBase.setState({ currentSituationIndex: index });
}

export function setPlayersAnswer(playersAnswer: Reaction | null) {
    useGame5StoreBase.setState({ playersAnswer });
}

export function resetGame5Store() {
    useGame5StoreBase.setState(structuredClone(INITIAL_GAME5_STORE));
}
