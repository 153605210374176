import game3Data from '@/data/gameThreeData.json';
import styles from '@/features/components/gameThree/crash/crashInfo.module.scss';
import { useGame3Store } from '@/store/games/game3Store';
import Direction from '@/features/components/gameThree/map/components/Itineraries/Direction';
import { CarCrash } from '@/assets/images/global';
import TextAnimation from '@/features/components/text/TextAnimation';
import AudioComponent from '@/features/components/AudioComponent';

export default function CrashInfo() {
    const selectedItinerary = useGame3Store.use.selectedItinerary();
    const { title, content } = game3Data.crash;

    const enrichedText = content.replace(/([.!?])/g, '$1\n\n');

    return (
        <div className={styles.crashInfoContainer}>
            <h1>{title}</h1>
            <div>
                <TextAnimation text={enrichedText} />
                <AudioComponent src={game3Data.crash.audio} />
                <div>
                    <img src={selectedItinerary?.crashImage} alt='crash' />
                    <div className={styles.directionWrapper}>
                        <img src={CarCrash} alt='gif car crash' />
                        <Direction itinerary={selectedItinerary!} />
                    </div>
                </div>
            </div>
        </div>
    );
}
