import globalStyle from '@/assets/styles/app.module.scss';
import MediatorScreen from '@/features/devices/tablets/mediator/MediatorScreen';
import PreConnectionMediatorScreen from '@/features/devices/tablets/mediator/PreConnectionMediatorScreen';
import { useDynamicTabletName } from '@/hooks/useDynamicTabletName';
import useReconnection from '@/hooks/useReconnection';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import SocketService from '@/services/SocketServices';

import { useDeviceStore } from '@/store/deviceStore';

export default function MediatorTablet({ roomName }: { roomName: string }) {
    useReconnection();
    const socket = SocketService.getInstance().getSocket();

    const { mediator, blue, orange, red, green } =
        useDeviceStore.use.deviceStatus();

    useDynamicTabletName('mediator', mediator.label);
    useReSynchronizeDevice('mediator');

    return (
        <div className={globalStyle.containerTablet}>
            {mediator.isActivated ? (
                <MediatorScreen />
            ) : (
                <PreConnectionMediatorScreen
                    deviceStatuses={[
                        { label: 'Bleu', status: blue.isConnected, color: 'blue' },
                        {
                            label: 'Orange',
                            status: orange.isConnected,
                            color: 'orange',
                        },
                        { label: 'Rouge', status: red.isConnected, color: 'red' },
                        {
                            label: 'Verte',
                            status: green.isConnected,
                            color: 'green',
                        },
                    ]}
                    handleConnect={() => {
                        socket.emit('send_start_game', {
                            roomName,
                            color: 'mediator',
                        });
                    }}
                />
            )}
        </div>
    );
}
