/**
 * Custom type to get all values of an object, as same as enum values.
 */
export type ObjectValues<T> = T[keyof T];

/**
 * Custom type to enable completion of the snippet. Works as same as the original {@link Omit} type.
 */
export type StrictOmit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type Nullable<T> = T | null;

export type Maybe<T> = T | undefined;

export type DeviceColor = 'blue' | 'green' | 'orange' | 'red';
export type DeviceColorWithTV = DeviceColor | 'tv';
export type DeviceColorWithTvAndMediator = DeviceColorWithTV | 'mediator';

export const GAMESTATUS = {
    WELCOME_SCREEN: 'WELCOME_SCREEN',
    VEHICLE_SELECTION: 'VEHICLE_SELECTION',

    GAME1INTRODUCTION_LOADING: 'GAME1INTRODUCTION_LOADING',
    GAME1INTRODUCTION: 'GAME1INTRODUCTION',
    GAME1ACTION: 'GAME1ACTION',
    GAME1PREVENTION: 'GAME1PREVENTION',
    GAME1UNLOCK: 'GAME1UNLOCK',

    GAME2INTRODUCTION_LOADING: 'GAME2INTRODUCTION_LOADING',
    GAME2INTRODUCTION: 'GAME2INTRODUCTION',
    GAME2ACTION: 'GAME2ACTION',
    GAME2PREVENTION: 'GAME2PREVENTION',
    GAME2UNLOCK: 'GAME2UNLOCK',

    GAME3INTRODUCTION_LOADING: 'GAME3INTRODUCTION_LOADING',
    GAME3INTRODUCTION: 'GAME3INTRODUCTION',
    GAME3ACTION: 'GAME3ACTION',

    BREATHING_INTRO: 'BREATHING_INTRO',
    BREATHING_ACTION: 'BREATHING_ACTION',
    BREATHING_END: 'BREATHING_END',

    GAME3PREVENTION: 'GAME3PREVENTION',
    GAME3UNLOCK: 'GAME3UNLOCK',

    GAME4INTRODUCTION_LOADING: 'GAME4INTRODUCTION_LOADING',
    GAME4INTRODUCTION: 'GAME4INTRODUCTION',
    GAME4ACTION: 'GAME4ACTION',
    GAME4PREVENTION: 'GAME4PREVENTION',
    GAME4UNLOCK: 'GAME4UNLOCK',

    GAME5INTRODUCTION_LOADING: 'GAME5INTRODUCTION_LOADING',
    GAME5INTRODUCTION: 'GAME5INTRODUCTION',
    GAME5ACTION: 'GAME5ACTION',
    GAME5PREVENTION: 'GAME5PREVENTION',
    GAME5UNLOCK: 'GAME5UNLOCK',

    GAME6INTRODUCTION_LOADING: 'GAME6INTRODUCTION_LOADING',
    GAME6INTRODUCTION: 'GAME6INTRODUCTION',
    GAME6ACTION: 'GAME6ACTION',
    GAME6PREVENTION: 'GAME6PREVENTION',
    GAME6UNLOCK: 'GAME6UNLOCK',

    FINISHED: 'FINISHED',
} as const;

export type GameStatus = ObjectValues<typeof GAMESTATUS>;
