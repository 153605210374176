import data from '@/data/gameFiveData.json';
import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import { userCurrentSituationGame5 } from '@/hooks/useCurrentSituationGame5';
import {
    handleSelectReaction,
    handleUpdateCurrentSituationIndex,
    handleValidateGame5,
} from '@/services/games/game5/gameFiveHandleFunctions';
import { useGame5Store } from '@/store/games/game5Store';

export default function GameFiveMediator() {
    const currentIndex = useGame5Store.use.currentSituationIndex();
    const playersAnswer = useGame5Store.use.playersAnswer();
    const totalSituations = data.situations.length;

    const currentSituation = userCurrentSituationGame5();

    return (
        <div>
            {/* Go to next situation */}
            <MediatorAction
                title='Passer a la situation suivante'
                displayingCondition={currentIndex < totalSituations - 1}
                handleFunction={() => {
                    handleUpdateCurrentSituationIndex(currentIndex + 1);
                }}
                type='secondary'
            />

            {/* Go to previous situation */}
            <MediatorAction
                title='Revenir a la situation précédente'
                displayingCondition={currentIndex > 0}
                handleFunction={() => {
                    handleUpdateCurrentSituationIndex(currentIndex - 1);
                }}
                type='secondary'
            />

            {/* Select the correct answers */}
            <MediatorAction
                title='Sélectionner la bonne réponse'
                displayingCondition={true}
                handleFunction={() => handleSelectReaction(currentSituation)}
                type='secondary'
            />

            {/* Validate the current answer */}
            <MediatorAction
                title='Valider la réponse actuelle'
                displayingCondition={playersAnswer != null}
                handleFunction={handleValidateGame5}
                type='secondary'
            />
        </div>
    );
}
