import { memo } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

function Confettis({
    top,
    right,
    bottom,
    left,
}: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
}) {
    return (
        <div style={{ position: 'absolute', top, right, bottom, left }}>
            <ConfettiExplosion width={1920} particleCount={250} force={0.8} />
        </div>
    );
}

export const MemoizedConfettis = memo(Confettis);
