import Cockpit from '@/features/components/cockpit/Cockpit';
import CrashInfo from '@/features/components/gameThree/crash/CrashInfo';
import styles from '@/features/components/gameThree/gameThree.module.scss';
import Scenes from '@/features/components/gameThree/scenes/Scenes';
import { useGame3Store } from '@/store/games/game3Store';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';

export default function GameThree() {
    const selectedItinerary = useGame3Store.use.selectedItinerary();
    const cockpitStatus = useGameStore.use.cockpitStatus();
    const overlayType = useOverlayStore.use.overlayType();

    const render = () => {
        switch (cockpitStatus) {
            case 'INFO':
                return selectedItinerary && <CrashInfo />;
            case 'GAME':
                return overlayType === 'neutral' ? <Scenes /> : null;
            default:
                return (
                    <div className={styles.cockpitTvContainer}>
                        <Cockpit device='tv' />
                    </div>
                );
        }
    };

    return render();
}
