import { INITIAL_GAME1_STORE } from '@/sessionInfo/sessionInfo';
import createSelectors from '@/store/selectors';
import {
    type Game1Store,
    type Tire,
    type TirePressureStatus,
} from '@/types/games/gameOne/types';
import { create } from 'zustand';

function initializeStore() {
    return structuredClone(INITIAL_GAME1_STORE);
}

const useGame1StoreBase = create<Game1Store>(initializeStore);

export const useGame1Store = createSelectors(useGame1StoreBase);

export function setTirePressureValidation(status: TirePressureStatus) {
    useGame1StoreBase.setState({ tirePressureInfoStatus: status });
}

export function setTirePressure(tirePressure: number, tire?: Tire) {
    const newTiresPressure = {} as Record<Tire, number>;

    if (!tire) {
        newTiresPressure['LeftFront'] = tirePressure;
        newTiresPressure['LeftRear'] = tirePressure;
        newTiresPressure['RightFront'] = tirePressure;
        newTiresPressure['RightRear'] = tirePressure;
    }

    useGame1StoreBase.setState((state) => ({
        ...state,
        tiresPressure: tire
            ? { ...state.tiresPressure, [tire]: tirePressure }
            : newTiresPressure,
    }));
}

export function setSeatBeltStatus(status: boolean) {
    useGame1StoreBase.setState({ isBeltClipped: status });
}

export function setDoNotDisturb(status: boolean) {
    useGame1StoreBase.setState({ isDoNotDisturbOn: status });
}

export function setIsBeeingCalled(status: boolean) {
    useGame1StoreBase.setState({ isBeingCalled: status });
}

export function setCallerId(id: number) {
    useGame1StoreBase.setState({ callerId: id });
}

export function setUnlockState(status: boolean) {
    useGame1StoreBase.setState({ unlockState: status });
}

export function resetTirePressure() {
    useGame1StoreBase.setState(() => ({
        tiresPressure: structuredClone(INITIAL_GAME1_STORE.tiresPressure),
    }));
}

export function resetGame1Store() {
    useGame1StoreBase.setState(structuredClone(INITIAL_GAME1_STORE));
}
