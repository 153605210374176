import data from '@/data/global.json';
import styles from '@/features/components/breathingExercice/deviceBreathingScreen/deviceBreathingScreen.module.scss';
import Button from '@/features/devices/tablets/components/buttons/Button';
import { handleUpdatePlayerStatus } from '@/services/games/game3/gameThreeHandleFunctions';
import type { DeviceColor } from '@/types/global/types';

interface DeviceBreathingScreenProps {
    device: DeviceColor;
}

export default function DeviceBreathingScreen({
    device,
}: DeviceBreathingScreenProps) {
    return (
        <div className={styles.container}>
            <div className={styles.breathing_circle}>
                <Button
                    color='black'
                    text={data.global.ready}
                    onClick={() =>
                        handleUpdatePlayerStatus(device, 'isPlaying', true)
                    }
                    additionalStyle={{
                        width: '80%',
                    }}
                />
            </div>
        </div>
    );
}
