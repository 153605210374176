import overlayContent from '@/data/overlayDeviceContent.json';
import {
    emitSocketEvent,
    getNextGameStatus,
    getSocket,
    isGameACtion,
    tabletStatusGameConfig,
} from '@/services/global/globalUtils';
import { setOverlayCallback, useOverlayStore } from '@/store/overlayStore';

import { useGameStore } from '@/store/gameStore';
import {
    type CockpitAction,
    type OverlayDescription,
    type OverlayType,
    type Vehicle,
} from '@/types/games/types';
import {
    GAMESTATUS,
    type DeviceColorWithTvAndMediator,
    type GameStatus,
} from '@/types/global/types';

export const handleReset = () => {
    emitSocketEvent('send_reset', {});
};

export const handleSendGameStatus = (gameStatus: GameStatus) => {
    emitSocketEvent('send_game_status', { status: gameStatus });
};

export const handleUpdateDeviceActivatedStatus = (
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
) => {
    emitSocketEvent('send_device_activated_status', {
        deviceColor,
        status,
    });
};

export const handleSendIsAudioPlaying = (
    isAudioPlaying: boolean,
    roomName: string
): void => {
    const socket = getSocket();

    socket?.emit('send_is_audio_playing', { roomName, isAudioPlaying });
};

export const handleTabletStatus = (deviceActivationStatus: {
    [color: string]: boolean;
}) => {
    Object.entries(deviceActivationStatus).forEach(([color, status]) => {
        handleUpdateDeviceActivatedStatus(
            color as DeviceColorWithTvAndMediator,
            status
        );
    });
};

export const handleGoToGameStatus = (gameStatus: GameStatus) => {
    handleSendGameStatus(gameStatus);
    handleTabletStatus(
        tabletStatusGameConfig[gameStatus as keyof typeof tabletStatusGameConfig]
    );

    const isGameAction =
        gameStatus === GAMESTATUS.GAME1ACTION ||
        gameStatus === GAMESTATUS.GAME2ACTION ||
        gameStatus === GAMESTATUS.GAME3ACTION ||
        gameStatus === GAMESTATUS.GAME4ACTION ||
        gameStatus === GAMESTATUS.GAME5ACTION ||
        gameStatus === GAMESTATUS.GAME6ACTION;

    if (isGameAction) {
        handleUpdateOverlayDescriptions([], 'neutral');
    } else {
        handleUpdateOverlayDescriptions(
            overlayContent[gameStatus].tv as OverlayDescription[],
            'neutral'
        );
    }
};
export const handleUpdateOverlayIndex = (overlayIndex: number) => {
    emitSocketEvent('send_update_overlay_index', { overlayIndex });
};

export const handleUpdateOverlayType = (overlayType: OverlayType) => {
    emitSocketEvent('send_update_overlay_type', { overlayType });
};

export const handleUpdateOverlayDescriptions = (
    overlayDescriptions: OverlayDescription[],
    overlayType: OverlayType
) => {
    const vehicle = useGameStore.getState().vehicle;
    const descriptions = overlayDescriptions.filter(
        (desc) => !desc.category || desc.category === vehicle
    );
    emitSocketEvent('send_update_overlay_descriptions', {
        overlayDescriptions: descriptions,
        overlayType,
    });
};

export const handleNextOrPreviousOverlayDescription = (
    action: 'next' | 'previous'
): void => {
    const nextGameStatus = getNextGameStatus();
    const { overlayDescriptions, currentOverlayIndex } = useOverlayStore.getState();

    if (action === 'next') {
        const isLastOverlayMessage =
            overlayDescriptions &&
            currentOverlayIndex === overlayDescriptions.length - 1;

        if (isGameACtion() && isLastOverlayMessage) {
            handleUpdateOverlayDescriptions([], 'neutral');
            setOverlayCallback(null);
            return;
        }

        if (isLastOverlayMessage) {
            handleGoToGameStatus(nextGameStatus);
        } else {
            handleUpdateOverlayIndex(currentOverlayIndex + 1);
        }
    } else if (action === 'previous') {
        const isFirstMessage = overlayDescriptions && currentOverlayIndex === 0;
        if (!isFirstMessage) handleUpdateOverlayIndex(currentOverlayIndex - 1);
    }
};

export function handleVehicleSelection(type: Vehicle) {
    emitSocketEvent('send_update_vehicle', { type });
}

export function handleModeSelection(mode: 'cadenas' | 'pochette') {
    emitSocketEvent('send_update_mode', { mode });
}

export const handleCockpitAction = (action: CockpitAction) => {
    emitSocketEvent('send_update_cockpit_status', { action });
};

export const handleUnlockScreen = (status: boolean) => {
    emitSocketEvent('send_update_unlock_screen', { status });
};
