import { ClickSound } from '@/assets/sounds/soundsEffects';
import styles from '@/features/devices/tablets/components/gameFive/fullScreenButton.module.scss';
import { handleSelectReactionByDeviceColor } from '@/services/games/game5/gameFiveHandleFunctions';
import { playSound } from '@/services/global/globalUtils';
import type { Game5Tablets } from '@/types/games/gameFive/types';
import { motion } from 'framer-motion';

export default function FullScreenButton({
    color,
    text,
    onClick,
}: {
    color: Game5Tablets;
    text: string;
    onClick?: () => void;
}) {
    return (
        <motion.button
            onClick={() => {
                //TODO: update sound
                playSound(ClickSound);
                onClick ? onClick() : handleSelectReactionByDeviceColor(color);
            }}
            whileTap={{ scale: 0.95 }}
            className={` ${styles[`${color}Container`]}`}
        >
            <p>{text.toUpperCase()}</p>
            <div />
        </motion.button>
    );
}
