import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import styles from '@/features/devices/tablets/mediator/mediatorScreen.module.scss';
import StatusIndicator from '@/features/devices/tablets/mediator/StatusIndicator';

import commonData from '@/data/global.json';
import data from '@/data/mediator/actionsGames.json';
import {
    displayPreventMessage,
    gamesAccess,
    resetGame,
} from '@/data/mediator/genericDatas.json';
import introductionData from '@/data/overlayDeviceContent.json';
import BreathingExerciceMediator from '@/features/components/breathingExercice/mediator/BreathingExerciceMediator';
import GameFiveMediator from '@/features/devices/tablets/mediator/gamesComponents/GameFiveMediator';
import GameFourMediator from '@/features/devices/tablets/mediator/gamesComponents/GameFourMediator';
import GameOneMediator from '@/features/devices/tablets/mediator/gamesComponents/GameOneMediator';
import GameSixMediator from '@/features/devices/tablets/mediator/gamesComponents/GameSixMediator';
import GameThreeMediator from '@/features/devices/tablets/mediator/gamesComponents/GameThreeMediator';
import GameTwoMediator from '@/features/devices/tablets/mediator/gamesComponents/GameTwoMediator';
import SelectVehicleMediator from '@/features/devices/tablets/mediator/gamesComponents/SelectVehicleMediator';
import ToggleTabletStatus from '@/features/devices/tablets/mediator/ToggleTabletStatus';
import {
    handleGoToGameStatus,
    handleModeSelection,
    handleNextOrPreviousOverlayDescription,
    handleReset,
    handleTabletStatus,
    handleUpdateOverlayDescriptions,
} from '@/services/global/globalHandleFunctions';
import {
    emitSocketEvent,
    getNextGameStatus,
    objectKeys,
} from '@/services/global/globalUtils';
import { type ActionsGamesMapping } from '@/services/mediator/types.mediator';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import { type OverlayDescription } from '@/types/games/types';
import {
    GAMESTATUS,
    type DeviceColorWithTvAndMediator,
    type GameStatus,
} from '@/types/global/types';
import { handleOverlayCallbackMediator } from '@/services/mediator/mediatorUtils';

export default function MediatorScreen() {
    const gameStatus = useGameStore.use.gameStatus();
    const vehicle = useGameStore.use.vehicle();
    const mode = useGameStore.use.mode();
    const allDevices = useDeviceStore.use.deviceStatus();
    const currentOverlayDescriptions = useOverlayStore.use.overlayDescriptions();
    const currentOverlayIndex = useOverlayStore.use.currentOverlayIndex();
    const currentOverlayType = useOverlayStore.use.overlayType();

    const actionsGames: ActionsGamesMapping = data;
    const btnActions = actionsGames[gameStatus].actions;

    const isGameAction =
        gameStatus === GAMESTATUS.GAME1ACTION ||
        gameStatus === GAMESTATUS.GAME2ACTION ||
        gameStatus === GAMESTATUS.GAME3ACTION ||
        gameStatus === GAMESTATUS.BREATHING_ACTION ||
        gameStatus === GAMESTATUS.GAME4ACTION ||
        gameStatus === GAMESTATUS.GAME5ACTION ||
        gameStatus === GAMESTATUS.GAME6ACTION;

    const isPreventionMessage =
        gameStatus === GAMESTATUS.GAME1PREVENTION ||
        gameStatus === GAMESTATUS.GAME2PREVENTION ||
        gameStatus === GAMESTATUS.GAME3PREVENTION ||
        gameStatus === GAMESTATUS.GAME4PREVENTION ||
        gameStatus === GAMESTATUS.GAME5PREVENTION ||
        gameStatus === GAMESTATUS.GAME6PREVENTION;

    const isUnlockPage =
        gameStatus === GAMESTATUS.GAME1UNLOCK ||
        gameStatus === GAMESTATUS.GAME2UNLOCK ||
        gameStatus === GAMESTATUS.GAME3UNLOCK ||
        gameStatus === GAMESTATUS.GAME4UNLOCK ||
        gameStatus === GAMESTATUS.GAME5UNLOCK ||
        gameStatus === GAMESTATUS.GAME6UNLOCK;

    const handleClick = (action: string | undefined) => {
        switch (action) {
            case 'resetGame':
                handleReset();
                break;
            case 'initGame':
                handleGoToGameStatus(GAMESTATUS.VEHICLE_SELECTION);
                handleUpdateOverlayDescriptions(
                    introductionData.VEHICLE_SELECTION.tv as OverlayDescription[],
                    'neutral'
                );
                break;
            case 'previous':
            case 'next':
                handleNextOrPreviousOverlayDescription(action);
                break;
            case 'displayUnlockCode':
                handleGoToGameStatus(
                    actionsGames[gameStatus]?.unlockCodeStatus as GameStatus
                );
                break;
            case 'nextGame':
                handleGoToGameStatus(getNextGameStatus());
                break;
            case 'displayPreventMessage':
                handleGoToGameStatus(
                    actionsGames?.[gameStatus]?.preventStatus as GameStatus
                );
                break;
            case 'desactivateAllTablets':
                handleTabletStatus({
                    blue: false,
                    green: false,
                    orange: false,
                    red: false,
                });
                break;
        }
    };

    const handleReconnection = (device: DeviceColorWithTvAndMediator) => {
        emitSocketEvent('send_synchronize_device', {
            deviceColor: device,
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Écran Médiateur</h1>
            </div>
            <div>
                <div>
                    <div className={styles.subtitles}>
                        <h2>Session en cours</h2>
                        <h3>Description de la session</h3>
                    </div>
                    <div className={styles.devicesContainer}>
                        {objectKeys(allDevices)
                            .filter((deviceType) => deviceType !== 'mediator')
                            .map((deviceType) => {
                                const deviceContent = allDevices[deviceType];
                                return (
                                    <div
                                        key={deviceType}
                                        className={`${styles.device} `}
                                    >
                                        <div
                                            className={styles.tablet}
                                            style={{ borderColor: deviceType }}
                                        >
                                            <p>
                                                {deviceContent.isConnected
                                                    ? '✅'
                                                    : '❌'}
                                            </p>
                                        </div>
                                        <span>{deviceContent.label}</span>
                                        <StatusIndicator
                                            status={deviceContent.isConnected}
                                        />
                                        <button
                                            className={styles.button}
                                            onClick={() =>
                                                handleReconnection(deviceType)
                                            }
                                        >
                                            Resynchroniser
                                        </button>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className={styles.separator} />
                <div className={styles.mediatorActions}>
                    <h1>Actions Générales</h1>
                    {gameStatus !== GAMESTATUS.WELCOME_SCREEN && (
                        <h2>En cours : {gameStatus}</h2>
                    )}

                    {!vehicle && (
                        <h2 className={styles.warning}>
                            {commonData.global.mandatoryVehicleSelection}
                        </h2>
                    )}

                    {!mode && (
                        <h2 className={styles.warning}>
                            {commonData.global.mandatoryModeSelection}
                        </h2>
                    )}

                    {/* ****************   BUTTONS COMMONS TO ALL GAMES   ************** */}
                    {/* resetGame, initGame, next/prev overlay, display score page, prevent message and unlock Code  */}
                    <MediatorAction
                        handleFunction={() => handleClick(resetGame.nameFunction)}
                        title={resetGame.btnTitle}
                        description={resetGame.description}
                        displayingCondition={true}
                    />
                    <MediatorAction
                        handleFunction={() =>
                            handleClick(btnActions?.initGame.nameFunctions[0])
                        }
                        title={btnActions?.initGame?.btnTitle || ''}
                        description={btnActions?.initGame?.description || ''}
                        displayingCondition={
                            gameStatus === GAMESTATUS.WELCOME_SCREEN && !!mode
                        }
                    />
                    <MediatorAction
                        handleFunction={() => {
                            handleClick('next');
                        }}
                        title='Message suivant'
                        description='Passer au message suivant'
                        displayingCondition={
                            !isGameAction &&
                            gameStatus !== GAMESTATUS.WELCOME_SCREEN &&
                            gameStatus !== GAMESTATUS.VEHICLE_SELECTION &&
                            currentOverlayDescriptions.length > 0
                        }
                    />
                    <MediatorAction
                        handleFunction={() => {
                            handleClick('previous');
                        }}
                        title='Message précédent'
                        description='Passer au message précédent'
                        displayingCondition={
                            !isGameAction &&
                            currentOverlayDescriptions &&
                            currentOverlayIndex > 0
                        }
                    />

                    {gameStatus === GAMESTATUS.WELCOME_SCREEN && (
                        <>
                            <MediatorAction
                                title='Choisir le mode de jeu pochette'
                                description='Choisir le mode de jeu pochette pour le restant de la partie'
                                handleFunction={() =>
                                    handleModeSelection('pochette')
                                }
                                displayingCondition={!mode}
                            />
                            <MediatorAction
                                title='Choisir le mode de jeu cadenas'
                                description='Choisir le mode de jeu cadenas pour le restant de la partie'
                                handleFunction={() =>
                                    handleModeSelection('cadenas')
                                }
                                displayingCondition={!mode}
                            />
                        </>
                    )}

                    {gameStatus === GAMESTATUS.VEHICLE_SELECTION && (
                        <SelectVehicleMediator />
                    )}

                    <MediatorAction
                        handleFunction={() => {
                            handleClick(displayPreventMessage?.nameFunction);
                        }}
                        title={displayPreventMessage.btnTitle}
                        description={displayPreventMessage.description}
                        displayingCondition={
                            (isGameAction || gameStatus.includes('BREATHING')) &&
                            !isPreventionMessage
                        }
                    />

                    <MediatorAction
                        handleFunction={() => {
                            handleClick(btnActions?.nextGame?.nameFunctions[0]);
                        }}
                        title={btnActions?.nextGame?.btnTitle || ''}
                        description={btnActions?.nextGame?.description}
                        displayingCondition={isUnlockPage}
                    />

                    <MediatorAction
                        handleFunction={() => {
                            handleUpdateOverlayDescriptions([], 'neutral');
                            handleOverlayCallbackMediator(
                                gameStatus,
                                currentOverlayType
                            );
                        }}
                        title='Passer le message'
                        description='Passer le message actuallement affiché'
                        type='tertiary'
                        displayingCondition={currentOverlayType !== 'neutral'}
                    />

                    {/* ****************  ACTIONS BUTTONS FOR CURRENT GAME   ************** */}
                    <div className={styles.separator} />

                    <h1>Accès jeux</h1>

                    {vehicle && mode && gameStatus === GAMESTATUS.GAME1ACTION && (
                        <GameOneMediator />
                    )}
                    {vehicle && mode && gameStatus === GAMESTATUS.GAME2ACTION && (
                        <GameTwoMediator />
                    )}
                    {vehicle && mode && gameStatus === GAMESTATUS.GAME3ACTION && (
                        <GameThreeMediator />
                    )}

                    {vehicle && mode && gameStatus === GAMESTATUS.GAME4ACTION && (
                        <GameFourMediator />
                    )}
                    {vehicle && mode && gameStatus === GAMESTATUS.GAME5ACTION && (
                        <GameFiveMediator />
                    )}
                    {vehicle && mode && gameStatus === GAMESTATUS.GAME6ACTION && (
                        <GameSixMediator />
                    )}
                    {vehicle && mode && gameStatus.includes('BREATHING') && (
                        <BreathingExerciceMediator />
                    )}

                    {/* ****************  BUTTONS TO ACCESS GAMES ANYTIME  ************** */}
                    <div className={styles.separator} />
                    {gamesAccess.map((action, index) => (
                        <MediatorAction
                            key={index}
                            handleFunction={() => {
                                handleGoToGameStatus(
                                    action.accessGameStatus as GameStatus
                                );
                            }}
                            title={action.btnTitle}
                            description={action.description}
                            displayingCondition={!!vehicle && !!mode}
                        />
                    ))}

                    {/* **************** DESACTIVATE / ACTIVATE TABLETS ************** */}
                    <div className={styles.separator} />
                    <h1>Désactivation / Activation Tablettes</h1>
                    <ToggleTabletStatus />
                </div>
            </div>
        </div>
    );
}
