import game3Data from '@/data/gameThreeData.json';
import AudioComponent from '@/features/components/AudioComponent';
import styles from '@/features/components/gameThree/scenes/scenes.module.scss';
import SwipableScene from '@/features/components/gameThree/scenes/SwipableScene';
import { handleAudioPlayed } from '@/services/games/game3/gameThreeHandleFunctions';
import { MAP_SWIPE_ACTION_TO_DIRECTION } from '@/services/games/game3/gameThreeUtils';
import { useGame3Store } from '@/store/games/game3Store';
import type { Scene } from '@/types/games/gameThree/types';
import { AnimatePresence, motion } from 'framer-motion';

const CARD_VARIANTS = {
    left: {
        x: '-200%',
        y: -800,
        opacity: 0,
        rotate: -30,
    },
    center: {
        x: 0,
        y: 0,
        opacity: 1,
        rotate: 0,
    },
    right: {
        x: '200%',
        y: -800,
        opacity: 0,
        rotate: 30,
    },
};

export default function Scenes() {
    const audioPlayed = useGame3Store.use.audioPlayed?.();
    const currentSceneIndex = useGame3Store.use.currentSceneIndex();
    const currentGroupIndex = useGame3Store.use.currentGroupIndex();
    const swipeAction = useGame3Store.use.swipeAction();

    const currentScene = game3Data.scenes[currentSceneIndex] as Scene;
    const currentGroup = currentScene.groups[currentGroupIndex];

    const actions = currentGroup.actions;

    const renderSceneResult = () => {
        return (
            <div className={styles.scene_container}>
                {!audioPlayed ? (
                    <AudioComponent
                        src={currentScene.audio}
                        onEndedCallback={() => handleAudioPlayed(true)}
                    />
                ) : null}
                <div className={styles.left} />
                <p className={styles.actionTextLeft}>
                    {game3Data.sceneActionsMap[actions[1]].toUpperCase()}
                </p>
                <div className={styles.right} />
                <p className={styles.actionTextRight}>
                    {game3Data.sceneActionsMap[actions[0]].toUpperCase()}
                </p>
                <div className={styles.card_container}>
                    <AnimatePresence mode='wait' initial={false}>
                        <motion.div
                            key={currentSceneIndex}
                            variants={CARD_VARIANTS}
                            initial={
                                MAP_SWIPE_ACTION_TO_DIRECTION[
                                    swipeAction ?? 'illegal'
                                ]
                            }
                            animate='center'
                            exit={
                                MAP_SWIPE_ACTION_TO_DIRECTION[
                                    swipeAction ?? 'legal'
                                ]
                            }
                            transition={{
                                type: 'spring',
                                bounce: 0.5,
                            }}
                        >
                            <SwipableScene scene={currentScene} />
                        </motion.div>
                    </AnimatePresence>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            {!currentScene ? (
                <div>{game3Data.endOfScenes}</div>
            ) : (
                renderSceneResult()
            )}
        </div>
    );
}
