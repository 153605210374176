import data from '@/data/global.json';
import AudioComponent from '@/features/components/AudioComponent';
import TextAnimation from '@/features/components/text/TextAnimation';
import styles from '@/features/devices/tv/components/introduction/introduction.module.scss';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { useGameStore } from '@/store/gameStore';

export default function Introduction() {
    const { currentText, currentTitle, currentTextAudio } = useCurrentOverlay();
    const vehicle = useGameStore.use.vehicle();

    if (!vehicle) return null;

    let enrichedText = '';

    enrichedText = currentText
        ?.replace(/\[VEHICLE_NAME\]/g, data.global[vehicle])
        .replace(/([.!?])/g, '$1\n\n');

    return (
        <>
            <div className={styles.container}>
                <p className={styles.intro}>Introduction</p>
                <p className={styles.title}>{currentTitle}</p>
                <TextAnimation text={enrichedText} />
                <AudioComponent src={currentTextAudio} />
            </div>
        </>
    );
}
