import data from '@/data/gameTwoData.json';
import {
    areObjectsInGoodLocation,
    assertTruckObjectIsNotNull,
    isDeliveryOrderRespected,
    isWeightWellDistributed,
} from '@/services/games/game2/gameTwoUtils';
import {
    handleGoToGameStatus,
    handleUpdateOverlayDescriptions,
} from '@/services/global/globalHandleFunctions';
import { emitSocketEvent, getNextGameStatus } from '@/services/global/globalUtils';
import { useGame2Store } from '@/store/games/game2Store';
import { setOverlayCallback } from '@/store/overlayStore';
import type {
    CarLocation,
    CarObject,
    TruckObject,
} from '@/types/games/gameTwo/types';
import type { DeviceColor } from '@/types/global/types';

export function handleTruckObjectSelectedGametwo(
    object: TruckObject,
    deviceColor: DeviceColor
): void {
    emitSocketEvent('send_truck_objects_selected_game_two', {
        object,
        deviceColor,
    });
}

export function handleTruckObjectStrappedGametwo(
    deviceColor: DeviceColor,
    isStrapped: boolean
): void {
    emitSocketEvent('send_truck_objects_strapped_game_two', {
        deviceColor,
        isStrapped,
    });
}

export function handleCarObjectSelectedGameTwo(
    carLocation: CarLocation,
    object: CarObject,
    color?: DeviceColor
): void {
    emitSocketEvent('send_car_objects_selected_game_two', {
        color,
        carLocation,
        object,
    });
}

export function handleValidateGameTwoTruck(): void {
    const selectedTruckObjects = useGame2Store.getState().selectedTruckObjects;

    assertTruckObjectIsNotNull(selectedTruckObjects);

    const goodCombination =
        isWeightWellDistributed(selectedTruckObjects) &&
        isDeliveryOrderRespected(selectedTruckObjects);

    if (!isWeightWellDistributed(selectedTruckObjects)) {
        handleUpdateOverlayDescriptions(
            [
                {
                    id: 1,
                    title: data.result['errorDistributedTruck'].title,
                    text: data.result['errorDistributedTruck'].text,
                    audio: data.result['errorDistributedTruck'].audio,
                },
            ],
            'error'
        );
    } else if (!isDeliveryOrderRespected(selectedTruckObjects)) {
        handleUpdateOverlayDescriptions(
            [
                {
                    id: 1,
                    title: data.result['errorOrderTruck'].title,
                    text: data.result['errorOrderTruck'].text,
                    audio: data.result['errorOrderTruck'].audio,
                },
            ],
            'error'
        );
    }

    if (goodCombination) {
        handleUpdateOverlayDescriptions(
            [
                {
                    id: 1,
                    title: data.result['success'].title,
                    text: data.result['success'].text,
                    audio: data.result['success'].audio,
                },
            ],
            'success'
        );

        setOverlayCallback(() => {
            handleGoToGameStatus(getNextGameStatus());
        });
    } else {
        setOverlayCallback(() => {
            Object.keys(selectedTruckObjects).forEach((color) => {
                handleTruckObjectStrappedGametwo(color as DeviceColor, false);
            });
        });
    }
}

export function handleValidateGameTwoCar(): void {
    const areObjectsAtGoodLocation = areObjectsInGoodLocation();
    const status = areObjectsAtGoodLocation ? 'success' : 'errorCar';

    handleUpdateOverlayDescriptions(
        [
            {
                id: 1,
                title: data.result[status].title,
                text: data.result[status].text,
                audio: data.result[status].audio,
            },
        ],
        areObjectsAtGoodLocation ? 'success' : 'error'
    );

    if (status === 'success') {
        setOverlayCallback(() => {
            handleGoToGameStatus(getNextGameStatus());
        });
    }
}
