import { ClickSound } from '@/assets/sounds/soundsEffects';
import gameThreeData from '@/data/gameThreeData.json';
import { DestinationProposal } from '@/features/components/gameThree/map/components/DestinationProposal/DestinationProposal';
import Itineraries from '@/features/components/gameThree/map/components/Itineraries/Itineraries';
import styles from '@/features/components/gameThree/map/map.module.scss';
import { useDebouncedSearch } from '@/hooks/useDebouncedSearch';
import {
    handleSearch,
    handleShowDirections,
} from '@/services/games/game3/gameThreeHandleFunctions';
import { shouldShowDestination } from '@/services/games/game3/gameThreeUtils';
import { handleUpdateOverlayDescriptions } from '@/services/global/globalHandleFunctions';
import { playSound } from '@/services/global/globalUtils';
import { useGame3Store } from '@/store/games/game3Store';
import type { DeviceColorWithTV } from '@/types/global/types';
import { useEffect, useState } from 'react';

interface MapProps {
    device: DeviceColorWithTV;
}

export default function Map({ device }: MapProps) {
    const showDirections = useGame3Store.use.showDirections();
    const [showDestination, setShowDestination] = useState(false);
    const search = useGame3Store.use.search()?.toLowerCase();

    const handleClick = () => {
        // TODO: update sound
        playSound(ClickSound);
        handleShowDirections(true);
    };

    const apiResponse = useDebouncedSearch(search, 500);
    const locations = apiResponse?.features ?? [];

    useEffect(() => {
        if (!search) {
            setShowDestination(false);
            return;
        }

        setShowDestination(shouldShowDestination(search));
    }, [search]);

    const [goodAddress, goodCity, goodRegion] =
        gameThreeData.destination.split(', ');

    return (
        <div className={styles.mapContainer}>
            {showDirections ? (
                <Itineraries device={device} />
            ) : (
                <div>
                    <input
                        type='text'
                        // button delete at the end of the input
                        alt='recherche'
                        onChange={(e) => handleSearch(e.target.value)}
                        value={search ?? ''}
                        placeholder='Saisir votre destination'
                    />
                    {locations.map((location, index) => (
                        <button
                            key={location.properties.label + index}
                            type='button'
                            onClick={() => {
                                // TODO: update sound
                                playSound(ClickSound);
                                handleSearch('');
                                handleUpdateOverlayDescriptions(
                                    [
                                        {
                                            id: 1,
                                            title: 'Erreur',
                                            text: 'Vous avez saisi une adresse invalide',
                                            audio: '',
                                        },
                                    ],
                                    'error'
                                );
                            }}
                        >
                            <DestinationProposal
                                address={location.properties.label}
                                city={location.properties.city}
                                region={location.properties.context}
                            />
                        </button>
                    ))}
                    {showDestination && (
                        <button type='button' onClick={handleClick}>
                            <DestinationProposal
                                address={goodAddress}
                                city={goodCity}
                                region={goodRegion}
                            />
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}
