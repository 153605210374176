import { handleTirePressureUpdate } from '@/services/games/game1/gameOneHandleFunctions';
import { type Tire } from '@/types/games/gameOne/types';

export function handleMediatorSetTiresPressure(pressure: number) {
    const tiresName: Tire[] = ['LeftFront', 'LeftRear', 'RightFront', 'RightRear'];

    for (const tire of tiresName) {
        handleTirePressureUpdate(tire, pressure);
    }
}
