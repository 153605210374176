import type { SearchResponse } from '@/api/search/search.types';

export async function getSearch({ search }: { search?: string }) {
    if (!search) return;
    const response = await fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${search}&limit=3&type=housenumber&autocomplete=1`
    );

    if (!response.ok) {
        throw new Error('Error while fetching data');
    }

    return response.json() as Promise<SearchResponse>;
}
