import { INITAL_OVERLAY_STORE } from '@/sessionInfo';
import createSelectors from '@/store/selectors';
import {
    type OverlayDescription,
    type OverlayStore,
    type OverlayType,
} from '@/types/games/types';
import type { Nullable } from '@/types/global/types';
import { create } from 'zustand';

const useOverlayStoreBase = create<OverlayStore>(() =>
    structuredClone(INITAL_OVERLAY_STORE)
);

export const useOverlayStore = createSelectors(useOverlayStoreBase);

export function setCurrentOverlayIndex(index: number) {
    useOverlayStoreBase.setState({
        currentOverlayIndex: index,
    });
}

export function setOverlayCallback(overlayCallback: Nullable<() => void>) {
    useOverlayStoreBase.setState({
        overlayCallback,
    });
}

export function setCurrentOverlayDescriptions(
    overlayDescriptions: OverlayDescription[],
    type: OverlayType
) {
    useOverlayStoreBase.setState({
        overlayDescriptions,
        overlayType: type,
        currentOverlayIndex: 0,
    });
}

export function setCurrentOverlayType(type: OverlayType) {
    useOverlayStoreBase.setState({
        overlayType: type,
    });
}

export function resetOverlayStore() {
    useOverlayStoreBase.setState(structuredClone(INITAL_OVERLAY_STORE));
}
