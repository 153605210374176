import TireImg from '@/assets/images/global/tire.svg';
import { SwellSound } from '@/assets/sounds/soundsEffects';
import gameOneData from '@/data/gameOneData.json';
import data from '@/data/global.json';
import Button from '@/features/devices/tablets/components/buttons/Button';
import {
    handleTirePressureUpdate,
    handleTirePressureValidation,
} from '@/services/games/game1/gameOneHandleFunctions';
import { handleCockpitAction } from '@/services/global/globalHandleFunctions';
import { playSound } from '@/services/global/globalUtils';
import { INITIAL_GAME1_STORE } from '@/sessionInfo';
import { useGame1Store } from '@/store/games/game1Store';
import type { Tire } from '@/types/games/gameOne/types';
import type { DeviceColor } from '@/types/global/types';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import styles from './tirePressure.module.scss';

interface TirePressureProps {
    tire: Tire;
    device?: DeviceColor;
}

const tireNameMap = {
    LeftFront: 'Avant Gauche',
    LeftRear: 'Arrière Gauche',
    RightFront: 'Avant Droit',
    RightRear: 'Arrière Droit',
} as const;

const TIRE_PRESSURE_OFFSET = 0.5;

export default function TirePressure({ device, tire }: TirePressureProps) {
    const { tiresPressure: allTirePressure } = useGame1Store();
    const tirePressure = allTirePressure[tire];
    const tireName = tireNameMap[tire];
    const [isValidable, setIsValidable] = useState(false);

    useEffect(() => {
        const baseTirePressure = INITIAL_GAME1_STORE.tiresPressure.LeftFront;
        setIsValidable(
            Object.values(allTirePressure).every(
                (tirePressure) => tirePressure !== baseTirePressure
            )
        );
    }, [allTirePressure]);

    const adjustPressure = (action: 'up' | 'down'): void => {
        const amount =
            action === 'up' ? +TIRE_PRESSURE_OFFSET : -TIRE_PRESSURE_OFFSET;
        handleTirePressureUpdate(tire, tirePressure + amount);
    };

    return (
        <div className={styles.container}>
            <Header device={device} />
            <TireCard
                tirePressure={tirePressure}
                tireName={tireName}
                adjustPressure={adjustPressure}
            />
            {device && device === 'red' && (
                <Button
                    color='black'
                    text={data.global.validate}
                    onClick={handleTirePressureValidation}
                    disabled={!isValidable}
                    additionalStyle={{
                        width: '80%',
                    }}
                />
            )}
        </div>
    );
}

function Header({ device }: { device?: DeviceColor }) {
    return (
        <header>
            {device && device === 'red' ? (
                <button
                    type='button'
                    onClick={() => handleCockpitAction('COCKPIT')}
                >
                    {'<'}
                </button>
            ) : (
                <div></div>
            )}
            <h3>{gameOneData.tirePressureTablet}</h3>
            <div></div>
        </header>
    );
}

function TireCard({
    tirePressure,
    tireName,
    adjustPressure,
}: {
    tirePressure: number;
    tireName: string;
    adjustPressure: (action: 'up' | 'down') => void;
}) {
    return (
        <div className={styles.tire_card}>
            <motion.button
                onClick={() => {
                    adjustPressure('down');
                    playSound(SwellSound);
                }}
                whileTap={{
                    scale: 0.75,
                }}
            >
                -
            </motion.button>
            <div>
                <p>{tirePressure.toFixed(2)} Bar</p>
                <img src={TireImg} alt='pneu' width={256} />
                <h1> Pneu {tireName}</h1>
            </div>
            <motion.button
                color='white'
                onClick={() => {
                    adjustPressure('up');
                    playSound(SwellSound);
                }}
                whileTap={{
                    scale: 0.75,
                }}
            >
                +
            </motion.button>
        </div>
    );
}
