import CreateRoom from '@/features/components/waitingPlayers/CreateRoom';
import BlueActionSwitch from '@/features/devices/tablets/blue/BlueActionSwitch';
import GreenActionSwitch from '@/features/devices/tablets/green/GreenActionSwitch';
import MediatorTablet from '@/features/devices/tablets/mediator/MediatorTablet';
import OrangeActionSwitch from '@/features/devices/tablets/orange/OrangeActionSwitch';
import RedActionSwitch from '@/features/devices/tablets/red/RedActionSwitch';
import Tv from '@/features/devices/tv/Tv';
import { useCurrentDevice } from '@/hooks/useCurrentDevice';
import { getColorByLocation } from '@/services/games/game2/gameTwoUtils';
import {
    handleGoToGameStatus,
    handleUpdateDeviceActivatedStatus,
} from '@/services/global/globalHandleFunctions';
import { emitSocketEvent, isDevelopperMode } from '@/services/global/globalUtils';
import SocketService from '@/services/SocketServices';
import {
    resetDevice,
    setDeviceActivatedStatus,
    setDeviceConnectedStatus,
} from '@/store/deviceStore';
import {
    resetGame1Store,
    resetTirePressure,
    setCallerId,
    setDoNotDisturb,
    setIsBeeingCalled,
    setSeatBeltStatus,
    setTirePressure,
    setTirePressureValidation,
    setUnlockState,
} from '@/store/games/game1Store';
import {
    resetGame2Store,
    setSelectedCarObject,
    setSelectedTruckObject,
    setStrapStatus,
    useGame2Store,
} from '@/store/games/game2Store';
import {
    resetGame3Store,
    setAudioPlayed,
    setCurrentGroupIndex,
    setCurrentSceneIndex,
    setSearch,
    setSelectedItinerary,
    setShowDirections,
    setSwipeAction,
} from '@/store/games/game3Store';
import {
    resetGame4Store,
    setItemStatus,
    setSelectedSubstance,
} from '@/store/games/game4Store';
import {
    resetGame5Store,
    setCurrentSituation,
    setPlayersAnswer,
} from '@/store/games/game5Store';
import {
    resetGame6Store,
    resetResponses,
    setCurrentSituationIndex,
    setResponses,
    setTimerStatus,
} from '@/store/games/game6Store';
import {
    resetGameBreathingExerciceStore,
    setAction,
    setPlayerStatus,
    toggleIsStarted,
} from '@/store/games/gameBreathingExerciceStore';
import {
    resetGameStore,
    setCockpitStatus,
    setGameStatus,
    setIsAudioPlaying,
    setMode,
    setVehicle,
} from '@/store/gameStore';
import {
    resetOverlayStore,
    setCurrentOverlayDescriptions,
    setCurrentOverlayIndex,
    setCurrentOverlayType,
} from '@/store/overlayStore';
import { setRoomName } from '@/store/roomStore';
import {
    GAMESTATUS,
    type DeviceColorWithTvAndMediator,
} from '@/types/global/types';
import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

export default function App() {
    const [room, setRoom] = useState('');
    const socket = SocketService.getInstance().getSocket();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const device = useCurrentDevice();

    useEffect(() => {
        if (!isFirstRender) {
            return;
        }
        if (!isDevelopperMode) return;

        setRoomName('devRoom');
        setDeviceConnectedStatus(device, true);
        setRoom('devRoom');
        emitSocketEvent('send_join_room', {
            device,
        });
        setIsFirstRender(false);
    }, [isFirstRender, device]);

    useEffect(() => {
        socket.on('receive_start_game', (data) => {
            setDeviceConnectedStatus(data.color, true);

            if (data.color === 'mediator') {
                handleUpdateDeviceActivatedStatus(data.color, true);
                handleGoToGameStatus(GAMESTATUS.WELCOME_SCREEN);
            }
        });
        socket.on('receive_game_status', (data) => {
            setGameStatus(data.status);
        });
        socket.on('receive_device_activated_status', (data) => {
            setDeviceActivatedStatus(
                data.deviceColor as DeviceColorWithTvAndMediator,
                data.status
            );
        });
        socket.on('receive_reset', () => {
            resetDevice();
            resetOverlayStore();
            resetGameStore();
            resetGame1Store();
            resetGame2Store();
            resetGame3Store();
            resetGameBreathingExerciceStore();
            resetGame4Store();
            resetGame5Store();
            resetGame6Store();
        });
        socket.on('receive_reset_responses_game_six', () => {
            resetResponses();
        });
        socket.on('receive_is_audio_playing', (data) => {
            setIsAudioPlaying(data.isAudioPlaying);
        });
        socket.on('receive_update_mode', (data) => {
            setMode(data.mode);
        });
        socket.on('receive_update_overlay_index', (data) => {
            setCurrentOverlayIndex(data.overlayIndex);
        });
        socket.on('receive_update_overlay_descriptions', (data) => {
            setCurrentOverlayDescriptions(
                data.overlayDescriptions,
                data.overlayType
            );
        });
        socket.on('receive_update_overlay_type', (data) => {
            setCurrentOverlayType(data.overlayType);
        });
        socket.on('receive_update_vehicle', ({ type }) => {
            setVehicle(type);
        });
        socket.on('receive_truck_objects_selected_game_two', (data) => {
            setSelectedTruckObject(data.deviceColor, data.object);
        });
        socket.on('receive_truck_objects_strapped_game_two', (data) => {
            setStrapStatus(data.deviceColor, data.isStrapped);
        });
        socket.on('receive_car_objects_selected_game_two', (data) => {
            const itemsSelected = useGame2Store.getState().selectedCarObjects;

            if (data.color) {
                setSelectedCarObject(data.color, data.carLocation, data.object);
            } else {
                const color = getColorByLocation(data.carLocation, itemsSelected);
                if (color)
                    setSelectedCarObject(color, data.carLocation, data.object);
            }
        });
        socket.on('receive_update_unlock_screen', (data) => {
            setUnlockState(data.status);
        });

        socket.on('receive_update_cockpit_status', ({ action }) => {
            setCockpitStatus(action);
        });

        socket.on('receive_update_tire_pressure', ({ tire, pressure }) => {
            setTirePressure(pressure, tire);
        });

        socket.on('receive_update_seat_belt_status', ({ status }) => {
            setSeatBeltStatus(status);
        });

        socket.on('receive_validate_tire_pressure', ({ status }) => {
            setTirePressureValidation(status);
        });

        socket.on('receive_update_do_not_disturb', ({ status }) => {
            setDoNotDisturb(status);
        });

        socket.on('receive_update_receive_call', ({ status, callerId }) => {
            setIsBeeingCalled(status);
            setCallerId(callerId);
        });

        socket.on('receive_update_search', ({ text }) => {
            setSearch(text);
        });

        socket.on('receive_update_show_directions', ({ status }) => {
            setShowDirections(status);
        });

        socket.on('receive_update_selected_itinerary', ({ itinerary }) => {
            setSelectedItinerary(itinerary);
        });

        socket.on('receive_update_current_scene_index', ({ currentSceneIndex }) => {
            setCurrentSceneIndex(currentSceneIndex);
        });

        socket.on('receive_update_current_group_index', ({ currentGroupIndex }) => {
            setCurrentGroupIndex(currentGroupIndex);
        });

        socket.on('receive_propagate_swipe_action', ({ action }) => {
            setSwipeAction(action);
        });

        socket.on('receive_update_game_three_audio_played', ({ audioPlayed }) => {
            setAudioPlayed(audioPlayed);
        });

        // BREATHING_GAME
        socket.on('receive_set_update_breathing_exercice_is_started', () => {
            toggleIsStarted();
        });
        socket.on(
            'receive_update_breathing_exercice_player_status',
            ({ device, status, value }) => {
                setPlayerStatus(device, status, value);
            }
        );

        socket.on('receive_update_breathing_exercice_action', (data) => {
            setAction(data.action);
        });

        socket.on('receive_reset_tire_pressure', () => {
            resetTirePressure();
        });

        socket.on('receive_items_status_game_four', ({ color, itemStatus }) => {
            setItemStatus(color, itemStatus);
        });
        socket.on('receive_items_selected_game_four', ({ color, substance }) => {
            setSelectedSubstance(color, substance);
        });
        socket.on('receive_players_answer_game_five', ({ selectedReaction }) => {
            setPlayersAnswer(selectedReaction);
        });
        socket.on('receive_update_situation_game_five', ({ index }) => {
            setCurrentSituation(index);
        });
        socket.on('receive_validate_game_five', () => {
            setPlayersAnswer(null);
        });
        socket.on('receive_response_game_six', (data) => {
            setResponses(data.color, data.responseId);
        });
        socket.on('receive_current_situation_index_game_six', (data) => {
            setCurrentSituationIndex(data.currentSituationIndex);
            resetResponses();
        });
        socket.on('receive_timer_status_game_six', ({ status }) => {
            setTimerStatus(status);
        });
        return () => {
            socket.off('receive_game_status');
            socket.off('receive_device_activated_status');
            socket.off('receive_reset');
            socket.off('receive_is_audio_playing');
            socket.off('receive_update_overlay_index');
            socket.off('receive_update_overlay_descriptions');
            socket.off('receive_update_overlay_type');
            socket.off('receive_update_vehicle');
            socket.off('receive_truck_objects_selected_game_two');
            socket.off('receive_truck_objects_strapped_game_two');
            socket.off('receive_car_objects_selected_game_two');
            socket.off('receive_update_unlock_screen');
            socket.off('receive_update_cockpit_status');
            socket.off('receive_update_tire_pressure');
            socket.off('receive_update_seat_belt_status');
            socket.off('receive_validate_tire_pressure');
            socket.off('receive_update_do_not_disturb');
            socket.off('receive_update_game_three_audio_played');
            socket.off('receive_update_search');
            socket.off('receive_update_show_directions');
            socket.off('receive_update_current_scene_index');
            socket.off('receive_update_current_group_index');
            socket.off('receive_propagate_swipe_action');
            socket.off('receive_update_breathing_exercice_player_status');
            socket.off('receive_update_breathing_exercice_action');
            socket.off('receive_set_update_breathing_exercice_is_started');
            socket.off('receive_update_receive_call');
            socket.off('receive_reset_tire_pressure');
            socket.off('receive_response_game_six');
            socket.off('receive_current_situation_index_game_six');
            socket.off('receive_items_status_game_four');
            socket.off('receive_items_selected_game_four');
            socket.off('receive_players_answer_game_five');
            socket.off('receive_update_situation_game_five');
            socket.off('receive_validate_game_five');
            socket.off('receive_timer_status_game_six');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const router = createBrowserRouter([
        {
            path: '/tv',
            element: room ? <Tv /> : <CreateRoom color='tv' setRoom={setRoom} />,
        },
        {
            path: '/red-tablet',
            element: room ? (
                <RedActionSwitch />
            ) : (
                <CreateRoom color='red' setRoom={setRoom} />
            ),
        },
        {
            path: '/blue-tablet',
            element: room ? (
                <BlueActionSwitch />
            ) : (
                <CreateRoom color='blue' setRoom={setRoom} />
            ),
        },
        {
            path: '/green-tablet',
            element: room ? (
                <GreenActionSwitch />
            ) : (
                <CreateRoom color='green' setRoom={setRoom} />
            ),
        },
        {
            path: '/orange-tablet',
            element: room ? (
                <OrangeActionSwitch />
            ) : (
                <CreateRoom color='orange' setRoom={setRoom} />
            ),
        },
        {
            path: '/mediator-tablet',
            element: room ? (
                <MediatorTablet roomName={room} />
            ) : (
                <CreateRoom color='mediator' setRoom={setRoom} />
            ),
        },
        {
            path: '/health_check',
            element: <div>Server is running 🚀</div>,
            errorElement: <div>Server is experiencing issues 🚨</div>,
        },
    ]);

    return <RouterProvider router={router} />;
}
