import Button from '@/features/devices/tablets/components/buttons/Button';
import SelectObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectObjectButton';
import styles from '@/features/devices/tablets/components/buttons/gameTwo/selectTruckObjectButton.module.scss';
import {
    handleTruckObjectStrappedGametwo,
    handleValidateGameTwoTruck,
} from '@/services/games/game2/gameTwoHandleFunctions';
import { useGame2Store } from '@/store/games/game2Store';
import type { DeviceColor } from '@/types/global/types';
import { useEffect } from 'react';

const COLOR_INDEX_MAP = {
    blue: 0,
    orange: 1,
    green: 2,
    red: 3,
};

const getIndexByColor = (color: DeviceColor) => {
    return COLOR_INDEX_MAP[color];
};

export default function SelectTruckObjectButton({ color }: { color: DeviceColor }) {
    const selectedObjects = useGame2Store.use.selectedTruckObjects();
    const currentItem = selectedObjects[color];
    const isCurrentItemStrapped = currentItem?.isStrapped;

    const handleStrapButtonClick = () => {
        handleTruckObjectStrappedGametwo(color, !isCurrentItemStrapped);
    };

    useEffect(() => {
        if (Object.values(selectedObjects).every((item) => item?.isStrapped)) {
            handleValidateGameTwoTruck();
        }
    }, [selectedObjects]);

    return (
        <div className={styles.container}>
            <div>
                <h1>Zone {getIndexByColor(color) + 1}</h1>
            </div>
            <div>
                <SelectObjectButton
                    type='truck'
                    disabled={isCurrentItemStrapped!}
                    color={color}
                    index={getIndexByColor(color)}
                />
                {currentItem ? (
                    <Button
                        onClick={handleStrapButtonClick}
                        text={
                            currentItem.isStrapped
                                ? "Désangler l'objet"
                                : "Sangler l'objet"
                        }
                        color='black'
                        additionalStyle={{ width: '90%' }}
                    />
                ) : null}
            </div>
        </div>
    );
}
