import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { handleReceiveCall } from '@/services/games/game1/gameOneHandleFunctions';
import { getNextCalledId } from '@/services/games/game1/gameOneUtils';
import { useGame1Store } from '@/store/games/game1Store';
import { useGameStore } from '@/store/gameStore';
import { GAMESTATUS } from '@/types/global/types';
import { useEffect } from 'react';

const CALL_DURATION = 8000;
const MAX_SECOND_DELAY = 20000;

export const useReceiveCall = (): void => {
    const gameStatus = useGameStore.use.gameStatus();
    const { overlayType } = useCurrentOverlay();
    const { isDoNotDisturbOn, isBeingCalled } = useGame1Store();

    useEffect(() => {
        if (
            gameStatus !== GAMESTATUS.GAME1ACTION ||
            isDoNotDisturbOn ||
            isBeingCalled ||
            overlayType !== 'neutral'
        )
            return;

        const timeBeforeCall = Math.random() * MAX_SECOND_DELAY;
        const timeoutID = setTimeout(() => {
            handleReceiveCall(true, getNextCalledId());
        }, timeBeforeCall);

        return () => {
            clearTimeout(timeoutID);
        };
    }, [isDoNotDisturbOn, isBeingCalled, gameStatus, overlayType]);

    useEffect(() => {
        if (gameStatus !== GAMESTATUS.GAME1ACTION || !isBeingCalled) return;
        const timeoutID = setTimeout(() => {
            handleReceiveCall(false);
        }, CALL_DURATION);
        return () => {
            clearTimeout(timeoutID);
        };
    }, [isBeingCalled, gameStatus]);
};
