import data from '@/data/gameSixData.json';
import { handleSetResponse } from '@/services/games/game6/game6HandleFunctions';
import { DEVICES_COLOR } from '@/services/global/globalUtils';
import { useGame6Store } from '@/store/games/game6Store';
import type { Game6Response, Game6Situation } from '@/types/games/gameSix/types';

export function getSituationByIndex(index: number): Game6Situation {
    return data.situations[index];
}

export function getResponsesBySituationId(situationId: number): Game6Response[] {
    return data.responses.filter(
        (response) => response.situationId === situationId
    );
}

export function getCorrectAnswersIdsBySituationId(situationId: number): number[] {
    return data.responses
        .filter(
            (response) => response.situationId === situationId && response.isCorrect
        )
        .map((response) => response.id);
}

export function setResponsesForAllColors(responseId: number) {
    DEVICES_COLOR.forEach((color) => handleSetResponse(color, responseId));
}

export function isSelectionCorrect() {
    const { responses, currentSituationIndex } = useGame6Store.getState();
    const currentSituation = getSituationByIndex(currentSituationIndex);
    const correctAnswersIds = getCorrectAnswersIdsBySituationId(
        currentSituation.id
    );

    return DEVICES_COLOR.every((color) => {
        const responseIds = responses[color];
        return correctAnswersIds.every((id) => responseIds.includes(id));
    });
}
