import texts from '@/features/devices/tablets/mediator/gamesComponents/gameComponentsTexts.json';
import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import { handleValidateGameTwoCar } from '@/services/games/game2/gameTwoHandleFunctions';
import { showValidateButton } from '@/services/games/game2/gameTwoUtils';
import { isCarSelected } from '@/services/global/globalUtils';
import {
    handleSelectGoodItemsGameTwoCar,
    handleSelectOrStrapGoodItemsGameTwoTruck,
} from '@/services/mediator/game2/mediatorUtilsGameTwo';
import { useGame2Store } from '@/store/games/game2Store';

export default function GameTwoMediator() {
    const isCar = isCarSelected();
    return isCar ? <CarMediator /> : <TruckMediator />;
}

function CarMediator() {
    const selectedCarObjects = useGame2Store.use.selectedCarObjects();

    const showButton = showValidateButton(selectedCarObjects);

    return (
        <div>
            {/* Set the objects at the good location */}
            <MediatorAction
                title={texts.game2.selectGoodObjects}
                displayingCondition
                handleFunction={handleSelectGoodItemsGameTwoCar}
            />

            {/* Validate the game */}
            <MediatorAction
                title={texts.game2.validateGame}
                displayingCondition={showButton}
                handleFunction={handleValidateGameTwoCar}
            />
        </div>
    );
}

function TruckMediator() {
    return (
        <div>
            {/* Set the objects at the good location */}
            <MediatorAction
                title={texts.game2.selectGoodObjects}
                displayingCondition
                handleFunction={() =>
                    handleSelectOrStrapGoodItemsGameTwoTruck(false)
                }
            />

            <MediatorAction
                title={texts.game2.strapObjects}
                displayingCondition
                handleFunction={() =>
                    handleSelectOrStrapGoodItemsGameTwoTruck(true)
                }
            />
        </div>
    );
}
