import { EngineStart } from '@/assets/images/global';
import styles from '@/features/devices/tablets/components/buttons/engineStartButton.module.scss';
import { motion } from 'framer-motion';

export default function EngineStartButton({ onClick }: { onClick: () => void }) {
    return (
        <div className={styles.container}>
            <motion.button
                whileTap={{ scale: 0.75 }}
                onClick={() => {
                    onClick();
                }}
            >
                <img src={EngineStart} alt='Boutton démarrage moteur' width={512} />
            </motion.button>
        </div>
    );
}
