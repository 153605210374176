import data from '@/data/global.json';
import styles from '@/features/components/cockpit/components/deactivatedTab.module.scss';
import { Toast } from '@/features/components/toast/Toast';

export function DeactivatedTab({ alreadydone }: { alreadydone?: boolean }) {
    return (
        <div className={styles.container}>
            <Toast
                text={`${data.cockpit.deactivated}
                    ${alreadydone ? data.cockpit.alreadyDone : data.cockpit.tabAvailability}
                    ${!alreadydone ? data.cockpit.progressAction : ''}`}
            />
        </div>
    );
}
