import {
    carObjects,
    correctCarCombination,
    truckObjects,
} from '@/data/gameTwoData.json';
import {
    handleCarObjectSelectedGameTwo,
    handleTruckObjectSelectedGametwo,
    handleTruckObjectStrappedGametwo,
} from '@/services/games/game2/gameTwoHandleFunctions';
import {
    CarLocation,
    type CarObject,
    type TruckObject,
} from '@/types/games/gameTwo/types';
import type { DeviceColor } from '@/types/global/types';

export function handleSelectGoodItemsGameTwoCar() {
    const emptyObject = carObjects[carObjects.length - 1];
    const allLocations = CarLocation;
    const goodLocations = correctCarCombination.map(
        (answer) => answer.location as CarLocation
    );

    const emptyLocations = Object.values(allLocations).filter(
        (location) => !goodLocations.includes(location)
    );

    // Set the empty object at the good locations
    emptyLocations.forEach((location) => {
        handleCarObjectSelectedGameTwo(location, emptyObject);
    });

    // Set the the good objects at the good locations

    correctCarCombination.forEach(({ location, objectIds }) => {
        const object = carObjects.find((object) => objectIds[0] === object.id);

        handleCarObjectSelectedGameTwo(
            location as CarLocation,
            object as CarObject
        );
    });
}

export function handleSelectOrStrapGoodItemsGameTwoTruck(strap: boolean) {
    const goodItems = truckObjects.filter(
        (object) => object.deliveryOrderIndex !== 0
    ) as TruckObject[];

    goodItems.forEach((object) => {
        let color: DeviceColor = 'blue';

        if (object.deliveryOrderIndex === 1) color = 'green';
        else if (object.deliveryOrderIndex === 2) color = 'orange';
        else if (object.deliveryOrderIndex === 3) color = 'red';

        strap
            ? handleTruckObjectStrappedGametwo(color, true)
            : handleTruckObjectSelectedGametwo(object, color);
    });
}
