import { WheelWhite } from '@/assets/images/icons';
import gameOneData from '@/data/gameOneData.json';
import data from '@/data/global.json';
import Switch from '@/features/devices/tablets/components/buttons/Switch';
import styles from '@/features/devices/tablets/orange/components/phone/settings/settings.module.scss';
import { handleSetDoNotDisturb } from '@/services/games/game1/gameOneHandleFunctions';
import { useGame1Store } from '@/store/games/game1Store';

export default function Settings() {
    const doNotDisturb = useGame1Store.use.isDoNotDisturbOn();

    return (
        <div className={styles.settings}>
            <div className={styles.header}>
                <img src={WheelWhite} alt='volant' width={64} />
                &nbsp;
                <p>{data.global.settings}</p>
            </div>
            <p className={styles.info}>{gameOneData.phone.settings}</p>
            <div className={styles.switch}>
                <p>{gameOneData.phone.doNotDisturb}</p>
                <Switch
                    initialStatus={doNotDisturb}
                    clickHandler={() => handleSetDoNotDisturb(!doNotDisturb)}
                />
            </div>
        </div>
    );
}
