import Button from '@/features/devices/tablets/components/buttons/Button';
import styles from '@/features/devices/tablets/components/gameSix/selectEmojiContainer.module.scss';
import {
    handleGameSixValidation,
    handleSetResponse,
} from '@/services/games/game6/game6HandleFunctions';
import { getResponsesBySituationId } from '@/services/games/game6/game6Utils';
import { useGame6Store } from '@/store/games/game6Store';
import type { DeviceColor } from '@/types/global/types';
import { motion } from 'framer-motion';

export default function SelectEmojiContainer({ color }: { color: DeviceColor }) {
    const currentSituationIndex = useGame6Store.use.currentSituationIndex();
    const currentResponse = useGame6Store.use.responses();
    const currentResponseIds = currentResponse[color];

    const hasEveryoneResponded = Object.values(currentResponse).every(
        (response) => response.length > 0
    );

    const possibleResponses = getResponsesBySituationId(currentSituationIndex);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <p>Choix multiples</p>
                <div className={`${styles[`circle_${color}`]}`} />
            </div>
            <div className={styles.cardContainer}>
                {possibleResponses.map((response) => {
                    const isSelected = currentResponseIds.includes(response.id);

                    return (
                        <ResponseCard
                            key={response.id}
                            emojis={response.emojis}
                            onClick={() => handleSetResponse(color, response.id)}
                            isSelected={isSelected}
                        />
                    );
                })}
            </div>
            {hasEveryoneResponded ? (
                <Button
                    color='black'
                    onClick={() => handleGameSixValidation(false)}
                    text='Valider'
                    additionalStyle={{
                        width: '95%',
                    }}
                />
            ) : (
                // Necessary to keep the layout consistent
                <div></div>
            )}
        </div>
    );
}

function ResponseCard({
    emojis,
    isSelected,
    onClick,
}: {
    emojis: string[];
    isSelected: boolean;
    onClick: () => void;
}) {
    return (
        <motion.button
            className={`${styles.responseCard} ${isSelected && styles.responseCard_selected}`}
            whileTap={{ scale: 0.9 }}
            onClick={onClick}
        >
            {emojis.map((emoji, index) => (
                <img key={index} src={emoji} alt='Emoji' />
            ))}
        </motion.button>
    );
}
