import { INITIAL_GAME_STORE } from '@/sessionInfo/sessionInfo';
import createSelectors from '@/store/selectors';
import type { CockpitAction, GameStore, Vehicle } from '@/types/games/types';
import type { GameStatus, Nullable } from '@/types/global/types';
import { create } from 'zustand';

const useGameStoreBase = create<GameStore>(() =>
    structuredClone(INITIAL_GAME_STORE)
);

export const useGameStore = createSelectors(useGameStoreBase);

export function setGameStatus(gameStatus: GameStatus) {
    useGameStoreBase.setState({
        gameStatus,
    });
}

export function setIsAudioPlaying(isAudioPlaying: boolean) {
    useGameStoreBase.setState({
        isAudioPlaying,
    });
}

export function setVehicle(vehicle: Nullable<Vehicle>) {
    useGameStoreBase.setState({ vehicle });
}

export function setMode(mode: 'cadenas' | 'pochette') {
    useGameStoreBase.setState({ mode });
}

export function setCockpitStatus(action: CockpitAction) {
    useGameStoreBase.setState({ cockpitStatus: action });
}

export function resetGameStore() {
    useGameStoreBase.setState(structuredClone(INITIAL_GAME_STORE));
}
